import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

export default function MetaHead({ title }) {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
}

MetaHead.defaultProps = {
  title: 'RouteLogic',
};

MetaHead.propTypes = {
  title: PropTypes.string,
};

import axios from 'axios';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import errorsFromResponse from '../helpers/errorsFromResponse';
import toApiUrl from '../helpers/toApiUrl';
import { userAtom } from '../store/atoms/auth';
import { translationsAtom } from '../store/atoms/i18n';

export const loadingStates = {
  LOADING: 'loading',
  IDLE: 'idle',
  ERROR: 'error',
};

const abortControllers = {};

/**
 * @callback onLoadingStateChangeCallback
 * @param {String} loadingState
 */

/**
 * @callback onErrorCallback
 * @param {Error} error
 */

/**
 * @callback onValidationErrorsCallback
 * @param {String[]} validationErrors
 */

/**
 * @callback useApiHandleCallback
 * @param {string} method
 * @param {string} path
 * @param {Object} properties
 */

/**
 * @typedef {Object} UseApiReturn
 * @property {useApiHandleCallback} handle
 */

/**
 * @param {Object} options
 * @param {onLoadingStateChangeCallback} options.onLoadingStateChange
 * @param {onErrorCallback} options.onError
 * @param {onValidationErrorsCallback} options.onValidationErrors
 * @param {string|null} identifier Identifier will cancel running requests for the same identifier.
 * @returns {UseApiReturn}
 */
export default function useApi({
  onLoadingStateChange = () => {},
  onError = () => {},
  onValidationErrors = () => {},
  identifier = null,
}) {
  const t = useRecoilValue(translationsAtom);
  const navigate = useNavigate();
  const [user, setUserAtom] = useRecoilState(userAtom);

  const handleError = (err) => {
    const statusCode = typeof err.response !== 'undefined'
      ? err.response.status
      : null;

    // The error is cancelled when an AbortController is signalled.
    if (typeof err.message !== 'undefined' && err.message === 'canceled') {
      return;
    }

    // If the response has a 401 status code, the user session has
    // probably expired. Log the user out and return to login.
    if (statusCode === 401 && user) {
      setUserAtom(null);
      navigate('/login');

      return;
    }

    // If the error has a response, it is most likely validation
    // errors. Parse the errors and push them in the validation errors.
    if (err.response) {
      onValidationErrors(errorsFromResponse(err.response));
    }

    // Clone error object.
    const prettyErr = new err.constructor(err.message);

    prettyErr.response = err.response;

    // Validation errors (unprocessable entity).
    if (statusCode === 422) {
      prettyErr.message = t.validation.unprocessable_entity;
    // Forbidden errors. Most likely thrown when the session has expired.
    } else if (statusCode === 403) {
      prettyErr.type = 'warning';
      prettyErr.message = t.validation.forbidden;
    // Unauthorized. Most likely thrown when the session has expired.
    } else if (statusCode === 401) {
      prettyErr.message = t.validation.unauthorized;
    // Session expired. Laravel error when the CSRF is invalid.
    } else if (statusCode === 419) {
      prettyErr.message = t.validation.session_expired;
    // Too many requests.
    } else if (statusCode === 429) {
      prettyErr.message = t.validation.too_many_requests;
    // Not found.
    } else if (statusCode === 404) {
      prettyErr.message = t.validation.not_found;
    // Gateway timeout or bad gateway. Most likely when during deployment (some service restarting).
    } else if (statusCode === 502 || statusCode === 504) {
      prettyErr.message = t.validation.gateway_error;
    // Server errors.
    } else if (statusCode >= 500 && statusCode <= 599) {
      prettyErr.message = t.validation.internal_server_error;
    }

    onError(prettyErr);
    onLoadingStateChange(loadingStates.ERROR);

    throw err;
  };

  const tryAbort = () => {
    if (identifier && typeof abortControllers[identifier] !== 'undefined') {
      abortControllers[identifier].abort();
      delete abortControllers[identifier];
    }
  };

  /**
   * @param {String} path
   * @param {Object} properties
   * @return {Promise<null|*[]>}
   */
  const get = async (path, properties = {}) => {
    tryAbort();
    onLoadingStateChange(loadingStates.LOADING);
    onValidationErrors([]);

    const axiosConfig = {};

    // If there's an identifier set, create an AbortController,
    // so we can stop the request if another request is made with
    // the same identifier.
    if (identifier) {
      abortControllers[identifier] = new AbortController();
      axiosConfig.signal = abortControllers[identifier].signal;
    }

    try {
      const queryParamters = Object.keys(properties).length
        ? `?${qs.stringify(properties)}`
        : '';

      const url = toApiUrl(path + queryParamters);
      const results = await axios.get(url, axiosConfig);
      const resultsData = results.data;

      onLoadingStateChange(loadingStates.IDLE);

      return resultsData;
    } catch (err) {
      handleError(err);
    }

    return null;
  };

  /**
   * @param {String} path
   * @param {Object} properties
   * @return {Promise<null|*[]>}
   */
  const post = async (path, properties = {}) => {
    tryAbort();
    onLoadingStateChange(loadingStates.LOADING);
    onValidationErrors([]);

    const axiosConfig = {};

    // If there's an identifier set, create an AbortController,
    // so we can stop the request if another request is made with
    // the same identifier.
    if (identifier) {
      abortControllers[identifier] = new AbortController();
      axiosConfig.signal = abortControllers[identifier].signal;
    }

    try {
      const url = toApiUrl(path);
      const results = await axios.post(url, properties, axiosConfig);
      const resultsData = results.data;

      onLoadingStateChange(loadingStates.IDLE);

      return resultsData;
    } catch (err) {
      handleError(err);
    }

    return null;
  };

  /**
   * @param {string} method
   * @param {string} path
   * @param {Object} properties
   * @returns {Promise<null|*[]>}
   */
  const handle = async (method, path, properties = {}) => {
    if (method.toLowerCase() === 'post') {
      return post(path, properties);
    }

    if (method.toLowerCase() === 'get') {
      return get(path, properties);
    }

    if (method.toLowerCase() === 'delete') {
      return post(path, { _method: 'DELETE', ...properties });
    }

    if (method.toLowerCase() === 'put') {
      return post(path, { _method: 'PUT', ...properties });
    }

    throw new Error('Invalid method');
  };

  return {
    handle,
  };
}

import { parseISO } from 'date-fns';
import { selector } from 'recoil';
import { trialUpgradeModalLastOpenAtom } from '../atoms/trialUpgrade';

// eslint-disable-next-line import/prefer-default-export
export const trialUpgradeModalLastOpenSelector = selector({
  key: 'trialUpgradeModalLastOpenSelector',
  get: ({ get }) => {
    const lastOpen = get(trialUpgradeModalLastOpenAtom);

    if (!lastOpen) {
      return null;
    }

    if (typeof lastOpen === 'string') {
      return parseISO(lastOpen);
    }

    return lastOpen;
  },
});

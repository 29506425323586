import { useRecoilState } from 'recoil';
import { navigationExpandedStateAtom } from '../atoms/navigation';

export default function useNavigationActions() {
  const [expandedState, setExpandedState] = useRecoilState(navigationExpandedStateAtom);

  function toggleNavigationExpandedState(forceState = null) {
    if (forceState !== null) {
      setExpandedState(forceState);
      return;
    }

    setExpandedState(!expandedState);
  }

  return {
    toggleNavigationExpandedState,
  };
}

import { parseISO } from 'date-fns';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import dateFormat from '../../helpers/dateFormat';
import useApi from '../../hooks/useApi';
import { apiErrorsFamily, apiLoadingFamily } from '../atoms/api';
import {
  tasksDetailItemAtom,
  tasksOverviewFiltersAtom,
  tasksOverviewItemsAtom,
  tasksNotDeliveredOverviewItemsAtom,
} from '../atoms/tasks';
import useToastActions from './toasts';
import { translationsAtom } from '../atoms/i18n';

export default function useTasksActions() {
  const t = useRecoilValue(translationsAtom);

  // Task detail
  const setTasksDetailItem = useSetRecoilState(tasksDetailItemAtom);
  const setTasksDetailItemLoadingState = useSetRecoilState(apiLoadingFamily('task-detail'));
  const setTasksDetailValidationErrors = useSetRecoilState(apiErrorsFamily('task-detail'));
  const setVisitFinishLoadingState = useSetRecoilState(apiLoadingFamily('visit-finish'));

  // Task overview
  const setTasksOverviewItems = useSetRecoilState(tasksOverviewItemsAtom);
  const setTasksOverviewItemsLoadingState = useSetRecoilState(apiLoadingFamily('tasks-overview'));
  const setTasksDownloadLoadingState = useSetRecoilState(apiLoadingFamily('tasks-download'));
  const [tasksOverviewFilters, setTasksOverviewFilters] = useRecoilState(
    tasksOverviewFiltersAtom,
  );

  // Task overview actions
  const setTasksDeleteLoadingState = useSetRecoilState(apiLoadingFamily('tasks-delete'));
  const setTasksRestoreLoadingState = useSetRecoilState(apiLoadingFamily('tasks-restore'));
  const setTasksDuplicateLoadingState = useSetRecoilState(apiLoadingFamily('tasks-duplicate'));

  // Task not delivered overview
  const setTasksNotDeliveredOverviewItems = useSetRecoilState(tasksNotDeliveredOverviewItemsAtom);
  const setTasksNotDeliveredOverviewItemsLoadingState = useSetRecoilState(apiLoadingFamily('tasks-not-delivered-overview'));

  const toastActions = useToastActions();

  const { handle: taskApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onLoadingStateChange: (loadingState) => setTasksDetailItemLoadingState(loadingState),
    onValidationErrors: (errors) => setTasksDetailValidationErrors(errors),
  });

  const { handle: tasksApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onLoadingStateChange: (loadingState) => setTasksOverviewItemsLoadingState(loadingState),
  });

  const { handle: tasksNotDeliveredApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onLoadingStateChange: (loadingState) => setTasksNotDeliveredOverviewItemsLoadingState(
      loadingState,
    ),
  });

  const { handle: taskCountsPerDayHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  const { handle: taskDuplicateApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  const { handle: tasksDownloadApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onLoadingStateChange: (loadingState) => setTasksDownloadLoadingState(loadingState),
  });

  const { handle: tasksDeleteApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onLoadingStateChange: (loadingState) => setTasksDeleteLoadingState(loadingState),
  });

  const { handle: tasksRestoreApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onLoadingStateChange: (loadingState) => setTasksRestoreLoadingState(loadingState),
  });

  const { handle: tasksDuplicateApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onLoadingStateChange: (loadingState) => setTasksDuplicateLoadingState(loadingState),
  });

  const { handle: taskLinesApiHandle } = useApi({
    //
  });

  const { handle: taskCodesApiHandle } = useApi({
    //
  });

  const { handle: taskVisitSignatureHandle } = useApi({
    //
  });

  const { handle: taskDeleteApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  const { handle: visitFinishApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onLoadingStateChange: (loadingState) => setVisitFinishLoadingState(loadingState),
  });

  async function fetch(taskId, withThrashed = false) {
    const results = await taskApiHandle('get', `tasks/${taskId}${withThrashed ? '/trashed' : ''}`);

    if (results) {
      results.delivery_date = results.delivery_date
        ? parseISO(results.delivery_date)
        : null;

      results.delivery_from_date = results.delivery_from_date
        ? parseISO(results.delivery_from_date)
        : null;

      setTasksDetailItem(results);
    }

    return results;
  }

  async function fetchAllNotDelivered() {
    const results = await tasksNotDeliveredApiHandle('get', 'tasks-not-delivered', tasksOverviewFilters);

    if (results) {
      setTasksNotDeliveredOverviewItems(results);
    }

    return results;
  }

  async function fetchAll() {
    const results = await tasksApiHandle('get', 'tasks', tasksOverviewFilters);

    if (results) {
      setTasksOverviewItems(results);
    }

    return results;
  }

  async function update(taskId, taskUpdateData) {
    const data = { ...taskUpdateData };

    data.delivery_date = dateFormat(data.delivery_date, 'yyyy-MM-dd');
    data.delivery_from_date = data.delivery_from_date ? dateFormat(data.delivery_from_date, 'yyyy-MM-dd') : null;

    const results = await taskApiHandle('put', `tasks/${taskId}`, data);

    if (results) {
      results.delivery_date = results.delivery_date
        ? parseISO(results.delivery_date)
        : null;

      results.delivery_from_date = results.delivery_from_date
        ? parseISO(results.delivery_from_date)
        : null;

      setTasksDetailItem(results);
    }

    return results;
  }

  async function store(taskStoreData) {
    const data = { ...taskStoreData };

    data.delivery_date = dateFormat(data.delivery_date, 'yyyy-MM-dd');
    data.delivery_from_date = data.delivery_from_date ? dateFormat(data.delivery_from_date, 'yyyy-MM-dd') : null;

    const results = await taskApiHandle('post', 'tasks', data);

    if (results) {
      results.delivery_date = results.delivery_date
        ? parseISO(results.delivery_date)
        : null;

      results.delivery_from_date = results.delivery_from_date
        ? parseISO(results.delivery_from_date)
        : null;

      setTasksDetailItem(results);
    }

    return results;
  }

  async function duplicate(taskId, date) {
    const result = await taskDuplicateApiHandle('post', `task-duplicate/${taskId}`, {
      date,
    });

    await fetchAll();
    await fetchAllNotDelivered();

    if (result) {
      toastActions.addGlobalToastItem(t.tasks.task_duplicated);
    }

    return result;
  }

  async function destroy(taskId) {
    const result = await taskDeleteApiHandle('delete', `tasks/${taskId}`);

    return result;
  }

  async function taskCountsPerDay() {
    const result = await taskCountsPerDayHandle('get', 'schedule-task-counts');

    return result;
  }

  async function updateLines(taskId, lines) {
    const result = await taskLinesApiHandle('put', `task-lines/${taskId}`, {
      // Do not store lines that do not have a description.
      lines: lines.filter((line) => {
        return line.description;
      }),
    });

    return result;
  }

  async function updateCode(taskId, code) {
    const result = await taskCodesApiHandle('put', `task-codes/${taskId}`, code);

    return result;
  }

  async function deleteSelectedCode(code) {
    const result = await taskCodesApiHandle('delete', `task-codes/${code}`, {});

    return result;
  }

  async function deleteSelectedTasks(selectedTasks) {
    await tasksDeleteApiHandle('post', 'bulk-tasks', {
      tasks: selectedTasks,
      action: 'archive',
    });

    toastActions.addGlobalToastItem(t.task.tasks_deleted, 'error');
  }

  async function restoreSelectedTasks(selectedTasks) {
    await tasksRestoreApiHandle('post', 'bulk-tasks', {
      tasks: selectedTasks,
      action: 'restore',
    });

    toastActions.addGlobalToastItem(t.task.tasks_restored);
  }

  async function duplicateSelectedTasks(selectedTasks, deliveryDate) {
    await tasksDuplicateApiHandle('post', 'bulk-tasks', {
      tasks: selectedTasks,
      action: 'duplicate',
      delivery_date: deliveryDate,
    });

    await fetchAll();
    await fetchAllNotDelivered();

    toastActions.addGlobalToastItem(t.task.tasks_duplicated);
  }

  async function visitSignature(visitId) {
    const result = await taskVisitSignatureHandle('get', `task-visit-signature/${visitId}`);

    return result;
  }

  async function downloadTasks() {
    const result = await tasksDownloadApiHandle('get', 'tasks-download', tasksOverviewFilters);

    const headers = [
      t.import.id,
      t.import.type,
      t.import.name,
      t.import.date,
      t.import.delivery_from_date,
      t.import.start_time,
      t.import.end_time,
      t.task.address,
      t.import.duration,
      t.task.delivered_at,
      t.task.driver,
      t.task.vehicle,
      t.import.note,
      t.task.driver_notes,
      t.import.return_require,
      t.import.signature_require,
      t.import.products,
    ];

    const data = result.map((row) => [
      row.external_identifier,
      row.type,
      row.name,
      row.delivery_date,
      row.delivery_from_date,
      row.start_time,
      row.end_time,
      row.address,
      row.delivery_duration_minutes,
      row.delivered_at,
      row.driver,
      row.vehicle,
      row.note,
      row.schedule_visit_notes,
      row.return_required,
      row.signature_required,
      row.products,
    ]);

    return {
      data,
      headers,
    };
  }

  async function finishVisit(visitId, taskId, data) {
    const result = await visitFinishApiHandle('post', `task-visit-finish/${visitId}`, data)
      .then(fetch(taskId));

    return result;
  }

  function updateFilter(key, value) {
    setTasksOverviewFilters({
      ...tasksOverviewFilters,
      [key]: value,
      page: key === 'page' ? value : 1,
    });
  }

  function reset() {
    setTasksDetailItem(null);
  }

  return {
    fetch,
    fetchAll,
    fetchAllNotDelivered,
    update,
    store,
    duplicate,
    destroy,
    reset,
    updateFilter,
    taskCountsPerDay,
    updateLines,
    deleteSelectedCode,
    deleteSelectedTasks,
    restoreSelectedTasks,
    duplicateSelectedTasks,
    visitSignature,
    downloadTasks,
    updateCode,
    finishVisit,
  };
}

import { atom } from 'recoil';

export const toastTypes = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
};

export const globalToastItemsAtom = atom({
  key: 'globalToastItemsAtom',
  default: [],
});

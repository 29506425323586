import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { subscriptionPlanQueryParamsSelector } from '../store/selectors/auth';

/**
 * For tracking we add the subscription plan to the URL.
 * To prevent this logic to be repeated accross the project, make it an hook.
 *
 * @returns function
 */
function useSchedulePath() {
  const subscriptionPlanQueryParams = useRecoilValue(subscriptionPlanQueryParamsSelector);

  return useCallback((scheduleUuid) => {
    return `/schedules/${scheduleUuid}?${subscriptionPlanQueryParams}`;
  }, [subscriptionPlanQueryParams]);
}

export default useSchedulePath;

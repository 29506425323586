import React, {
  Fragment,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { Dialog, Transition } from '@headlessui/react';
import SvgIcon from '../SvgIcon';
import { translationsAtom } from '../../store/atoms/i18n';
import analytics from '../../helpers/analytics';
import useEmailVerifyActions from '../../store/actions/emailVerify';
import { emailVerificationSentAtom } from '../../store/atoms/emailVerify';
import introImage from '../../assets/images/intro-image.jpg';
import { userAtom } from '../../store/atoms/auth';
import VimeoVideo from '../VimeoVideo';

export default function WelcomeModal({
  isOpen,
  onToggleOpen,
  description,
  buttonChild,
}) {
  const t = useRecoilValue(translationsAtom);
  const user = useRecoilValue(userAtom);
  const continueButtonRef = useRef(null);
  const emailVerifyActions = useEmailVerifyActions();
  const emailVerificationSent = useRecoilValue(emailVerificationSentAtom);

  const VIDEO_ENABLED = true;

  const resendVerification = useCallback(() => {
    emailVerifyActions.sendVerification();
  }, [emailVerifyActions]);

  const onIntroVideoPlay = useCallback(() => {
    analytics.track('Intro video playing', {
      category: 'Intro video playing',
    });
  }, []);

  const emailVerificationText = user && !user.email_verified_at
    ? (
      <>
        <div className="text-xl leading-relaxed mb-4">
          {t.welcome_modal.email_verification_description}
        </div>

        <div className="mb-8 lg:mb-12">
          {t.welcome_modal.email_verification_resend_text}
          <button
            className={`ml-2 underline ${emailVerificationSent ? 'text-gray-400 cursor-not-allowed' : 'text-primary hover:text-primary-darker focus:text-primary-darker'}`}
            type="button"
            onClick={resendVerification}
            disabled={emailVerificationSent}
          >
            {
              emailVerificationSent
                ? t.welcome_modal.email_verification_resend_button_disabled
                : t.welcome_modal.email_verification_resend_button
            }
          </button>
        </div>
      </>
    )
    : null;

  return (
    <Transition
      as={Fragment}
      show={isOpen}
    >
      <Dialog
        onClose={() => onToggleOpen(false)}
        className="fixed inset-0 z-30 overflow-y-auto"
        initialFocus={continueButtonRef}
      >
        <div className="flex items-center justify-center min-h-screen p-4 md:p-10 overflow-hidden">
          <Dialog.Overlay
            className="fixed inset-0 bg-black opacity-30"
          />

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="relative bg-white rounded-xl max-w-384 w-full mx-auto z-40 p-6 md:p-10 lg:p-16 md:grid md:grid-cols-3 md:items-center">
              <div className="md:col-span-1 mb-6 md:mb-0">
                <Dialog.Title className="text-2xl mb-4 font-bold">
                  {t.welcome_modal.title}
                </Dialog.Title>

                {
                  description
                    ? (
                      <div className="text-xl leading-relaxed mb-8 lg:mb-12">
                        {description}
                      </div>
                    )
                    : null
                }

                {emailVerificationText}

                {
                  !buttonChild
                    ? (
                      <button
                        type="button"
                        className="btn btn--positive btn--lg w-full justify-center"
                        onClick={() => onToggleOpen(false)}
                        ref={continueButtonRef}
                      >
                        {t.welcome_modal.continue_button}
                      </button>
                    )
                    : buttonChild
                }
              </div>

              <div className="md:col-span-2 md:pl-10 lg:pl-16">
                {
                  VIDEO_ENABLED
                    ? (
                      <div
                        className="rounded-lg overflow-hidden relative"
                        style={{
                          paddingBottom: '63.53%',
                        }}
                      >
                        <VimeoVideo
                          className="absolute top-0 left-0 w-full h-full"
                          videoId="794777021"
                          hash="4711315b20"
                          onPlay={onIntroVideoPlay}
                        />
                      </div>
                    )
                    : (
                      <img
                        className="block w-full h-auto"
                        src={introImage}
                        alt=""
                        width="750"
                        height="471"
                      />
                    )
                }
              </div>

              <button
                className="bg-primary hover:bg-primary-darker focus:bg-primary-darker transition-colors text-white p-3 md:p-4 absolute top-2 right-2 md:-top-4 md:-right-4 rounded-xl"
                type="button"
                onClick={() => onToggleOpen(false)}
                data-new-account-close
              >
                <span className="sr-only">{t.words.close}</span>
                <SvgIcon className="w-5 h-5" identifier="cross" />
              </button>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

WelcomeModal.defaultProps = {
  isOpen: false,
  description: null,
  buttonChild: null,
};

WelcomeModal.propTypes = {
  isOpen: PropTypes.bool,
  onToggleOpen: PropTypes.func.isRequired,
  description: PropTypes.string,
  buttonChild: PropTypes.node,
};

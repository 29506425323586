import { Dialog } from '@headlessui/react';
import React from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import { parseISO } from 'date-fns';
import { translationsAtom } from '../../store/atoms/i18n';
import Button from '../Button';
import Features from '../Features';
import { activeSubscriptionSelector, trialRemainingDaysSelector } from '../../store/selectors/auth';
import dateFormat from '../../helpers/dateFormat';

export default function TrialEnding({
  onOpenSubscriptionModal,
}) {
  const t = useRecoilValue(translationsAtom);
  const activeSubscription = useRecoilValue(activeSubscriptionSelector);
  const trialRemainingDays = useRecoilValue(trialRemainingDaysSelector);

  if (!activeSubscription || trialRemainingDays === null) {
    return null;
  }

  const expiresAtFormatted = dateFormat(parseISO(activeSubscription.trial_end_at), 'd MMMM yyyy');

  return (
    <div>
      <Dialog.Title
        className="text-xl md:text-2xl mb-4 md:mb-6 font-bold"
        dangerouslySetInnerHTML={{
          __html: trialRemainingDays === 1
            ? t.upgrade_trial_modal.trial_ending_title_day.replace('%s', trialRemainingDays)
            : t.upgrade_trial_modal.trial_ending_title_days.replace('%s', trialRemainingDays),
        }}
      />

      <Dialog.Description
        className="text-lg text-gray-700 mb-6 md:mb-10"
        dangerouslySetInnerHTML={{
          __html: t.upgrade_trial_modal.trial_ending_description.replace('%s', expiresAtFormatted),
        }}
      />

      <Features />

      <Button
        styleType="positive"
        className="btn--lg w-full justify-center"
        onClick={onOpenSubscriptionModal}
      >
        {t.upgrade_trial_modal.upgrade_account}
      </Button>
    </div>
  );
}

TrialEnding.defaultProps = {
  //
};

TrialEnding.propTypes = {
  onOpenSubscriptionModal: PropTypes.func.isRequired,
};

export default function localeFromWindow(acceptedLocales) {
  if (
    typeof window.navigator === 'undefined'
    || typeof window.navigator.language === 'undefined'
  ) {
    return acceptedLocales[0];
  }

  const navigatorLanguage = window.navigator.language;

  // If the language is for example 'en' of 'nl'
  if (navigatorLanguage.length === 2) {
    if (acceptedLocales.indexOf(navigatorLanguage) >= 0) {
      return navigatorLanguage;
    }
  }

  // If the language is for example 'en-US', 'nl-NL' or 'nl-BE'
  if (navigatorLanguage.length === 5) {
    const matches = navigatorLanguage.match(/(\w{2})-?\w{2}/);
    // For example 'en' or 'nl'
    const primaryLocale = matches.length >= 2 ? matches[1] : null;

    if (primaryLocale && acceptedLocales.indexOf(primaryLocale) >= 0) {
      return primaryLocale;
    }
  }

  return acceptedLocales[0];
}

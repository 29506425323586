/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import ErrorMessage from './ErrorMessage';
import ConnectForm from './ConnectForm';
import HelpLabel from './HelpLabel';

export default function SliderInput({
  label,
  labelHelp,
  id,
  schema,
  min,
  max,
}) {
  const isRequired = schema.exclusiveTests?.required || false;

  const requiredLabelNode = isRequired
    ? <abbr title="Required">*</abbr>
    : null;

  return (
    <ConnectForm name={id}>
      {({ control, error }) => (
        <>
          <label htmlFor={id}>
            <div className="block mb-2 font-bold">
              {label}
              {requiredLabelNode}
              <HelpLabel text={labelHelp} />
            </div>

            <Controller
              name={id}
              control={control}
              render={({ field }) => (
                <div className="flex items-center">
                  <Slider
                    min={min}
                    max={max}
                    value={field.value}
                    onChange={field.onChange}
                  />

                  <input
                    className="form-control w-20 ml-4 md:ml-6 flex-grow-0"
                    type="number"
                    min={min}
                    max={max}
                    value={field.value}
                    onChange={(event) => field.onChange(event.currentTarget.value)}
                  />
                </div>
              )}
            />
          </label>
          <ErrorMessage message={error} />
        </>
      )}
    </ConnectForm>
  );
}

SliderInput.defaultProps = {
  schema: {},
  labelHelp: null,
  min: 0,
  max: 100,
};

SliderInput.propTypes = {
  label: PropTypes.string.isRequired,
  labelHelp: PropTypes.string,
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
};

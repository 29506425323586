import * as yup from 'yup';

export default function useFreemiumRegisterEmailSchema() {
  const schema = {
    email: yup
      .string()
      .required()
      .email()
      .min(2)
      .max(200),
  };

  const cyclicDependencies = [
    //
  ];

  return [
    schema,
    cyclicDependencies,
  ];
}

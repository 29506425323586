import React from 'react';
import PropTypes from 'prop-types';

export default function Container({ children, className }) {
  return (
    <div className={`${className} w-full max-w-8xl mx-auto`}>
      {children}
    </div>
  );
}

Container.defaultProps = {
  className: '',
};

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

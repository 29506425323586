import { useRecoilState, useSetRecoilState } from 'recoil';
import useApi from '../../hooks/useApi';
import { apiErrorsFamily, apiLoadingFamily } from '../atoms/api';
import { userAtom } from '../atoms/auth';
import { freemiumModalOpenAtom } from '../atoms/freemium';
import useToastActions from './toasts';

export default function useFreemiumActions() {
  const setUser = useSetRecoilState(userAtom);
  const [freemiumModalOpen, setFreemiumModalOpen] = useRecoilState(freemiumModalOpenAtom);
  const setFreemiumLoadingState = useSetRecoilState(apiLoadingFamily('freemium'));
  const setFreemiumErrors = useSetRecoilState(apiErrorsFamily('freemium'));

  const toastActions = useToastActions();

  const { handle: freemiumApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onLoadingStateChange: (loadingState) => setFreemiumLoadingState(loadingState),
    onValidationErrors: (errors) => setFreemiumErrors(errors),
  });

  async function register(data) {
    if (typeof data.email === 'undefined') {
      throw new Error('Missing information to create account');
    }

    const results = await freemiumApiHandle('post', 'freemium-register', data);

    if (results) {
      setUser(results);
    }

    return results;
  }

  function toggleModal(forceState = null) {
    setFreemiumModalOpen(forceState !== null ? forceState : !freemiumModalOpen);
  }

  return {
    register,
    toggleModal,
  };
}

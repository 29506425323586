import React, { useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useRecoilValue } from 'recoil';
import { topUpModalOpenAtom } from '../../store/atoms/topUp';
import { translationsAtom } from '../../store/atoms/i18n';
import SvgIcon from '../../components/SvgIcon';
import useTopUpActions from '../../store/actions/topUp';
import Form from '../../components/Form';
import SelectInput from '../../components/FormFields/SelectInput';
import useTopUpSchema from '../../schemas/topUp';
import FormGroup from '../../components/FormFields/FormGroup';
import Button from '../../components/Button';
import { apiLoadingFamily } from '../../store/atoms/api';
import { loadingStates } from '../../hooks/useApi';
import analytics from '../../helpers/analytics';

export default function TopUpModal() {
  const t = useRecoilValue(translationsAtom);
  const [schema, cyclicDependencies] = useTopUpSchema();
  const topUpActions = useTopUpActions();
  const topUpModalOpen = useRecoilValue(topUpModalOpenAtom);
  const topUpLoadingState = useRecoilValue(apiLoadingFamily('top-up'));

  const onSubmit = useCallback((data) => {
    topUpActions.topUp(data).then((results) => {
      if (results && results.redirect_url) {
        analytics.track('Top up requested', {
          category: 'Top up requested',
          value: data.amount,
        });

        window.location = results.redirect_url;
      }
    });
  }, [topUpActions]);

  return (
    <Transition
      show={topUpModalOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        as="div"
        onClose={() => topUpActions.toggleModal(false)}
        className="fixed inset-0 z-30 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen p-4 md:p-10 overflow-hidden">
          <Dialog.Overlay
            className="fixed inset-0 bg-black opacity-30"
          />

          <div className="relative bg-white rounded-xl max-w-3xl w-full mx-auto z-40">
            <div className="overflow-hidden w-full max-w-3xl p-6 md:p-10 lg:p-14">
              <Dialog.Title className="text-xl md:text-2xl font-bold">
                {t.top_up_modal.title}
              </Dialog.Title>

              <Dialog.Description className="sr-only">
                {t.top_up_modal.description}
              </Dialog.Description>

              <div className="pt-6">
                <Form
                  schemaObject={schema}
                  schemaCyclicDependencies={cyclicDependencies}
                  onSubmit={onSubmit}
                >
                  <FormGroup>
                    <SelectInput
                      label={t.top_up_modal.amount}
                      id="amount"
                      schema={schema.amount}
                      options={{
                        25: '€ 25,-',
                        100: '€ 100,-',
                        250: '€ 250,-',
                        500: '€ 500,-',
                        1000: '€ 1.000,-',
                        2000: '€ 2.000,-',
                      }}
                    />
                  </FormGroup>

                  <Button
                    styleType="primary"
                    buttonType="submit"
                    className="px-10"
                    disabled={topUpLoadingState === loadingStates.LOADING}
                  >
                    {t.top_up_modal.pay_button}
                  </Button>
                </Form>
              </div>
            </div>

            <button
              className="bg-primary hover:bg-primary-darker focus:bg-primary-darker transition-colors text-white p-3 md:p-4 absolute top-2 right-2 md:-top-4 md:-right-4 rounded-xl"
              type="button"
              onClick={() => topUpActions.toggleModal(false)}
            >
              <span className="sr-only">{t.words.close}</span>
              <SvgIcon className="w-5 h-5" identifier="cross" />
            </button>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

TopUpModal.defaultProps = {
  //
};

TopUpModal.propTypes = {
  //
};

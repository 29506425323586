import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { trialRemainingDaysSelector } from '../../store/selectors/auth';
import { translationsAtom } from '../../store/atoms/i18n';

export default function TrialExpiring({
  toggleSubscriptionModal,
}) {
  const t = useRecoilValue(translationsAtom);
  const trialRemainingDays = useRecoilValue(trialRemainingDaysSelector);

  return (
    <div>
      {t.notification_bar.trial_expiring_in}
      {` ${trialRemainingDays} ${trialRemainingDays === 1 ? t.notification_bar.day : t.notification_bar.days}`}
      .

      <button
        className="ml-2 underline underline-offset-2"
        type="button"
        onClick={toggleSubscriptionModal}
      >
        {t.notification_bar.upgrade_now}
      </button>
    </div>
  );
}

TrialExpiring.defaultProps = {
  //
};

TrialExpiring.propTypes = {
  toggleSubscriptionModal: PropTypes.func.isRequired,
};

import { useSetRecoilState } from 'recoil';
import { apiErrorsFamily, apiLoadingFamily } from '../atoms/api';
import useToastActions from './toasts';
import useApi from '../../hooks/useApi';

export default function useTwoFactorActions() {
  // Loading states
  const setTwoFactorStoreLoadingState = useSetRecoilState(apiLoadingFamily('two-factor-store'));

  // Errors
  const setTwoFactorErrorsState = useSetRecoilState(apiErrorsFamily('two-factor'));

  const toastActions = useToastActions();

  const { handle: twoFactorQrHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onValidationErrors: (errors) => setTwoFactorErrorsState(errors),
  });

  const { handle: twoFactorStoreHandle } = useApi({
    onLoadingStateChange: (loadingState) => setTwoFactorStoreLoadingState(loadingState),
    onError: (err) => toastActions.addErrorFromObject(err),
    onValidationErrors: (errors) => setTwoFactorErrorsState(errors),
  });

  async function fetchQr() {
    const results = await twoFactorQrHandle('post', '/two-factor/qr');

    return results;
  }

  async function store(formData) {
    const results = await twoFactorStoreHandle('post', '/two-factor/store', formData);

    return results;
  }

  async function destroy() {
    const results = await twoFactorStoreHandle('delete', '/two-factor/delete');

    return results;
  }

  return {
    fetchQr,
    store,
    destroy,
  };
}

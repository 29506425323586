import React, { useCallback, useMemo } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import Badge from '../Badge';
import { userAtom } from '../../store/atoms/auth';
import { translationsAtom } from '../../store/atoms/i18n';
import {
  creditBalanceSelector,
  userHasFeatureSelector,
} from '../../store/selectors/auth';
import defaultAvatar from '../../assets/images/default-avatar.png';
import mediaPath from '../../helpers/mediaPath';
import useSubscriptionModalActions from '../../store/actions/subscriptionModal';
import useTopUpActions from '../../store/actions/topUp';
import formatPrice from '../../helpers/formatPrice';
import SvgIcon from '../SvgIcon';
import SubscriptionInfo from '../SubscriptionInfo';

export default function NavigationUser({ isOpen, navigationExpanded }) {
  const t = useRecoilValue(translationsAtom);
  const user = useRecoilValue(userAtom);
  const hasApiSynchronizationFeature = useRecoilValue(userHasFeatureSelector('API_SYNCHRONIZATION'));
  const hasMultiCompanyFeature = useRecoilValue(userHasFeatureSelector('MULTI_COMPANY'));
  const subscriptionModalActions = useSubscriptionModalActions();
  const creditBalance = useRecoilValue(creditBalanceSelector);
  const topUpActions = useTopUpActions();

  const onSubscriptionModalOpen = useCallback(() => {
    subscriptionModalActions.toggleModal(true);
  }, [subscriptionModalActions]);

  const handleProFeatureClick = useCallback((event) => {
    event.preventDefault();
    onSubscriptionModalOpen();
  }, [onSubscriptionModalOpen]);

  const onTopUpModalOpen = useCallback(() => {
    topUpActions.toggleModal(true);
  }, [topUpActions]);

  const creditBalanceLine = useMemo(() => {
    if (!creditBalance) {
      return t.navigation.credit_balance.no_balance;
    }

    return t.navigation.credit_balance.you_have_credit
      .replace('%s', formatPrice(creditBalance));
  }, [creditBalance, t]);

  const subscriptionsButton = useMemo(() => {
    return (
      <button
        type="button"
        className="py-1 flex items-center hover:text-primary transition-colors"
        onClick={onSubscriptionModalOpen}
      >
        {t.navigation.your_subscription}
      </button>
    );
  }, [t, onSubscriptionModalOpen]);

  return (
    <>
      <Menu.Button
        className={`${navigationExpanded ? 'w-full p-4' : 'w-10 h-10 group-hover:w-full group-hover:p-4 group-hover:h-auto'} ${isOpen ? 'bg-gray-200' : 'bg-gray-100'} hover:bg-gray-200 transition-colors md:rounded-lg text-left flex items-center`}
      >
        <div className={`rounded-md bg-gray-200 flex-grow-0 flex-shrink-0 relative overflow-hidden ${navigationExpanded ? 'mr-3 w-6 h-6' : 'w-10 h-10 group-hover:w-6 group-hover:h-6 group-hover:mr-3'}`}>
          <img
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-auto w-auto max-w-full max-h-full"
            src={user.avatar_path ? mediaPath(user.avatar_path) : defaultAvatar}
            alt=""
          />
        </div>

        <span className={`${navigationExpanded ? '' : 'group-hover:inline hidden'} flex-grow whitespace-nowrap text-ellipsis overflow-hidden min-w-0`}>
          {user.email}
        </span>

        <span className={`${navigationExpanded ? '' : 'hidden group-hover:block'} -mr-1 ml-2 inline-block text-gray-400`} aria-hidden>
          <SvgIcon identifier="dots" />
        </span>
      </Menu.Button>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="absolute w-full left-0 top-full md:top-auto md:bottom-0 md:left-full md:ml-8 bg-white rounded-md shadow-md z-10 mt-4 md:mt-0 min-w-80 overflow-hidden py-4">
          <Menu.Item>
            <div className="mb-4 pb-4 px-4 border-b">
              <h2 className="text-base font-bold pb-2">
                {t.navigation.credits}
              </h2>

              <div className="mb-2 text-gray-500">
                {creditBalanceLine}
              </div>

              <button type="button" className="underline hover:text-primary focus:text-primary transition-colors" onClick={onTopUpModalOpen}>
                {t.navigation.credit_balance.top_up}
              </button>
            </div>
          </Menu.Item>

          <Menu.Item>
            <div className={`${navigationExpanded ? 'hidden' : ''} mb-4 pb-4 px-4 border-b`}>
              <SubscriptionInfo />
            </div>
          </Menu.Item>

          <Menu.Item>
            <h2 className="text-base font-bold pb-3 px-4">
              {t.navigation.account}
            </h2>
          </Menu.Item>

          <Menu.Item>
            <NavLink
              to="/settings/account"
              className={({ isActive }) => `py-1 flex items-center hover:text-primary px-4 transition-colors ${isActive ? 'font-bold text-primary' : ''}`}
            >
              {t.navigation.your_data}
            </NavLink>
          </Menu.Item>

          <Menu.Item className="block w-full py-1 text-left hover:text-primary px-4 transition-colors">
            {subscriptionsButton}
          </Menu.Item>

          <Menu.Item>
            <NavLink
              to="/users"
              className="py-1 flex items-center hover:text-primary px-4 transition-colors"
            >
              {t.navigation.users}
            </NavLink>
          </Menu.Item>

          <Menu.Item>
            <NavLink
              to="/synchronization"
              className={`py-1 flex items-center hover:text-primary px-4 transition-colors ${hasApiSynchronizationFeature ? '' : 'text-gray-400'}`}
            >
              {t.navigation.synchronisation}
              <Badge
                onClick={handleProFeatureClick}
                className={`${hasApiSynchronizationFeature ? 'hidden' : ''} ml-auto -mr-1`}
              >
                PRO PLUS
              </Badge>
            </NavLink>
          </Menu.Item>

          <Menu.Item>
            <NavLink
              to="/companies"
              onClick={hasMultiCompanyFeature ? () => {} : handleProFeatureClick}
              className={`py-1 flex items-center hover:text-primary px-4 transition-colors ${hasMultiCompanyFeature ? '' : 'text-gray-400'}`}
            >
              {t.navigation.switch_company}
              <Badge className={`${hasMultiCompanyFeature ? 'hidden' : ''} ml-auto -mr-1`}>PRO</Badge>
            </NavLink>
          </Menu.Item>

          <Menu.Item>
            <NavLink
              to="/logout"
              className="py-1 flex items-center hover:text-primary px-4 transition-colors"
            >
              {t.navigation.logout}
            </NavLink>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </>
  );
}

NavigationUser.defaultProps = {
  isOpen: false,
  navigationExpanded: false,
};

NavigationUser.propTypes = {
  isOpen: PropTypes.bool,
  navigationExpanded: PropTypes.bool,
};

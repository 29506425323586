import { atom } from 'recoil';

// eslint-disable-next-line import/prefer-default-export
export const subscriptionModalOpenAtom = atom({
  key: 'subscriptionModalOpenAtom',
  default: false,
});

export const subscriptionTrialModalOpenAtom = atom({
  key: 'subscriptionTrialModalOpenAtom',
  default: false,
});

import { useRecoilState } from 'recoil';
import analytics from '../../helpers/analytics';
import useApi from '../../hooks/useApi';
import { emailVerificationSentAtom } from '../atoms/emailVerify';
import useToastActions from './toasts';

export default function useEmailVerifyActions() {
  const toastActions = useToastActions();
  const [
    emailVerificationSent,
    setEmailVerificationSentAtom,
  ] = useRecoilState(emailVerificationSentAtom);

  const { handle: verificationApiHandle } = useApi({
    identifier: 'verification-mail-send',
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  async function sendVerification() {
    if (emailVerificationSent) {
      return null;
    }

    setEmailVerificationSentAtom(true);

    const results = await verificationApiHandle('post', 'send-email-verification');

    if (results) {
      analytics.track('Email verification resend', {
        category: 'Email verification resend',
      });
    }

    setTimeout(() => {
      setEmailVerificationSentAtom(false);
    }, 20000);

    return results;
  }

  return {
    sendVerification,
  };
}

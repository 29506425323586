import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { translationsAtom } from '../../store/atoms/i18n';
import DatePicker from '../DatePicker';
import dateFormat from '../../helpers/dateFormat';
import useTasksActions from '../../store/actions/tasks';
import SvgIcon from '../SvgIcon';
import useSchedulesActions from '../../store/actions/schedules';
import useSchedulePath from '../../hooks/useSchedulePath';

export default function CreateScheduleDatepicker({ showTasks, size }) {
  const t = useRecoilValue(translationsAtom);
  const [taskCountsPerDay, setTaskCountsPerDay] = useState({});
  const tasksActions = useTasksActions();
  const schedulePath = useSchedulePath();
  const navigate = useNavigate();
  const scheduleOverviewActions = useSchedulesActions();

  useEffect(() => {
    if (!showTasks) {
      return;
    }

    tasksActions.taskCountsPerDay()
      .then((result) => {
        setTaskCountsPerDay(result);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateNewSchedule = useCallback(async (date) => {
    const results = await scheduleOverviewActions.store(date);

    if (results) {
      // Redirect to created schedule...
      navigate(schedulePath(results.uuid));
    }
  }, [navigate, scheduleOverviewActions, schedulePath]);

  const renderDay = useCallback((day, date) => {
    if (!showTasks) {
      return (
        <div>
          {day}
        </div>
      );
    }

    const dateFormatted = dateFormat(date, 'yyyy-MM-dd');
    const hasCount = typeof taskCountsPerDay[dateFormatted] !== 'undefined';
    const tasksOnDate = hasCount ? taskCountsPerDay[dateFormatted] : '&nbsp;';

    return (
      <div>
        {day}
        <span className="block text-sm -mt-3 mb-3 text-gray-400">
          <SvgIcon
            className={`${hasCount ? '' : 'hidden'} inline-block w-4 h-4 mr-1`}
            identifier="vehicle"
          />
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: tasksOnDate }} />
        </span>
      </div>
    );
  }, [taskCountsPerDay, showTasks]);

  let calendarClassName = '';

  if (size === 'sm') {
    calendarClassName = 'react-datepicker--sm';
  }

  return (
    <DatePicker
      calendarClassName={calendarClassName}
      inline
      onChange={onCreateNewSchedule}
      renderDay={renderDay}
    >
      <div className="text-gray-500 px-3 py-4">
        {t.schedules.select_date_to_create}
      </div>
    </DatePicker>
  );
}

CreateScheduleDatepicker.defaultProps = {
  showTasks: true,
  size: 'md',
};

CreateScheduleDatepicker.propTypes = {
  showTasks: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md']),
};

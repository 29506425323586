import { cloneDeep } from 'lodash';
import { useRecoilCallback } from 'recoil';
import { globalToastItemsAtom, toastTypes } from '../atoms/toasts';

export default function useToastActions() {
  /**
   * @param {String} message
   * @param {String} type
   * @param {number} duration
   * @return {number}
   */
  const addGlobalToastItem = useRecoilCallback(({ snapshot, set }) => {
    return async (message, type = toastTypes.SUCCESS, duration = 6000) => {
      let items = await snapshot.getPromise(globalToastItemsAtom);
      items = cloneDeep(items);

      const id = Math.round(Math.random() * 100000000);

      items.push({
        id,
        message,
        type,
        duration,
      });

      set(globalToastItemsAtom, items);

      return id;
    };
  });

  /**
   * @param {Error} err
   */
  function addErrorFromObject(err) {
    return addGlobalToastItem(err.message, err.type ?? 'error', 6000);
  }

  return {
    addGlobalToastItem,
    addErrorFromObject,
  };
}

import { useSetRecoilState } from 'recoil';
import useApi from '../../hooks/useApi';
import { apiErrorsFamily, apiLoadingFamily } from '../atoms/api';
import { userAtom } from '../atoms/auth';
import useToastActions from './toasts';

export default function useRegisterActions() {
  const setRegisterLoadingState = useSetRecoilState(apiLoadingFamily('register'));
  const setRegisterErrorsState = useSetRecoilState(apiErrorsFamily('register'));
  const setUser = useSetRecoilState(userAtom);
  const toastActions = useToastActions();

  const { handle: registerApiHandle } = useApi({
    onLoadingStateChange: (loadingState) => setRegisterLoadingState(loadingState),
    onError: (err) => toastActions.addErrorFromObject(err),
    onValidationErrors: (errors) => setRegisterErrorsState(errors),
  });

  const { handle: randomAddressApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  async function register(registerData) {
    const results = await registerApiHandle('post', 'register', registerData);

    if (results) {
      setUser(results);
    }

    return results;
  }

  async function enrichAddress(address) {
    const results = await registerApiHandle('put', 'register/enrich', {
      type: 'address',
      address,
    });

    return results;
  }

  async function enrichVehicles(data) {
    const results = await registerApiHandle('put', 'register/enrich', {
      type: 'vehicles',
      ...data,
    });

    return results;
  }

  async function enrichTasks(data) {
    const results = await registerApiHandle('put', 'register/enrich', {
      type: 'tasks',
      ...data,
    });

    return results;
  }

  async function createSchedule(data) {
    const results = await registerApiHandle('put', 'register/enrich', {
      type: 'schedule',
      ...data,
    });

    return results;
  }

  async function fetchRandomAddresses(limit) {
    const results = await randomAddressApiHandle('get', `random-address?limit=${limit}`);

    return results;
  }

  return {
    register,
    enrichAddress,
    enrichVehicles,
    enrichTasks,
    createSchedule,
    fetchRandomAddresses,
  };
}

import { atom } from 'recoil';

export const schedulesOverviewItemsAtom = atom({
  key: 'schedulesOverviewItems',
  default: {},
});

export const schedulesOverviewFiltersAtom = atom({
  key: 'schedulesOverviewFiltersAtom',
  default: {
    date: null,
    page: 1,
    status: 'active',
  },
});

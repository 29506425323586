import { useRecoilState, useSetRecoilState } from 'recoil';
import useApi from '../../hooks/useApi';
import { apiLoadingFamily } from '../atoms/api';
import { topUpModalOpenAtom } from '../atoms/topUp';
import useToastActions from './toasts';

export default function useTopUpActions() {
  const toastActions = useToastActions();
  const topUpLoadingState = useSetRecoilState(apiLoadingFamily('top-up'));
  const [topUpModalOpen, setTopUpModalOpen] = useRecoilState(topUpModalOpenAtom);

  const { handle: topUpApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onLoadingStateChange: (loadingState) => topUpLoadingState(loadingState),
  });

  async function topUp(data) {
    const results = await topUpApiHandle('post', 'credit/top-up', data);

    return results;
  }

  function toggleModal(forceState = null) {
    setTopUpModalOpen(forceState !== null ? forceState : !topUpModalOpen);
  }

  return {
    topUp,
    toggleModal,
  };
}

export default function toApiUrl(path) {
  // Prepend the API URL if the URL does not start with http.
  if (path.indexOf('http') !== 0) {
    return [
      process.env.REACT_APP_API_URL,
      path.replace(/^\//, ''),
    ].join('/');
  }

  return path;
}

import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@headlessui/react';
import { useRecoilValue } from 'recoil';
import SvgIcon from '../SvgIcon';
import { translationsAtom } from '../../store/atoms/i18n';

export default function InfoBubble({
  message, className, panelClassName, iconClassName, buttonClassName, dangerousMessage,
}) {
  const t = useRecoilValue(translationsAtom);

  const panel = () => {
    return (
      dangerousMessage ? (
        <Popover.Panel
          className={`${panelClassName} absolute z-10 p-4 bg-white rounded shadow-lg w-60 -ml-3`}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      ) : (
        <Popover.Panel
          className={`${panelClassName} absolute z-10 p-4 bg-white rounded shadow-lg w-60 -ml-3`}
        >
          {message}
        </Popover.Panel>
      ));
  };

  return (
    <Popover className={`${className} relative -my-1`}>
      <Popover.Button className={buttonClassName}>
        <SvgIcon identifier="info" className={iconClassName || 'w-5 h-5 text-gray-500'} />

        <span className="sr-only">{t.form.more_info_bubble}</span>
      </Popover.Button>

      {panel()}
    </Popover>
  );
}

InfoBubble.defaultProps = {
  className: '',
  panelClassName: '',
  iconClassName: '',
  buttonClassName: '',
  dangerousMessage: false,
};

InfoBubble.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
  panelClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  dangerousMessage: PropTypes.bool,
};

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { NavLink } from 'react-router-dom';
import { translationsAtom } from '../../store/atoms/i18n';
import { activeSubscriptionSelector, companySelector, trialEndAtSelector } from '../../store/selectors/auth';
import dateFormat from '../../helpers/dateFormat';
import useSubscriptionModalActions from '../../store/actions/subscriptionModal';

export default function SubscriptionInfo({
  className,
}) {
  const t = useRecoilValue(translationsAtom);
  const subscription = useRecoilValue(activeSubscriptionSelector);
  const subscriptionModalActions = useSubscriptionModalActions();
  const company = useRecoilValue(companySelector);
  const trialEndAt = useRecoilValue(trialEndAtSelector);

  const onSubscriptionModalOpen = useCallback(() => {
    subscriptionModalActions.toggleModal(true);
  }, [subscriptionModalActions]);

  if (!company) {
    return null;
  }

  // If there's no subscription running, its in free mode.
  // And the company has not been in a trial in the past.
  if (!subscription && !company.has_had_subscription) {
    return (
      <div className={className}>
        <h2 className="text-base font-bold pb-2">
          <button
            type="button"
            onClick={onSubscriptionModalOpen}
          >
            {t.subscription_info.title}
          </button>
        </h2>

        <button
          type="button"
          className="underline hover:text-primary focus:text-primary transition-colors"
          onClick={onSubscriptionModalOpen}
        >
          {t.subscription_info.start_trial}
        </button>
      </div>
    );
  }

  if (!subscription) {
    return (
      <div className={className}>
        <h2 className="text-base font-bold">
          <button
            type="button"
            onClick={onSubscriptionModalOpen}
          >
            {t.subscription_info.title}
          </button>

          <span className="font-normal inline-block ml-1">{t.subscription_info.free}</span>
        </h2>

        <button
          type="button"
          className="underline hover:text-primary focus:text-primary transition-colors text-left inline-block mt-2"
          onClick={onSubscriptionModalOpen}
        >
          {t.subscription_info.start_subscription}
        </button>
      </div>
    );
  }

  const trialEndAtFormatted = dateFormat(trialEndAt, 'dd-MM-yyyy');

  // Its possible that a subscription is in trial, but has no attachment to
  // the payment provider. The consequence is that we cannot redirect the
  // user to the payment provider to manage their subscription information.
  const hasPaymentProvider = !!subscription?.chargebee_id;

  return (
    <div className={className}>
      <h2 className="text-base font-bold">
        <button
          type="button"
          onClick={onSubscriptionModalOpen}
        >
          {t.subscription_info.title}
        </button>

        <span className="font-normal inline-block ml-1">{subscription.subscription_plan.name}</span>
      </h2>

      {
        subscription.status === 'in_trial' && trialEndAtFormatted
          ? (
            <div className="mt-2">
              <div className="mb-2 text-gray-500">
                {`${t.subscription_info.trial_expires_at} ${trialEndAtFormatted}`}
              </div>

              {
                hasPaymentProvider
                  ? (
                    <NavLink
                      className="underline hover:text-primary focus:text-primary transition-colors text-left"
                      to="/internal-subscription"
                    >
                      {t.subscription_info.manage_payment_methods}
                    </NavLink>
                  )
                  : (
                    <button
                      type="button"
                      className="underline hover:text-primary focus:text-primary transition-colors text-left inline-block mt-2"
                      onClick={onSubscriptionModalOpen}
                    >
                      {t.subscription_info.start_subscription}
                    </button>
                  )
              }
            </div>
          )
          : null
      }
    </div>
  );
}

SubscriptionInfo.defaultProps = {
  className: '',
};

SubscriptionInfo.propTypes = {
  className: PropTypes.string,
};

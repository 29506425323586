import { atomFamily } from 'recoil';
import { loadingStates } from '../../hooks/useApi';

export const apiLoadingFamily = atomFamily({
  key: 'apiLoadingFamily',
  default: loadingStates.IDLE,
});

export const apiErrorsFamily = atomFamily({
  key: 'apiErrorsFamily',
  default: [],
});

import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';

export default function PageLoader({ className }) {
  return (
    <div className={`${className} bg-white w-full h-screen flex items-center justify-center`}>
      <Spinner />
    </div>
  );
}

PageLoader.defaultProps = {
  className: '',
};

PageLoader.propTypes = {
  className: PropTypes.string,
};

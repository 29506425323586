import { useRecoilState, useSetRecoilState } from 'recoil';
import useApi from '../../hooks/useApi';
import useToastActions from './toasts';
import {
  scheduleVisitNotesOverviewFiltersAtom,
  scheduleVisitNotesOverviewItemsAtom,
  scheduleVisitNotesSelectedItemAtom,
  scheduleVisitNotesUnreadCountAtom,
} from '../atoms/scheduleVisitNotes';

export default function useScheduleVisitNotesActions() {
  const toastActions = useToastActions();

  const setScheduleVisitNotesItems = useSetRecoilState(scheduleVisitNotesOverviewItemsAtom);
  const setSelectedScheduleVisitNote = useSetRecoilState(scheduleVisitNotesSelectedItemAtom);
  const setScheduleVisitNotesUnreadCount = useSetRecoilState(
    scheduleVisitNotesUnreadCountAtom,
  );

  const [
    scheduleVisitNotesOverviewFilters,
    setScheduleVisitNotesOverviewFilters,
  ] = useRecoilState(scheduleVisitNotesOverviewFiltersAtom);

  const { handle: scheduleVisitNotesApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  async function getUnreadNotesCount() {
    const result = await scheduleVisitNotesApiHandle('get', 'visit-notes-unread-count');

    if (result) {
      setScheduleVisitNotesUnreadCount(result);
    }

    return result;
  }

  async function fetchAll() {
    const result = await scheduleVisitNotesApiHandle('get', 'visit-notes', scheduleVisitNotesOverviewFilters);

    if (result) {
      setScheduleVisitNotesItems(result);
    }

    getUnreadNotesCount();

    return result;
  }

  async function fetchSelectedItem(id, type) {
    if (type === 'feedback') {
      const result = await scheduleVisitNotesApiHandle('get', `visit-feedback/${id}`);

      if (result) {
        setSelectedScheduleVisitNote(result);

        return result;
      }
    }

    if (type === 'note') {
      const result = await scheduleVisitNotesApiHandle('get', `visit-notes/${id}`);

      if (result) {
        setSelectedScheduleVisitNote(result);

        return result;
      }
    }

    return null;
  }

  function updateFilter(key, value) {
    setScheduleVisitNotesOverviewFilters({
      ...scheduleVisitNotesOverviewFilters,
      [key]: value,
      page: key === 'page' ? value : 1,
    });
  }

  async function scheduleNameFromUuid(uuid) {
    if (!uuid) {
      return null;
    }

    const result = scheduleVisitNotesApiHandle('get', `schedule-name/${uuid}`);

    if (!result) {
      return null;
    }

    return result;
  }

  async function updateReadStatus(id, type, state) {
    if (type === 'feedback') {
      scheduleVisitNotesApiHandle('put', `visit-feedback/${id}`, {
        is_read: state,
      });
    }

    if (type === 'note') {
      scheduleVisitNotesApiHandle('put', `visit-notes/${id}`, {
        is_read: state,
      });
    }

    // Fetch all notes to make sure the current read status for all notes is synced.
    fetchAll();
  }

  function setSelectedItem(item) {
    setSelectedScheduleVisitNote(item);

    return item;
  }

  return {
    fetchAll,
    updateFilter,
    updateReadStatus,
    setSelectedItem,
    getUnreadNotesCount,
    scheduleNameFromUuid,
    fetchSelectedItem,
  };
}

import React from 'react';
import PropTypes from 'prop-types';

export default function ErrorMessage({ message }) {
  let error = null;

  if (message) {
    error = message;
  }

  if (!error) {
    return null;
  }

  return (
    <div className="text-red-600 pt-2" role="alert">
      {error}
    </div>
  );
}

ErrorMessage.defaultProps = {
  message: null,
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
};

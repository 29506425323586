import format from 'date-fns/format';
import { useRecoilState, useSetRecoilState } from 'recoil';
import dateFormat from '../../helpers/dateFormat';
import useApi from '../../hooks/useApi';
import { apiErrorsFamily, apiLoadingFamily } from '../atoms/api';
import {
  schedulesOverviewFiltersAtom,
  schedulesOverviewItemsAtom,
} from '../atoms/schedules';
import useToastActions from './toasts';

export default function useSchedulesActions() {
  // Schedule create
  const setSchedulesCreateLoadingState = useSetRecoilState(apiLoadingFamily('schedule-create'));

  // Schedule overview
  const setSchedulesOverviewItems = useSetRecoilState(schedulesOverviewItemsAtom);
  const setScheduleOverviewLoadingState = useSetRecoilState(
    apiLoadingFamily('schedule-overview'),
  );
  const setScheduleOverviewValidationErrors = useSetRecoilState(
    apiErrorsFamily('schedule-overview'),
  );
  const [schedulesOverviewFilters, setSchedulesOverviewFilters] = useRecoilState(
    schedulesOverviewFiltersAtom,
  );

  const toastActions = useToastActions();

  const { handle: schedulesApiHandler } = useApi({
    onLoadingStateChange: (loadingState) => setScheduleOverviewLoadingState(loadingState),
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  const { handle: scheduleStoreApiHandler } = useApi({
    onLoadingStateChange: (loadingState) => setSchedulesCreateLoadingState(loadingState),
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  const { handle: scheduleDeleteApiHandler } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  const { handle: scheduleDuplicateApiHandler } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  const { handle: scheduleNotificationApiHandler } = useApi({
    onValidationErrors: (errors) => setScheduleOverviewValidationErrors(errors),
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  async function fetchAll() {
    const results = await schedulesApiHandler('get', 'schedules', schedulesOverviewFilters);

    if (results) {
      setSchedulesOverviewItems(results);
    }

    return results;
  }

  async function store(date) {
    const formattedDate = format(date, 'yyyy-MM-dd');

    const results = await scheduleStoreApiHandler('post', 'schedules', {
      date: formattedDate,
    });

    return results;
  }

  async function freemiumStore(date, params) {
    const formattedDate = format(date, 'yyyy-MM-dd');

    const results = await scheduleStoreApiHandler('post', 'freemium-schedule-store', {
      date: formattedDate,
      parameters: params,
    });

    return results;
  }

  async function deleteSchedule(scheduleUuid, shouldDelete) {
    const result = await scheduleDeleteApiHandler('post', `archive-schedule/${scheduleUuid}`, {
      archive: shouldDelete,
    });

    return result;
  }

  async function sendNotifications(scheduleUuid, notificationType) {
    const result = await scheduleNotificationApiHandler('post', `schedule-notification/${scheduleUuid}`, {
      type: notificationType,
    });

    return result;
  }

  async function duplicateSchedule(scheduleUuid, date) {
    const result = await scheduleDuplicateApiHandler('post', `schedule-duplicate/${scheduleUuid}`, {
      date: dateFormat(date, 'yyyy-MM-dd'),
    });

    return result;
  }

  function updateFilter(key, value) {
    setSchedulesOverviewFilters({
      ...schedulesOverviewFilters,
      [key]: value,
      page: key === 'page' ? value : 1,
    });
  }

  return {
    fetch,
    fetchAll,
    store,
    updateFilter,
    delete: deleteSchedule,
    freemiumStore,
    sendNotifications,
    duplicateSchedule,
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@headlessui/react';

export default function UnitToggle({
  enabled,
  onChange,
  leftText,
  rightText,
  description,
}) {
  return (
    <div className="flex items-center">
      <button
        type="button"
        className={`${!enabled ? 'text-primary' : 'text-gray-400'} text-right`}
        onClick={() => { onChange(false); }}
      >
        {leftText}
      </button>

      <Switch
        checked={enabled}
        onChange={onChange}
        className="bg-primary mx-4 relative inline-flex flex-shrink-0 items-center h-6 rounded-full w-11"
      >
        <span className="sr-only">
          {description}
        </span>

        <span
          className={`${
            enabled ? 'translate-x-6' : 'translate-x-1'
          } inline-block w-4 h-4 transform bg-white rounded-full`}
        />
      </Switch>

      <button
        type="button"
        className={`${enabled ? 'text-primary' : 'text-gray-400'}`}
        onClick={() => { onChange(true); }}
      >
        {rightText}
      </button>
    </div>
  );
}

UnitToggle.defaultProps = {
  //
};

UnitToggle.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  leftText: PropTypes.string.isRequired,
  rightText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

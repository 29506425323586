import Joyride, { STATUS } from 'react-joyride';
import React, { useCallback, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { joyRideHelpersAtom, joyRideRunAtom } from '../../store/atoms/joyRideState';
import useRegisterEndModalActions from '../../store/actions/registerEndModal';
import { translationsAtom } from '../../store/atoms/i18n';
import { scheduleDetailNotificationModal } from '../../store/atoms/scheduleDetail';
import { onboardingStateAtom } from '../../store/atoms/startOnboarding';

export default function JoyRide() {
  const [joyRideRun, setJoyRideRun] = useRecoilState(joyRideRunAtom);
  const setJoyRideHelpers = useSetRecoilState(joyRideHelpersAtom);
  const setOnboardingState = useSetRecoilState(onboardingStateAtom);

  const setIsNotificationModalOpen = useSetRecoilState(scheduleDetailNotificationModal);
  const registerEndModalActions = useRegisterEndModalActions();
  const t = useRecoilValue(translationsAtom);

  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if (STATUS.FINISHED === status || STATUS.SKIPPED === status) {
      setJoyRideRun(false);
      setIsNotificationModalOpen(false);
      window.localStorage.setItem('onboarding_state', 'completed');
      setOnboardingState('completed');
    }

    if (STATUS.FINISHED === status) {
      registerEndModalActions.toggleModal(true);
    }
  };

  const handleStep7RedirectCallback = useCallback(() => {
    setJoyRideRun(false);
    setIsNotificationModalOpen(false);
  }, [setJoyRideRun, setIsNotificationModalOpen]);

  useEffect(() => {
    const handleKey = (event) => {
      if (event.key === 'Escape' && joyRideRun) {
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKey, false);

    return () => {
      window.removeEventListener('keydown', handleKey, false);
    };
  }, [joyRideRun]);

  const step4 = useMemo(() => {
    return (
      <>
        {t.joyride.step_4.content}
        &nbsp;
        <NavLink onClick={handleStep7RedirectCallback} className="text-gray-600 underline" to="/notification-templates">
          {t.joyride.step_4.link}
        </NavLink>
        .
      </>
    );
  }, [t, handleStep7RedirectCallback]);

  const steps = [
    {
      target: '.step-1',
      title: t.joyride.step_1.title,
      content: t.joyride.step_1.content,
      test: 'test',
      continuous: true,
      hideBackButton: true,
      disableBeacon: true,
      placement: 'auto',
    },
    {
      target: '.step-2',
      title: t.joyride.step_2.title,
      content: t.joyride.step_2.content,
      disableBeacon: true,
      continuous: true,
      spotlightClicks: false,
      placement: 'auto',
    },
    {
      target: '.step-3',
      title: t.joyride.step_3.title,
      content: t.joyride.step_3.content,
      continuous: true,
      disableBeacon: true,
      placement: 'auto',
    },
    {
      target: '.step-4',
      title: t.joyride.step_4.title,
      content: step4,
      disableBeacon: true,
      continuous: true,
      spotlightClicks: false,
      placement: 'auto',
    },
  ];

  const joyrideTooltip = ({
    step,
    index,
    tooltipProps,
    primaryProps,
    skipProps,
    backProps,
  }) => {
    const {
      'aria-label': backAriaLabel,
      'data-action': backDataAction,
      onClick: backOnClick,
      role: backRole,
      title: backTitle,
    } = backProps;

    const {
      'aria-label': primaryAriaLabel,
      'data-action': primaryDataAction,
      onClick: primaryOnClick,
      role: primaryRole,
      title: primaryTitle,
    } = primaryProps;

    const handleNext = (e) => {
      let timeout = 0;

      if (index === 0) {
        setTimeout(() => {
          document.getElementById('step-1-click').click();
        }, 50);
      }

      if (index === 2) {
        setIsNotificationModalOpen(true);
        timeout = 200;
      }

      setTimeout(() => {
        primaryOnClick(e);
      }, timeout);
    };

    const handleBack = (e) => {
      let timeout = 0;

      if (index === 1) {
        const currentState = document.getElementById('step-1-popover').getAttribute('data-headlessui-state');

        if (!currentState) {
          document.getElementById('step-1-click').click();
        }

        timeout = 50;
      }

      if (index === 3) {
        setTimeout(() => {
          setIsNotificationModalOpen(false);
        }, 50);
      }

      setTimeout(() => {
        backOnClick(e);
      }, timeout);
    };

    return (
      <div
        className="bg-white p-3 lg:p-6 rounded-lg shadow-md w-72 lg:w-96 text-gray-800 leading-relaxed relative"
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...tooltipProps}
      >
        <strong className="block mb-2">
          {step.title}
        </strong>

        <div className="text-gray-600 whitespace-pre-wrap">
          {step.content}
        </div>

        <div className="mt-5 flex justify-between items-center w-full">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <button type="button" className="border-red-500 text-red-500 underline" {...skipProps}>
            {t.joyride.skip}
          </button>

          {(step.continuous) && (
            <div>
              {(!step.hideBackButton) && (
                <>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <button
                    type="button"
                    className="px-3 py-2 text-base rounded-l-full inline-flex items-center leading-tight font-medium transition-colors bg-white border-l border-y hover:bg-gray-100 focus:border-gray-500"
                    aria-label={backAriaLabel}
                    data-action={backDataAction}
                    onClick={handleBack}
                    role={backRole}
                    title={backTitle}
                  >
                    Vorige
                  </button>
                </>
              )}

              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <button
                type="button"
                className={`${step.hideBackButton ? 'rounded-full' : 'rounded-r-full'} px-3 py-2 text-base inline-flex items-center leading-tight font-medium transition-colors bg-green-500 border border-green-500 text-white hover:bg-green-400 focus:bg-green-400`}
                aria-label={primaryAriaLabel}
                data-action={primaryDataAction}
                onClick={handleNext}
                role={primaryRole}
                title={primaryTitle}
              >
                {t.joyride.next}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Joyride
        continuous={false}
        disableOverlayClose
        disableScrolling
        spotlightClicks
        hideCloseButton
        disableCloseOnEsc
        steps={steps}
        run={joyRideRun}
        tooltipComponent={joyrideTooltip}
        floaterProps={{
          styles: {
            arrow: {
              length: 10,
              spread: 15,
            },
          },
        }}
        getHelpers={(helpers) => setJoyRideHelpers(helpers)}
        callback={handleJoyrideCallback}
      />
    </div>
  );
}

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './SvgIcon.css';
import { ReactComponent as app } from '../../assets/icons/app.svg';
import { ReactComponent as archive } from '../../assets/icons/archive.svg';
import { ReactComponent as bike } from '../../assets/icons/bike.svg';
import { ReactComponent as box } from '../../assets/icons/box.svg';
import { ReactComponent as buoy } from '../../assets/icons/buoy.svg';
import { ReactComponent as building } from '../../assets/icons/building.svg';
import { ReactComponent as calendar } from '../../assets/icons/calendar.svg';
import { ReactComponent as car } from '../../assets/icons/car.svg';
import { ReactComponent as charge } from '../../assets/icons/charge.svg';
import { ReactComponent as check } from '../../assets/icons/check.svg';
import { ReactComponent as checkRound } from '../../assets/icons/check-round.svg';
import { ReactComponent as chevronDown } from '../../assets/icons/chevron-down.svg';
import { ReactComponent as chevronLeft } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as chevronRight } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as chevronUp } from '../../assets/icons/chevron-up.svg';
import { ReactComponent as clock } from '../../assets/icons/clock.svg';
import { ReactComponent as cog } from '../../assets/icons/cog.svg';
import { ReactComponent as cross } from '../../assets/icons/cross.svg';
import { ReactComponent as crossFat } from '../../assets/icons/cross-fat.svg';
import { ReactComponent as crosshair } from '../../assets/icons/crosshair.svg';
import { ReactComponent as dots } from '../../assets/icons/dots.svg';
import { ReactComponent as download } from '../../assets/icons/download.svg';
import { ReactComponent as drag } from '../../assets/icons/drag.svg';
import { ReactComponent as edit } from '../../assets/icons/edit.svg';
import { ReactComponent as en } from '../../assets/icons/en.svg';
import { ReactComponent as exclamation } from '../../assets/icons/exclamation.svg';
import { ReactComponent as externalLink } from '../../assets/icons/external-link.svg';
import { ReactComponent as eye } from '../../assets/icons/eye.svg';
import { ReactComponent as eyeClosed } from '../../assets/icons/eye-closed.svg';
import { ReactComponent as globe } from '../../assets/icons/globe.svg';
import { ReactComponent as grid } from '../../assets/icons/grid.svg';
import { ReactComponent as hamburger } from '../../assets/icons/hamburger.svg';
import { ReactComponent as house } from '../../assets/icons/house.svg';
import { ReactComponent as image } from '../../assets/icons/image.svg';
import { ReactComponent as info } from '../../assets/icons/info.svg';
import { ReactComponent as leaf } from '../../assets/icons/leaf.svg';
import { ReactComponent as lock } from '../../assets/icons/lock.svg';
import { ReactComponent as mail } from '../../assets/icons/mail.svg';
import { ReactComponent as megaphone } from '../../assets/icons/megaphone.svg';
import { ReactComponent as min } from '../../assets/icons/min.svg';
import { ReactComponent as nl } from '../../assets/icons/nl.svg';
import { ReactComponent as note } from '../../assets/icons/note.svg';
import { ReactComponent as noteNoOffset } from '../../assets/icons/note-no-offset.svg';
import { ReactComponent as noteUnread } from '../../assets/icons/note-unread.svg';
import { ReactComponent as padlock } from '../../assets/icons/padlock.svg';
import { ReactComponent as pin } from '../../assets/icons/pin.svg';
import { ReactComponent as pinHead } from '../../assets/icons/pin-head.svg';
import { ReactComponent as plus } from '../../assets/icons/plus.svg';
import { ReactComponent as print } from '../../assets/icons/print.svg';
import { ReactComponent as question } from '../../assets/icons/question.svg';
import { ReactComponent as route } from '../../assets/icons/route.svg';
import { ReactComponent as routeLogicLogo } from '../../assets/icons/routelogic-logo.svg';
import { ReactComponent as save } from '../../assets/icons/save.svg';
import { ReactComponent as scanning } from '../../assets/icons/scanning.svg';
import { ReactComponent as search } from '../../assets/icons/search.svg';
import { ReactComponent as shield } from '../../assets/icons/shield.svg';
import { ReactComponent as sms } from '../../assets/icons/sms.svg';
import { ReactComponent as star } from '../../assets/icons/star.svg';
import { ReactComponent as starHalf } from '../../assets/icons/star-half.svg';
import { ReactComponent as stars } from '../../assets/icons/stars.svg';
import { ReactComponent as sync } from '../../assets/icons/sync.svg';
import { ReactComponent as time } from '../../assets/icons/time-filled.svg';
import { ReactComponent as timeSlot } from '../../assets/icons/time-slot.svg';
import { ReactComponent as trash } from '../../assets/icons/trash.svg';
import { ReactComponent as truck } from '../../assets/icons/truck.svg';
import { ReactComponent as truckLoading } from '../../assets/icons/truck-loading.svg';
import { ReactComponent as unlockedPadlock } from '../../assets/icons/unlocked-padlock.svg';
import { ReactComponent as upload } from '../../assets/icons/upload.svg';
import { ReactComponent as user } from '../../assets/icons/user.svg';
import { ReactComponent as vehicle } from '../../assets/icons/vehicle.svg';
import { ReactComponent as warning } from '../../assets/icons/warning.svg';

const iconMap = {
  app,
  archive,
  bike,
  box,
  building,
  buoy,
  calendar,
  car,
  charge,
  check,
  checkRound,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  clock,
  cog,
  cross,
  crossFat,
  crosshair,
  dots,
  download,
  drag,
  edit,
  en,
  exclamation,
  externalLink,
  eye,
  eyeClosed,
  globe,
  grid,
  hamburger,
  house,
  image,
  info,
  leaf,
  lock,
  mail,
  megaphone,
  min,
  nl,
  note,
  noteNoOffset,
  noteUnread,
  padlock,
  pin,
  pinHead,
  plus,
  print,
  question,
  route,
  routeLogicLogo,
  save,
  scanning,
  search,
  shield,
  sms,
  star,
  starHalf,
  stars,
  sync,
  time,
  timeSlot,
  trash,
  truck,
  truckLoading,
  unlockedPadlock,
  upload,
  user,
  vehicle,
  warning,
};

function SvgIcon({ className, identifier }) {
  if (typeof iconMap[identifier] === 'undefined') {
    throw new Error(`Icon ${identifier} not found`);
  }

  const Icon = iconMap[identifier];

  return (
    <Icon className={`${className} svg-icon`} />
  );
}

SvgIcon.defaultProps = {
  className: '',
};

SvgIcon.propTypes = {
  className: PropTypes.string,
  identifier: PropTypes.oneOf(Object.keys(iconMap)).isRequired,
};

export default memo(SvgIcon);

import { atom } from 'recoil';
import localStorageEffect from '../effects/localStorageEffect';

// eslint-disable-next-line import/prefer-default-export
export const trialUpgradeModalLastOpenAtom = atom({
  key: 'trialUpgradeModalLastOpen',
  default: null,
  effects_UNSTABLE: [
    localStorageEffect('trialUpgradeModalLastOpenAtom'),
  ],
});

import { atom } from 'recoil';

export const translationsLoadingStateAtom = atom({
  key: 'translationsLoadingStateAtom',
  default: 'loading',
});

export const translationsAtom = atom({
  key: 'translations',
  default: {
    //
  },
});

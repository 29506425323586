/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';
import ConnectForm from './ConnectForm';
import HelpLabel from './HelpLabel';

export default function SelectInput({
  label,
  labelHelp,
  id,
  schema,
  placeholder,
  options,
  onChange,
}) {
  const methods = useFormContext();

  const isRequired = schema.exclusiveTests?.required || false;

  const requiredLabel = isRequired
    ? <abbr className="text-red-600 ml-1" title="Required">*</abbr>
    : null;

  let placeholderElement = null;

  if (placeholder) {
    placeholderElement = (
      <option value="">{placeholder}</option>
    );
  }

  const optionElements = [];

  Object.keys(options).forEach((optionKey) => {
    optionElements.push((
      <option value={optionKey} key={optionKey}>{options[optionKey]}</option>
    ));
  });

  // Very hacky, but on dynamic options, the select does not rerender. By explicitely
  // resetting the value when the options change, it does.
  useEffect(() => {
    methods.setValue(id, methods.getValues(id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <ConnectForm>
      {({ register, error }) => (
        <>
          <label htmlFor={id}>
            <div className="block mb-4 font-bold">
              {label}
              {requiredLabel}
              <HelpLabel text={labelHelp} />
            </div>
            <select
              className={`form-control ${error ? 'form-control--error' : ''}`}
              name={id}
              id={id}
              {...register(id, {
                onChange,
              })}
            >
              {placeholderElement}
              {optionElements}
            </select>
          </label>
          <ErrorMessage message={error} />
        </>
      )}
    </ConnectForm>
  );
}

SelectInput.defaultProps = {
  errors: {},
  schema: {},
  placeholder: null,
  options: {},
  labelHelp: null,
  onChange: () => {},
};

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  labelHelp: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object,
  placeholder: PropTypes.string,
  options: PropTypes.objectOf(PropTypes.string),
  onChange: PropTypes.func,
};

import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import localeFromWindow from '../helpers/localeFromWindow';
import { userAtom } from '../store/atoms/auth';

const acceptedLocales = [
  'nl',
  'en',
];

export default function useLocaleKey() {
  const user = useRecoilValue(userAtom);

  const locale = useMemo(() => {
    return user?.preferred_locale || localeFromWindow(acceptedLocales);
  }, [user]);

  return locale;
}

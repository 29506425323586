import { setLocale } from 'yup';

export default function setupYupLocale(translations) {
  const { validation } = translations;

  setLocale({
    mixed: {
      default: validation.mixed_default,
      required: validation.mixed_required,
    },
    string: {
      required: validation.string_required,
      min: ({ min }) => validation.string_min.replace('%s', min),
      max: ({ max }) => validation.string_max.replace('%s', max),
      email: ({ path, label }) => validation.email.replace('%s', label || path),
    },
    number: {
      min: ({ min, label, path }) => validation.number_greater_than_or_equal.replace('%s', label || path).replace('%d', min),
      max: ({ max, label, path }) => validation.number_less_than_or_equal.replace('%s', label || path).replace('%d', max),
    },
  });
}

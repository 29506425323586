import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import SvgIcon from '../SvgIcon';

export default function Button({
  children,
  buttonType,
  styleType,
  className,
  onClick,
  dropdown,
  dropdownAlignment,
  label,
  onLabelClick,
  disabled,
  icon,
  iconClass,
  to,
  buttonId,
}) {
  let buttonClassName = `${className} btn`;

  if (!disabled) {
    if (styleType === 'empty') {
      buttonClassName += ' btn--empty';
    } else if (styleType === 'default') {
      buttonClassName += ' btn--default';
    } else if (styleType === 'primary') {
      buttonClassName += ' btn--primary';
    } else if (styleType === 'positive') {
      buttonClassName += ' btn--positive';
    } else if (styleType === 'negative') {
      buttonClassName += ' btn--negative';
    }
  } else {
    buttonClassName += ' bg-gray-200 text-gray-500 border';
  }

  const labelElement = useMemo(() => {
    if (!label) {
      return null;
    }

    const labelClass = 'rounded-full bg-white -my-1 ml-2 -mr-2 py-1 px-2 inline-block align-middle text-primary text-xs font-bold';

    if (onLabelClick) {
      return (
        <button className={labelClass} type="button" onClick={onLabelClick}>
          {label}
        </button>
      );
    }

    return (
      <span className={labelClass}>
        {label}
      </span>
    );
  }, [label, onLabelClick]);

  const iconElement = icon
    ? <SvgIcon identifier={icon} className={iconClass} />
    : null;

  let dropdownClass = 'absolute bg-white rounded-md shadow-md divide-y divide-gray-100 z-10 overflow-hidden';

  if (dropdownAlignment === 'left') {
    dropdownClass += ' left-0 mt-2';
  } else if (dropdownAlignment === 'right') {
    dropdownClass += ' right-0 mt-2';
  } else if (dropdownAlignment === 'top-left') {
    dropdownClass += ' left-0 bottom-full mb-2';
  } else if (dropdownAlignment === 'top-right') {
    dropdownClass += ' right-0 bottom-full mb-2';
  } else if (dropdownAlignment === 'center') {
    dropdownClass += ' left-1/2 transform -translate-x-1/2 mt-2';
  }

  if (dropdown) {
    return (
      <Menu as="div" className="inline-block relative">
        <Menu.Button className={buttonClassName}>
          {iconElement}
          {children}
          {labelElement}
        </Menu.Button>

        <Menu.Items
          className={dropdownClass}
        >
          {dropdown}
        </Menu.Items>
      </Menu>
    );
  }

  if (to) {
    return (
      <NavLink
        to={to}
        className={buttonClassName}
        disabled={disabled}
      >
        {iconElement}
        {children}
        {labelElement}
      </NavLink>
    );
  }

  return (
    <button
      id={buttonId}
      type={buttonType === 'button' ? 'button' : 'submit'}
      className={buttonClassName}
      onClick={onClick}
      disabled={disabled}
    >
      {iconElement}
      {children}
      {labelElement}
    </button>
  );
}

Button.defaultProps = {
  styleType: 'default',
  buttonType: 'button',
  onClick: () => {},
  className: '',
  label: null,
  onLabelClick: null,
  dropdown: null,
  dropdownAlignment: 'right',
  disabled: false,
  icon: null,
  iconClass: null,
  to: null,
  buttonId: null,
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  buttonType: PropTypes.string,
  styleType: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  onLabelClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconClass: PropTypes.string,
  dropdown: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  dropdownAlignment: PropTypes.oneOf(['right', 'left', 'top-right', 'top-left', 'center']),
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  buttonId: PropTypes.string,
};

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';
import { userAtom } from '../../store/atoms/auth';
import { activeSubscriptionSelector, subscriptionPlanSelector, trialRemainingDaysSelector } from '../../store/selectors/auth';
import useSubscriptionModalActions from '../../store/actions/subscriptionModal';
import TrialExpired from './TrialExpired';
import TrialExpiring from './TrialExpiring';
import StartTrial from './StartTrial';
import EmailVerification from './EmailVerification';

export default function NotificationBar({ className }) {
  const user = useRecoilValue(userAtom);
  const subscriptionPlan = useRecoilValue(subscriptionPlanSelector);
  const subscriptionModalActions = useSubscriptionModalActions();
  const trialRemainingDays = useRecoilValue(trialRemainingDaysSelector);
  const activeSubscription = useRecoilValue(activeSubscriptionSelector);
  const location = useLocation();

  const toggleSubscriptionTrialModal = useCallback(() => {
    subscriptionModalActions.toggleTrialModal(true);
  }, [subscriptionModalActions]);

  const toggleSubscriptionModal = useCallback(() => {
    subscriptionModalActions.toggleModal(true);
  }, [subscriptionModalActions]);

  const showVerify = !location.pathname.startsWith('/schedules/')
    && !location.pathname.startsWith('/register');

  const barContents = useMemo(() => {
    if (user && !user.email_verified_at && showVerify) {
      return <EmailVerification />;
    }

    if (user && !user.company.has_had_subscription && !subscriptionPlan) {
      return <StartTrial toggleSubscriptionTrialModal={toggleSubscriptionTrialModal} />;
    }

    // Remaining days could be null when the trial has expired
    if (!trialRemainingDays) {
      // If there's no active subscription (trial expired), show message to upgrade
      if (!subscriptionPlan && !activeSubscription) {
        return <TrialExpired toggleSubscriptionModal={toggleSubscriptionModal} />;
      }

      return null;
    }

    return <TrialExpiring toggleSubscriptionModal={toggleSubscriptionModal} />;
  }, [
    user,
    trialRemainingDays,
    toggleSubscriptionModal,
    toggleSubscriptionTrialModal,
    activeSubscription,
    subscriptionPlan,
    showVerify,
  ]);

  if (!user) {
    return null;
  }

  if (!barContents) {
    return null;
  }

  return (
    <div className={`${className} bg-yellow-600 text-white text-lg print:hidden flex flex-wrap gap-x-6 gap-y-4`}>
      {barContents}
    </div>
  );
}

NotificationBar.defaultProps = {
  className: '',
};

NotificationBar.propTypes = {
  className: PropTypes.string,
};

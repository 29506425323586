// List from https://nordpass.com/json-data/top-worst-passwords/pdfs/worst-passwords-2020.pdf
// Excluding passwords < 6 characters
export default [
  '123456',
  '123456789',
  'picture1',
  'password',
  '12345678',
  '111111',
  '123123',
  '1234567890',
  '1234567',
  'qwerty',
  'abc123',
  'Million2',
  '000000',
  'iloveyou',
  'aaron431',
  'password1',
  'qqww1122',
  'omgpop',
  '123321',
  '654321',
  'qwertyuiop',
  'qwer123456',
  '123456a',
  'a123456',
  '666666',
  'asdfghjkl',
  'ashley',
  '987654321',
  'unknown',
  'zxcvbnm',
  '112233',
  'chatbooks',
  '20100728',
  '123123123',
  'princess',
  'jacket025',
  '123abc',
  '123qwe',
  'sunshine',
  '121212',
  'dragon',
  '1q2w3e4r',
  '5201314',
  '159753',
  '123456789',
];

import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import PageLoader from '../../components/PageLoader';
import useSubscriptionActions from '../../store/actions/subscription';
import { subscriptionModalOpenAtom } from '../../store/atoms/subscriptionModal';

export default function InternalSubscription() {
  const subscriptionActions = useSubscriptionActions();
  const params = useParams();
  const navigate = useNavigate();

  const [
    subscriptionModalOpen,
    setSubscriptionModalOpen,
  ] = useRecoilState(subscriptionModalOpenAtom);

  useEffect(() => {
    if (!params.id) {
      subscriptionActions.fetchPortalSession()
        .then((results) => {
          if (results && results.redirect_url) {
            window.location = results.redirect_url;
          }
        }).catch(() => {
          if (subscriptionModalOpen) {
            setSubscriptionModalOpen(false);
          }

          navigate('/');
        });

      return;
    }

    subscriptionActions.checkoutSession(params.id, params.amount || null)
      .then((results) => {
        if (results && results.redirect_url) {
          window.location = results.redirect_url;
        }
      }).catch(() => {
        if (subscriptionModalOpen) {
          setSubscriptionModalOpen(false);
        }

        navigate('/');
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <PageLoader />
  );
}

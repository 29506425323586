import React from 'react';
import { useRecoilValue } from 'recoil';
import { NavLink } from 'react-router-dom';
import Main from '../../components/Main';
import { translationsAtom } from '../../store/atoms/i18n';
import RegisterSidebar from './RegisterSidebar';
import MetaHead from '../../components/MetaHead';
import Container from '../../components/Container';
import RegisterForm from './RegisterForm';

export default function Register() {
  const t = useRecoilValue(translationsAtom);

  return (
    <>
      <MetaHead title={t.register.meta_title} />

      <Main gapLess className="w-full">
        <Container className="py-6 md:py-20 px-6">
          <div className="lg:grid lg:grid-cols-10 gap-6 md:gap-10">
            <div className="lg:col-span-4 mb-6 lg:mb-0 lg:pt-7">
              <RegisterSidebar />
            </div>

            <div className="lg:col-span-6">
              <div className="bg-white rounded-xl shadow-md overflow-hidden p-6 md:p-10">
                <h1 className="text-xl md:text-2xl font-bold mb-3 md:mb-5">
                  {t.register.title}
                </h1>

                <span className="mr-2 inline-flex items-center py-2 px-8 mb-6 bg-[#c2e4d1] rounded-full text-sm font-bold">
                  {t.register.no_credit_card_required}
                </span>

                <span className="inline-flex items-center py-2 px-8 mb-6 bg-[#c2e4d1] rounded-full text-sm font-bold">
                  {t.register.no_tacit_renewal}
                </span>

                <RegisterForm />
              </div>

              <div className="sm:flex pt-6">
                <div className="mb-4 sm:mb-0">
                  <NavLink
                    className="text-gray-600 hover:text-black transition-colors inline-block pb-1 border-b border-gray-300 hover:border-gray-500 mr-4 md:mr-6"
                    to="/login"
                  >
                    {t.register.login}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Main>
    </>
  );
}

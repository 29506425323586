import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import Main from '../../components/Main';
import { translationsAtom } from '../../store/atoms/i18n';
import useAuthActions from '../../store/actions/auth';

export default function Logout() {
  const t = useRecoilValue(translationsAtom);
  const authActions = useAuthActions();
  const navigate = useNavigate();

  useEffect(() => {
    authActions.logout()
      .then(() => {
        navigate('/login');
      });
  }, [authActions, navigate]);

  return (
    <Main>
      {t.login.title}
    </Main>
  );
}

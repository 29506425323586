import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import Button from '../../components/Button';
import Form from '../../components/Form';
import RadioInput from '../../components/FormFields/RadioInput';
import TextInput from '../../components/FormFields/TextInput';
import ValidationErrors from '../../components/ValidationErrors';
import analytics from '../../helpers/analytics';
import identifyWithUserResponse from '../../helpers/identifyWithUserResponse';
import useRegisterSchema from '../../schemas/registerSchema';
import useAuthActions from '../../store/actions/auth';
import useRegisterActions from '../../store/actions/register';
import useToastActions from '../../store/actions/toasts';
import { apiErrorsFamily, apiLoadingFamily } from '../../store/atoms/api';
import { translationsAtom } from '../../store/atoms/i18n';
import { toastTypes } from '../../store/atoms/toasts';
import useLocaleKey from '../../hooks/useLocaleKey';

export default function RegisterForm() {
  const t = useRecoilValue(translationsAtom);
  const { schema, cyclicDependencies } = useRegisterSchema();
  const registerActions = useRegisterActions();
  const authActions = useAuthActions();
  const navigate = useNavigate();
  const toastActions = useToastActions();
  const params = new URLSearchParams(window.location.search);
  const registerLoadingState = useRecoilValue(apiLoadingFamily('register'));
  const registerErrorsState = useRecoilValue(apiErrorsFamily('register'));
  const [activeType, setActiveType] = useState('business');
  const localeKey = useLocaleKey();

  const onWatchChange = useCallback((formData) => {
    setActiveType(formData.type);
  }, []);

  const defaultValues = {
    type: activeType,
    expected_vehicles_count: 5,
    parameters: {
      gclid: params.get('gclid'),
      first_attribution: params.get('first_attribution'),
      last_attribution: params.get('last_attribution'),
    },
    locale: localeKey,
  };

  const onSubmit = useCallback((registerData) => {
    registerActions.register(registerData).then((user) => {
      identifyWithUserResponse(user);

      analytics.track('Register', {
        category: 'Register',
        userId: user.id,
        label: user.id,
      });

      analytics.track('Login', {
        category: 'Login',
        userId: user.id,
        label: user.id,
      });

      toastActions.addGlobalToastItem(t.register.account_created, toastTypes.SUCCESS, 10000);

      // I'm not sure why, but if we redirect to step-1 immediately, it might
      // fail. Probably because the user session is not correctly set yet.
      authActions.fetchUser().then(() => {
        navigate('/register/step-1-intro');
      });
    });
  }, [registerActions, navigate, t, toastActions, authActions]);

  return (
    <Form
      schemaObject={schema}
      schemaCyclicDependencies={cyclicDependencies}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      watches={['type']}
      onWatchChange={onWatchChange}
    >
      <ValidationErrors errors={registerErrorsState} />

      <div className="mb-6">
        <RadioInput
          label={t.register.type}
          id="type"
          schema={schema.type}
          options={{
            business: t.register.type_business,
            private: t.register.type_private,
          }}
        />
      </div>

      {
        activeType === 'business'
          ? (
            <>
              <div className="mb-6">
                <TextInput
                  label={t.register.company_name}
                  id="company_name"
                  schema={schema.company_name}
                />
              </div>

              <div className="mb-6">
                <TextInput
                  label={t.register.name}
                  id="name"
                  schema={schema.name}
                />
              </div>

              <div className="mb-6">
                <TextInput
                  label={t.register.phone_number}
                  id="phone_number"
                  schema={schema.phone_number}
                  inputType="tel"
                />
              </div>

              <div className="mb-6">
                <TextInput
                  label={t.register.email}
                  id="email"
                  schema={schema.email}
                  inputType="email"
                />
              </div>

              <div className="mb-6">
                <TextInput
                  label={t.register.password}
                  id="password"
                  schema={schema.password}
                  inputType="password"
                />
              </div>

              <div className="mb-6">
                <TextInput
                  label={t.register.acquisition_explanation}
                  id="acquisition_explanation"
                  schema={schema.acquisition_explanation}
                />
              </div>

              <div
                className="mb-6 text-gray-500"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t.register.agreement
                    .replace('%s', `<a class="underline hover:text-primary" href="https://www.routelogic.nl/gebruiksvoorwaarden-routelogic" target="_blank">${t.register.agreement_link_text}</a>`)
                    .replace('%s', `<a class="underline hover:text-primary" href="https://www.routelogic.nl/privacy-verklaring" target="_blank">${t.register.privacy_link_text}</a>`),
                }}
              />

              <Button
                styleType="positive"
                buttonType="submit"
                className="w-full justify-center font-bold"
                disabled={registerLoadingState === 'loading'}
              >
                {t.register.submit_button}
              </Button>
            </>
          )
          : (
            <div className="bg-gray-100 rounded p-6 md:p-10 text-lg">
              {t.register.private_message}
            </div>
          )
      }
    </Form>
  );
}

RegisterForm.defaultProps = {
  //
};

RegisterForm.propTypes = {
  //
};

import { differenceInDays, isFuture, parseISO } from 'date-fns';
import { selector, selectorFamily } from 'recoil';
import { userAtom } from '../atoms/auth';

export const userHasFeatureSelector = selectorFamily({
  key: 'userHasFeatureSelector',
  get: (feature) => ({ get }) => {
    const user = get(userAtom);

    if (!user) {
      return false;
    }

    const { company } = user;
    const features = company.allowed_features;

    if (typeof features[feature] === 'undefined') {
      // eslint-disable-next-line no-console
      console.error(`${feature} feature not found`);

      return false;
    }

    return features[feature];
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

export const isFreemiumSelector = selector({
  key: 'isFreemiumSelector',
  get: ({ get }) => {
    const user = get(userAtom);

    return !user;
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

export const companySelector = selector({
  key: 'companySelector',
  get: ({ get }) => {
    const user = get(userAtom);

    if (!user || !user.company) {
      return null;
    }

    return user.company;
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

export const creditBalanceSelector = selector({
  key: 'creditBalanceSelector',
  get: ({ get }) => {
    const user = get(userAtom);

    if (!user || !user.company || !user.company.credit_balance) {
      return 0;
    }

    const companyCreditBalance = user.company.credit_balance;

    if (Number.isNaN(companyCreditBalance)) {
      return 0;
    }

    return companyCreditBalance;
  },
});

export const activeSubscriptionSelector = selector({
  key: 'activeSubscriptionSelector',
  get: ({ get }) => {
    const user = get(userAtom);

    if (!user || !user.company || !user.company.active_subscription) {
      return null;
    }

    return user.company.active_subscription;
  },
});

export const subscriptionPlanSelector = selector({
  key: 'subscriptionPlanSelector',
  get: ({ get }) => {
    const activeSubscription = get(activeSubscriptionSelector);

    if (!activeSubscription) {
      return null;
    }

    return activeSubscription.subscription_plan;
  },
});

export const activeAddonsSelector = selector({
  key: 'activeAddonsSelector',
  get: ({ get }) => {
    const activeSubscription = get(activeSubscriptionSelector);

    if (!activeSubscription) {
      return null;
    }

    return activeSubscription.addons;
  },
});

export const subscriptionsSelector = selector({
  key: 'subscriptionsSelector',
  get: ({ get }) => {
    const user = get(userAtom);

    if (!user || !user.company || !user.company.subscriptions) {
      return null;
    }

    return user.company.subscriptions;
  },
});

export const hasChargebeeSubscriptionSelector = selector({
  key: 'hasChargebeeSubscriptionSelector',
  get: ({ get }) => {
    const user = get(userAtom);

    if (!user || !user.company || !user.company.has_chargebee_subscription) {
      return null;
    }

    return user.company.has_chargebee_subscription;
  },
});

export const subscriptionPlanForIdentifierActiveSelector = selectorFamily({
  key: 'subscriptionPlanForIdentifierActiveSelector',
  get: (identifier) => ({ get }) => {
    const subscriptionPlan = get(subscriptionPlanSelector);

    if (!subscriptionPlan && identifier === 'free') {
      return true;
    }

    if (!subscriptionPlan) {
      return false;
    }

    return subscriptionPlan.identifier === identifier;
  },
});

export const subscriptionPlanQueryParamsSelector = selector({
  key: 'subscriptionPlanQueryParamsSelector',
  get: ({ get }) => {
    const subscriptionPlan = get(subscriptionPlanSelector);

    if (subscriptionPlan) {
      return `subscription=${subscriptionPlan.identifier}`;
    }

    const user = get(userAtom);

    if (!user) {
      return 'subscription=freemium';
    }

    return 'subscription=free';
  },
  cachePolicy_UNSTABLE: {
    eviction: 'most-recent',
  },
});

export const trialEndAtSelector = selector({
  key: 'trialEndAtSelector',
  get: ({ get }) => {
    const activeSubscription = get(activeSubscriptionSelector);

    if (!activeSubscription) {
      return null;
    }

    const isTrial = activeSubscription.status === 'in_trial';

    // The text should only be shown when the subscription is in trial.
    if (!isTrial) {
      return null;
    }

    const trialEndAt = parseISO(activeSubscription.trial_end_at);

    // Make sure the trial ends in the future, should not be possible,
    // but to be sure we're working with a correct date.
    if (!isFuture(trialEndAt)) {
      return null;
    }

    return trialEndAt;
  },
});

export const trialRemainingDaysSelector = selector({
  key: 'trialRemainingDaysSelector',
  get: ({ get }) => {
    const trialEndAt = get(trialEndAtSelector);

    if (!trialEndAt) {
      return null;
    }

    // Calculate the difference in days between the trial expire date and now.
    return differenceInDays(trialEndAt, new Date());
  },
});

export const companyHasFlag = selectorFamily({
  key: 'companyHasFlagSelector',
  get: (flag) => ({ get }) => {
    const company = get(companySelector);

    if (!company || !company.flags) {
      return false;
    }

    return company.flags.indexOf(flag) >= 0;
  },
});

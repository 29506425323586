import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import WelcomeModal from '.';
import { translationsAtom } from '../../store/atoms/i18n';
import Button from '../Button';
import CreateScheduleDatepicker from '../CreateScheduleDatepicker';
import useWindowSize from '../../hooks/useWindowSize';

export default function RegisteredWelcomeModal({ onToggle }) {
  const t = useRecoilValue(translationsAtom);
  const { width } = useWindowSize();

  return (
    <WelcomeModal
      isOpen
      onToggleOpen={onToggle}
      description={t.registered_welcome_modal.content}
      buttonChild={(
        <Button
          styleType="primary"
          dropdown={<CreateScheduleDatepicker showTasks={false} size="sm" />}
          dropdownAlignment={width >= 768 ? 'top-left' : 'left'}
          icon="plus"
          iconClass="mr-2 opacity-60"
        >
          {t.registered_welcome_modal.create_route_button}
        </Button>
      )}
    />
  );
}

RegisteredWelcomeModal.defaultProps = {
  //
};

RegisteredWelcomeModal.propTypes = {
  onToggle: PropTypes.func.isRequired,
};

import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import Main from '../../components/Main';
import { translationsAtom } from '../../store/atoms/i18n';
import MetaHead from '../../components/MetaHead';
import Container from '../../components/Container';
import RegisteredWelcomeModal from '../../components/WelcomeModal/RegisteredWelcomeModal';

export default function EmailVerified() {
  const t = useRecoilValue(translationsAtom);
  const navigate = useNavigate();

  const onCloseWelcomeModal = useCallback(() => {
    navigate('/schedules?ref=email-verified');
  }, [navigate]);

  return (
    <>
      <MetaHead title={t.email_verified.meta_title} />

      <Main>
        <RegisteredWelcomeModal
          onToggle={onCloseWelcomeModal}
        />

        <Container className="my-6 md:my-20">
          <div className="bg-white p-6 md:p-10 rounded-xl shadow-md">
            <h1 className="text-2xl md:text-4xl font-bold">
              {t.email_verified.title}
            </h1>
          </div>
        </Container>
      </Main>
    </>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { Dialog } from '@headlessui/react';
import SvgIcon from '../SvgIcon';
import { translationsAtom } from '../../store/atoms/i18n';
import { loadingStates } from '../../hooks/useApi';
import ValidationErrors from '../ValidationErrors';

export default function ConfirmDialog({
  title,
  description,
  submitButtonText,
  cancelButtonText,
  onSubmit,
  onCancel,
  isOpen,
  children,
  loadingState,
  errors,
  className,
}) {
  const t = useRecoilValue(translationsAtom);

  const submitText = submitButtonText || t.words.ok;
  const cancelText = cancelButtonText || t.words.cancel;

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      className={`${className} fixed inset-0 z-30 overflow-y-auto`}
    >
      <Dialog.Overlay
        className="fixed inset-0 bg-black opacity-50"
      />

      <div className="flex items-center justify-center min-h-screen p-4 md:p-10 overflow-hidden">
        <div className="relative bg-white rounded-xl max-w-3xl w-full mx-auto z-40">
          <div className="p-6">
            <Dialog.Title className="text-xl font-bold mb-4">
              {title}
            </Dialog.Title>

            <Dialog.Description className="mb-6 md:mb-8">
              {description}
            </Dialog.Description>

            <ValidationErrors errors={errors} />

            {children}

            <div className="flex">
              <button
                type="button"
                className="btn btn--empty no-row-trigger"
                onClick={onCancel}
                disabled={loadingState === loadingStates.LOADING}
              >
                {cancelText}
              </button>

              <button
                type="button"
                className="btn btn--primary ml-auto no-row-trigger"
                onClick={onSubmit}
                disabled={loadingState === loadingStates.LOADING}
              >
                {submitText}
              </button>
            </div>
          </div>

          <button
            className="bg-primary hover:bg-primary-darker focus:bg-primary-darker transition-colors text-white p-3 md:p-4 absolute top-2 right-2 md:-top-4 md:-right-4 rounded-xl no-row-trigger"
            type="button"
            onClick={onCancel}
          >
            <span className="sr-only">{t.words.close}</span>
            <SvgIcon className="w-5 h-5" identifier="cross" />
          </button>
        </div>
      </div>
    </Dialog>
  );
}

ConfirmDialog.defaultProps = {
  submitButtonText: null,
  cancelButtonText: null,
  onSubmit: () => {},
  isOpen: false,
  children: null,
  loadingState: loadingStates.IDLE,
  errors: [],
  className: '',
};

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  loadingState: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
};

import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Main from '../../components/Main';
import { translationsAtom } from '../../store/atoms/i18n';
import Button from '../../components/Button';
import Form from '../../components/Form';
import TextInput from '../../components/FormFields/TextInput';
import useLoginSchema from '../../schemas/loginSchema';
import useAuthActions from '../../store/actions/auth';
import logo from '../../assets/brand/logo.svg';
import MetaHead from '../../components/MetaHead';
import { apiErrorsFamily, apiLoadingFamily } from '../../store/atoms/api';
import ValidationErrors from '../../components/ValidationErrors';
import analytics from '../../helpers/analytics';
import identifyWithUserResponse from '../../helpers/identifyWithUserResponse';

export default function Login() {
  const t = useRecoilValue(translationsAtom);
  const [schema, cyclicDependencies] = useLoginSchema();
  const authActions = useAuthActions();
  const location = useLocation();
  const navigate = useNavigate();
  const loginLoadingState = useRecoilValue(apiLoadingFamily('login'));
  const loginErrorsState = useRecoilValue(apiErrorsFamily('login'));
  const [showTokenField, setShowTokenField] = useState(false);

  const from = location.state?.from?.pathname || '/';

  const onSubmit = (loginData) => {
    authActions.login(loginData).then((user) => {
      identifyWithUserResponse(user);

      analytics.track('Login', {
        category: 'Login',
        label: user.id,
        userId: user.id,
      });

      navigate(from, { replace: true });
    });
  };

  let errors = loginErrorsState;

  if (loginErrorsState.indexOf('token_required') >= 0) {
    errors = [];

    if (!showTokenField) {
      setShowTokenField(true);
    }
  }

  return (
    <>
      <MetaHead title={t.login.meta_title} />

      <Main>
        <div className="h-full w-full flex items-center justify-center">
          <div className="w-full max-w-lg">
            <div className="bg-white p-6 md:p-10 rounded-xl shadow-md">
              <h1 className="sr-only">
                {t.login.title}
              </h1>

              <img
                src={logo}
                alt="RouteLogic logo"
                width="220"
                height="54"
                className="max-w-full block h-auto mb-6 md:mb-10 mx-auto"
              />

              <Form
                schemaObject={schema}
                schemaCyclicDependencies={cyclicDependencies}
                onSubmit={onSubmit}
              >
                <ValidationErrors errors={errors} />

                <div className="mb-6">
                  <TextInput
                    label={t.login.email}
                    id="email"
                    schema={schema.email}
                    inputType="email"
                  />
                </div>

                <div className="mb-6">
                  <TextInput
                    label={t.login.password}
                    id="password"
                    schema={schema.password}
                    inputType="password"
                  />
                </div>

                {
                  showTokenField
                    ? (
                      <div className="mb-6">
                        <TextInput
                          label={t.login.token}
                          id="token"
                          schema={schema.token}
                          inputType="token"
                          autoFocus
                        />
                      </div>
                    )
                    : null
                }

                <div className="text-center pt-3">
                  <Button
                    styleType="primary"
                    buttonType="submit"
                    className="px-10"
                    disabled={loginLoadingState === 'loading'}
                  >
                    {t.login.login_button}
                  </Button>
                </div>
              </Form>
            </div>

            <div className="text-center pt-3 md:pt-5">
              <NavLink
                className="text-gray-600 hover:text-black transition-colors inline-block pb-1 border-b border-gray-300 hover:border-gray-500 mr-4 md:mr-6"
                to="/register"
              >
                {t.login.register}
              </NavLink>

              <NavLink
                className="text-gray-600 hover:text-black transition-colors inline-block pb-1 border-b border-gray-300 hover:border-gray-500"
                to="/forgot-password"
              >
                {t.login.forgot_password}
              </NavLink>
            </div>
          </div>
        </div>
      </Main>
    </>
  );
}

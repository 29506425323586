import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import SvgIcon from '../../components/SvgIcon';
import { translationsAtom } from '../../store/atoms/i18n';

export default function RegisterReviews({
  className,
}) {
  const t = useRecoilValue(translationsAtom);
  const [activeReviewIndex, setActiveReviewIndex] = useState(0);
  const [reviewLimit, setReviewLimit] = useState(1);
  const { reviews } = t.register;

  useEffect(() => {
    setReviewLimit(reviews?.length ?? 1);
  }, [reviews]);

  const onPrev = useCallback(() => {
    if (activeReviewIndex === 0) {
      setActiveReviewIndex(reviewLimit - 1);
      return;
    }

    setActiveReviewIndex(activeReviewIndex - 1);
  }, [activeReviewIndex, reviewLimit]);

  const onNext = useCallback(() => {
    if (activeReviewIndex === (reviewLimit - 1)) {
      setActiveReviewIndex(0);
      return;
    }

    setActiveReviewIndex(activeReviewIndex + 1);
  }, [activeReviewIndex, reviewLimit]);

  if (!reviews || !reviews.length) {
    return null;
  }

  const activeReview = reviews[activeReviewIndex];

  const stars = [];

  for (let i = 0; i < 5; i += 1) {
    stars.push(<SvgIcon key={i} identifier="star" />);
  }

  return (
    <div className={`${className} bg-white shadow-md rounded-xl p-6 md:p-10 md:text-lg flex flex-col`}>
      <strong className="flex items-center mb-4">
        <div className="flex items-center mr-3">
          {activeReview.client}
          <div className="flex flex-shrink-0 whitespace-nowrap gap-1 ml-3 text-green-500">
            {stars}
          </div>
        </div>

        <div className="ml-auto flex items-center flex-shrink-0">
          <button
            type="button"
            className="flex items-center justify-center w-6 h-8 hover:text-gray-400 transition"
            onClick={onPrev}
          >
            <SvgIcon
              className="w-4 h-4 block mt-1"
              identifier="chevronLeft"
            />
            <span className="sr-only">
              Vorige review
            </span>
          </button>

          <div className="mx-3">
            {`${activeReviewIndex + 1} / ${reviewLimit}`}
          </div>

          <button
            type="button"
            className="flex items-center justify-center w-6 h-8 hover:text-gray-400 transition"
            onClick={onNext}
          >
            <SvgIcon
              className="w-4 h-4 block mt-1"
              identifier="chevronRight"
            />
            <span className="sr-only">
              Volgende review
            </span>
          </button>
        </div>
      </strong>

      <div className="leading-loose">
        {activeReview.text}
      </div>
    </div>
  );
}

RegisterReviews.defaultProps = {
  className: null,
};

RegisterReviews.propTypes = {
  className: PropTypes.string,
};

import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Main from '../../components/Main';
import MetaHead from '../../components/MetaHead';
import Plans from '../../components/Subscription/Plans';
import UnitToggle from '../../components/UnitToggle';
import { loadingStates } from '../../hooks/useApi';
import useSubscriptionActions from '../../store/actions/subscription';
import { apiLoadingFamily } from '../../store/atoms/api';
import { translationsAtom } from '../../store/atoms/i18n';
import { subscriptionsAtom } from '../../store/atoms/subscription';

export default function Subscription() {
  const t = useRecoilValue(translationsAtom);
  const [showPricesUnit, setShowPricesUnit] = useState('yearly');
  const subscriptions = useRecoilValue(subscriptionsAtom);
  const subscriptionsLoadingState = useRecoilValue(apiLoadingFamily('subscriptions'));
  const subscriptionActions = useSubscriptionActions();

  useEffect(() => {
    subscriptionActions.fetchSubscriptions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onShowPricesToggle = useCallback((checkState) => {
    setShowPricesUnit(checkState ? 'yearly' : 'monthly');
  }, []);

  if (!subscriptions || subscriptionsLoadingState === loadingStates.LOADING) {
    return null;
  }

  return (
    <>
      <MetaHead title={t.subscriptions.meta_title} />

      <Main>
        <div className="bg-white rounded-xl p-6 md:p-10 lg:p-14 max-w-384 mx-auto overflow-hidden">
          <div className="flex justify-center mb-6 mb:mb-10 lg:mb-14">
            <UnitToggle
              enabled={showPricesUnit === 'yearly'}
              onChange={onShowPricesToggle}
              leftText={t.subscription_modal.monthly_billing}
              rightText={t.subscription_modal.yearly_billing}
              description={t.subscription_modal.billing_switch_description}
            />
          </div>

          <Plans
            plans={subscriptions.plans}
            features={subscriptions.features}
            extras={subscriptions.extras}
            showPricesUnit={showPricesUnit}
          />
        </div>
      </Main>
    </>
  );
}

Subscription.defaultProps = {
  //
};

Subscription.propTypes = {
  //
};

import { Analytics } from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import linkedinPlugin from 'analytics-plugin-linkedin';
import mixpanelPlugin from './analytics/mixpanel';
import packageJson from '../../package.json';
import googleGtagAnalytics from './analytics/gtag';
import debugAnalytics from './analytics/debug';

const analyticsPlugins = [];

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_ANALYTICS_TID) {
  analyticsPlugins.push(googleGtagAnalytics({
    trackingId: process.env.REACT_APP_ANALYTICS_TID,
    linker: {
      domains: ['routelogic.io', 'app.routelogic.io'],
    },
  }));
}

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_MIXPANEL_TOKEN) {
  analyticsPlugins.push(mixpanelPlugin({
    token: process.env.REACT_APP_MIXPANEL_TOKEN,
    ignoreDnt: true,
    // debug: process.env.NODE_ENV !== 'production',
  }));
}

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_GTM_ID) {
  analyticsPlugins.push(googleTagManager({
    containerId: process.env.REACT_APP_GTM_ID,
  }));
}

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_LINKEDIN_ID) {
  analyticsPlugins.push(linkedinPlugin({
    partnerId: process.env.REACT_APP_LINKEDIN_ID,
  }));
}

if (process.env.NODE_ENV !== 'production') {
  analyticsPlugins.push(debugAnalytics({}));
}

const analytics = Analytics({
  app: packageJson.name,
  version: packageJson.version,
  plugins: analyticsPlugins,
});

export default analytics;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { differenceInHours, differenceInMinutes, parseISO } from 'date-fns';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userAtom } from '../../store/atoms/auth';
import { companySelector, trialRemainingDaysSelector } from '../../store/selectors/auth';
import TrialJustStarted from './TrialJustStarted';
import SvgIcon from '../SvgIcon';
import { translationsAtom } from '../../store/atoms/i18n';
import { trialUpgradeModalLastOpenAtom } from '../../store/atoms/trialUpgrade';
import { trialUpgradeModalLastOpenSelector } from '../../store/selectors/trialUpgrade';
import TrialEnding from './TrialEnding';
import TrialEnded from './TrialEnded';
import useSubscriptionModalActions from '../../store/actions/subscriptionModal';
import analytics from '../../helpers/analytics';

export default function TrialUpgradeModal() {
  const t = useRecoilValue(translationsAtom);
  const user = useRecoilValue(userAtom);
  const company = useRecoilValue(companySelector);
  const trialRemainingDays = useRecoilValue(trialRemainingDaysSelector);
  const setTrialUpgradeModalLastOpen = useSetRecoilState(trialUpgradeModalLastOpenAtom);
  const trialUpgradeModalLastOpen = useRecoilValue(trialUpgradeModalLastOpenSelector);
  const subscriptionModalActions = useSubscriptionModalActions();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const hasTrialExpired = useMemo(() => {
    return company?.has_had_subscription && trialRemainingDays === null;
  }, [company, trialRemainingDays]);

  const modalContentType = useMemo(() => {
    if (hasTrialExpired) return 'trial-ended';
    if (trialRemainingDays === null) return null;
    if (trialRemainingDays >= 7) return 'trial-just-started';
    if (trialRemainingDays >= 0) return 'trial-ending';
    return null;
  }, [trialRemainingDays, hasTrialExpired]);

  const onClose = useCallback((ref = 'close') => {
    setTrialUpgradeModalLastOpen(new Date());

    analytics.track('Trial upgrade modal', {
      category: 'Trial upgrade modal',
      label: modalContentType,
      action: `click ${ref}`,
    });
  }, [setTrialUpgradeModalLastOpen, modalContentType]);

  const onOpenSubscriptionModal = useCallback(() => {
    onClose('upgrade');
    subscriptionModalActions.toggleModal(true);
  }, [subscriptionModalActions, onClose]);

  const modalContents = useMemo(() => {
    if (modalContentType === 'trial-ended') {
      return <TrialEnded onOpenSubscriptionModal={onOpenSubscriptionModal} />;
    }

    if (modalContentType === 'trial-just-started') {
      return <TrialJustStarted onOpenSubscriptionModal={onOpenSubscriptionModal} />;
    }

    if (modalContentType === 'trial-ending') {
      return <TrialEnding onOpenSubscriptionModal={onOpenSubscriptionModal} />;
    }

    return null;
  }, [modalContentType, onOpenSubscriptionModal]);

  useEffect(() => {
    if (modalContentType && isModalVisible) {
      analytics.track('Trial upgrade modal', {
        category: 'Trial upgrade modal',
        label: modalContentType,
        action: 'view',
      });
    }
  }, [modalContentType, isModalVisible]);

  // If:
  // - Not logged in
  // - Or the user has an active subscription (not a trial)
  if (!user || company?.active_subscription?.status === 'active') {
    return null;
  }

  const userCreatedAt = parseISO(user.created_at);
  const minutesSinceUserCreation = differenceInMinutes(new Date(), userCreatedAt);

  // When the user has just been created, we do not want to bother them
  // with a modal. They're just exploring the tool.
  if (minutesSinceUserCreation < 30) {
    return null;
  }

  // Only open the modal when it has not opened yet, or the modal
  // is not seen in the last 8 hours.
  const modalOpen = trialUpgradeModalLastOpen
    ? differenceInHours(new Date(), trialUpgradeModalLastOpen) > 8
    : true;

  if (!modalContents) {
    return null;
  }

  if (!isModalVisible && modalOpen) {
    setIsModalVisible(true);
  }

  return (
    <Transition
      show={modalOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        as="div"
        onClose={onClose}
        className="fixed inset-0 z-30 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen p-4 md:p-10 overflow-hidden">
          <Dialog.Overlay
            className="fixed inset-0 bg-black opacity-30"
          />

          <div className="relative bg-white rounded-xl max-w-3xl w-full mx-auto z-40">
            <div className="overflow-hidden w-full max-w-3xl p-6 md:p-10 lg:p-14">
              {modalContents}
            </div>

            <button
              className="bg-primary hover:bg-primary-darker focus:bg-primary-darker transition-colors text-white p-3 md:p-4 absolute top-2 right-2 md:-top-4 md:-right-4 rounded-xl"
              type="button"
              onClick={onClose}
            >
              <span className="sr-only">{t.words.close}</span>
              <SvgIcon className="w-5 h-5" identifier="cross" />
            </button>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

TrialUpgradeModal.defaultProps = {
  //
};

TrialUpgradeModal.propTypes = {
  //
};

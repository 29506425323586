import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Main from '../../components/Main';
import { translationsAtom } from '../../store/atoms/i18n';
import Button from '../../components/Button';
import Form from '../../components/Form';
import TextInput from '../../components/FormFields/TextInput';
import useAuthActions from '../../store/actions/auth';
import { userAtom } from '../../store/atoms/auth';
import useForgotPasswordSchema from '../../schemas/forgotPasswordSchema';
import useToastActions from '../../store/actions/toasts';
import { toastTypes } from '../../store/atoms/toasts';
import MetaHead from '../../components/MetaHead';
import { apiErrorsFamily, apiLoadingFamily } from '../../store/atoms/api';
import ValidationErrors from '../../components/ValidationErrors';

export default function ForgotPassword() {
  const t = useRecoilValue(translationsAtom);
  const [schema, cyclicDependencies] = useForgotPasswordSchema();
  const authActions = useAuthActions();
  const location = useLocation();
  const navigate = useNavigate();
  const forgotPasswordLoadingState = useRecoilValue(apiLoadingFamily('forgot-password'));
  const forgotPasswordErrorsState = useRecoilValue(apiErrorsFamily('forgot-password'));
  const user = useRecoilValue(userAtom);
  const toastActions = useToastActions();

  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
    if (user) {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate, from, user]);

  const onSubmit = (forgotPasswordData) => {
    authActions.requestPasswordReset(forgotPasswordData).then(() => {
      navigate('/login', { replace: true });
      toastActions.addGlobalToastItem(t.forgot_password.request_sent, toastTypes.SUCCESS, 8000);
    });
  };

  return (
    <>
      <MetaHead title={t.forgot_password.meta_title} />

      <Main>
        <div className="h-full w-full flex items-center justify-center">
          <div className="w-full max-w-lg">
            <div className="bg-white p-6 md:p-10 rounded-lg shadow-md">
              <h1 className="text-center font-bold text-xl md:text-2xl mb-6 md:mb-10">
                {t.forgot_password.title}
              </h1>

              <Form
                schemaObject={schema}
                schemaCyclicDependencies={cyclicDependencies}
                onSubmit={onSubmit}
              >
                <ValidationErrors errors={forgotPasswordErrorsState} />

                <div className="mb-6">
                  <TextInput
                    label={t.forgot_password.email}
                    id="email"
                    schema={schema.email}
                    inputType="email"
                  />
                </div>

                <div className="text-center pt-3">
                  <Button
                    styleType="primary"
                    buttonType="submit"
                    className="px-10"
                    disabled={forgotPasswordLoadingState === 'loading'}
                  >
                    {t.forgot_password.submit_button}
                  </Button>
                </div>
              </Form>
            </div>

            <div className="text-center pt-3 md:pt-5">
              <NavLink
                className="text-gray-600 hover:text-black transition-colors inline-block pb-1 border-b border-gray-300 hover:border-gray-500"
                to="/login"
              >
                {t.forgot_password.back_to_login}
              </NavLink>
            </div>
          </div>
        </div>
      </Main>
    </>
  );
}

import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

export default function VimeoVideo({
  className,
  videoId,
  hash,
  onPlay,
  onReady,
  title,
}) {
  const videoRef = useRef(null);
  const [id] = useState(uniqueId('iframe-'));

  const onVimeoReady = useCallback((iframeId) => {
    const iframe = document.getElementById(iframeId);

    if (iframe) {
      videoRef.current = new window.Vimeo.Player(iframe);

      onReady();

      videoRef.current.on('play', () => {
        onPlay();
      });
    }
  }, [onReady, onPlay]);

  useEffect(() => {
    if (typeof window.Vimeo === 'undefined') {
      const tag = document.createElement('script');
      tag.src = 'https://player.vimeo.com/api/player.js';
      tag.onload = () => onVimeoReady(id);
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      onVimeoReady(id);
    }

    return () => {
      if (videoRef.current && typeof videoRef.current.destroy === 'function') {
        videoRef.current.destroy();
        videoRef.current = null;
      }
    };
  }, [onVimeoReady, id]);

  return (
    <iframe
      id={id}
      title={title}
      className={className}
      src={`https://player.vimeo.com/video/${videoId}?h=${hash}`}
      width="640"
      height="480"
      allowFullScreen
    />
  );
}

VimeoVideo.defaultProps = {
  onPlay: () => {},
  onReady: () => {},
  className: '',
  title: 'Video',
};

VimeoVideo.propTypes = {
  videoId: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  className: PropTypes.string,
  onPlay: PropTypes.func,
  onReady: PropTypes.func,
  title: PropTypes.string,
};

import React, { useCallback, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useRecoilValue } from 'recoil';
import { subscriptionTrialModalOpenAtom } from '../../store/atoms/subscriptionModal';
import { translationsAtom } from '../../store/atoms/i18n';
import useSubscriptionModalActions from '../../store/actions/subscriptionModal';
import SvgIcon from '../../components/SvgIcon';
import analytics from '../../helpers/analytics';

import unlimitedTasksAndVehiclesIcon from '../../assets/trial-icons/unlimited_tasks_and_vehicles.svg';
import trackAndTraceIcon from '../../assets/trial-icons/track_and_trace.svg';
import driverAppIcon from '../../assets/trial-icons/driver_app.svg';
import realtimeVehicleLocationsIcon from '../../assets/trial-icons/realtime_vehicle_locations.svg';
import timeContraintsIcon from '../../assets/trial-icons/time_contraints.svg';
import apiIcon from '../../assets/trial-icons/api.svg';

export default function SubscriptionTrialModal() {
  const t = useRecoilValue(translationsAtom);
  const subscriptionTrialModalOpen = useRecoilValue(subscriptionTrialModalOpenAtom);
  const subscriptionModalActions = useSubscriptionModalActions();

  useEffect(() => {
    if (subscriptionTrialModalOpen) {
      analytics.track('Subscription trial modal open', {
        category: 'Subscription trial modal open',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionTrialModalOpen]);

  const onSubscribeClick = useCallback(() => {
    analytics.track('Subscription trial modal submit', {
      category: 'Subscription trial modal submit',
    });

    subscriptionModalActions.toggleTrialModal(false);
    subscriptionModalActions.toggleModal(true);
  }, [subscriptionModalActions]);

  const blockClass = 'relative p-6 md:py-8 border border-gray-200 rounded-xl text-center text-gray-500';
  const labelClass = 'absolute top-3 right-4 text-sm text-gray-400';
  const iconClass = 'w-14 h-14 mb-4 mx-auto';

  return (
    <Transition
      show={subscriptionTrialModalOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        as="div"
        onClose={() => subscriptionModalActions.toggleTrialModal(false)}
        className="fixed inset-0 z-30 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen p-4 md:p-10 overflow-hidden">
          <Dialog.Overlay
            className="fixed inset-0 bg-black opacity-30"
          />

          <div className="relative bg-white rounded-xl max-w-5xl w-full mx-auto z-40">
            <div className="overflow-hidden w-full max-w-384 p-6 md:p-10 lg:p-14">
              <Dialog.Title
                className="text-2xl md:text-4xl mb-6"
                dangerouslySetInnerHTML={{ __html: t.subscription_trial_modal.title }}
              />

              <Dialog.Description className="text-2xl mb-10 lg:mb-14">
                {t.subscription_trial_modal.description}
              </Dialog.Description>

              <div className="grid grid-cols-2 md:grid-cols-3 gap-6 mb-10 lg:mb-14">
                <div className={blockClass}>
                  <span className={labelClass} aria-hidden="true">PRO</span>
                  <img className={iconClass} width="32" height="32" src={unlimitedTasksAndVehiclesIcon} alt="" />
                  {t.subscription_trial_modal.unlimited_tasks_and_vehicles}
                </div>

                <div className={blockClass}>
                  <span className={labelClass} aria-hidden="true">PRO</span>
                  <img className={iconClass} width="32" height="32" src={trackAndTraceIcon} alt="" />
                  {t.subscription_trial_modal.track_and_trace}
                </div>

                <div className={blockClass}>
                  <span className={labelClass} aria-hidden="true">PRO</span>
                  <img className={iconClass} width="32" height="32" src={driverAppIcon} alt="" />
                  {t.subscription_trial_modal.driver_app}
                </div>

                <div className={blockClass}>
                  <span className={labelClass} aria-hidden="true">PRO</span>
                  <img className={iconClass} width="32" height="32" src={realtimeVehicleLocationsIcon} alt="" />
                  {t.subscription_trial_modal.realtime_vehicle_locations}
                </div>

                <div className={blockClass}>
                  <span className={labelClass} aria-hidden="true">PRO</span>
                  <img className={iconClass} width="32" height="32" src={timeContraintsIcon} alt="" />
                  {t.subscription_trial_modal.time_contraints}
                </div>

                <div className={blockClass}>
                  <span className={labelClass} aria-hidden="true">PRO PLUS</span>
                  <img className={iconClass} width="32" height="32" src={apiIcon} alt="" />
                  {t.subscription_trial_modal.api}
                </div>
              </div>

              <button
                type="button"
                className="btn btn--primary btn--lg px-10"
                onClick={onSubscribeClick}
              >
                {t.subscription_trial_modal.subscribe_button}
              </button>
            </div>

            <button
              className="bg-primary hover:bg-primary-darker focus:bg-primary-darker transition-colors text-white p-3 md:p-4 absolute top-2 right-2 md:-top-4 md:-right-4 rounded-xl"
              type="button"
              onClick={() => subscriptionModalActions.toggleTrialModal(false)}
            >
              <span className="sr-only">{t.words.close}</span>
              <SvgIcon className="w-5 h-5" identifier="cross" />
            </button>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

SubscriptionTrialModal.defaultProps = {
  //
};

SubscriptionTrialModal.propTypes = {
  //
};

import React from 'react';
import PropTypes from 'prop-types';
import InfoBubble from './InfoBubble';

export default function HelpLabel({ text, dangerousLabelHelp, panelClassName }) {
  if (!text) {
    return null;
  }

  return (
    <InfoBubble message={text} dangerousMessage={dangerousLabelHelp} panelClassName={panelClassName} className="inline-block align-middle ml-2" />
  );
}

HelpLabel.defaultProps = {
  text: null,
  dangerousLabelHelp: false,
  panelClassName: '',
};

HelpLabel.propTypes = {
  text: PropTypes.string,
  dangerousLabelHelp: PropTypes.bool,
  panelClassName: PropTypes.string,
};

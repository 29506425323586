import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import Modal from '../Modal';
import { translationsAtom } from '../../store/atoms/i18n';

export default function NoActiveSubscriptionModal({ onModalClose, visible }) {
  const t = useRecoilValue(translationsAtom);

  return (
    <Modal
      isOpen={visible}
      onToggleOpen={onModalClose}
      title={t.subscription_modal.no_active_subscription_modal_title}
    >
      <p>{t.subscription_modal.no_active_subscription_modal_description}</p>
    </Modal>
  );
}

NoActiveSubscriptionModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

NoActiveSubscriptionModal.defaultProps = {
  //
};

import React from 'react';
import PropTypes from 'prop-types';

export default function Alert({ message, type, className }) {
  if (!message) {
    return null;
  }

  let typeClassName = 'bg-green-600 text-white';

  if (type === 'danger') {
    typeClassName = 'bg-red-500 text-white';
  } else if (type === 'warning') {
    typeClassName = 'bg-yellow-500 text-white';
  }

  const fullClassName = `p-4 md:px-6 rounded-xl ${typeClassName} ${className}`;

  if (typeof message !== 'string') {
    return (
      <div className={fullClassName}>
        {message}
      </div>
    );
  }

  /* eslint-disable react/no-danger */
  return (
    <div
      className={fullClassName}
      dangerouslySetInnerHTML={{ __html: message }}
    />
  );
}

Alert.defaultProps = {
  type: 'success',
  className: '',
  message: null,
};

Alert.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  type: PropTypes.string,
  className: PropTypes.string,
};

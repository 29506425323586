import { useRecoilState } from 'recoil';
import { subscriptionModalOpenAtom, subscriptionTrialModalOpenAtom } from '../atoms/subscriptionModal';

export default function useSubscriptionModalActions() {
  const [
    subscriptionModalOpen,
    setSubscriptionModalOpen,
  ] = useRecoilState(subscriptionModalOpenAtom);
  const [
    subscriptionTrialModalOpen,
    setSubscriptionTrialModalOpen,
  ] = useRecoilState(subscriptionTrialModalOpenAtom);

  function toggleModal(forceState = null) {
    setSubscriptionModalOpen(forceState !== null ? forceState : !subscriptionModalOpen);
  }

  function toggleTrialModal(forceState = null) {
    setSubscriptionTrialModalOpen(forceState !== null ? forceState : !subscriptionTrialModalOpen);
  }

  return {
    toggleModal,
    toggleTrialModal,
  };
}

import { atom } from 'recoil';

export const joyRideRunAtom = atom({
  key: 'joyRideRunAtom',
  default: null,
});

export const joyRideHelpersAtom = atom({
  key: 'joyRideHelpersAtom',
  default: null,
});

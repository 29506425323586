import React, { Suspense, lazy } from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';
import Login from './containers/Login';
import Logout from './containers/Logout';
import RequireAuth from './containers/RequireAuth';
import Error from './containers/Error';
import ForgotPassword from './containers/ForgotPassword';
import Register from './containers/Register';
import RequireGuest from './containers/RequireGuest';
import PageLoader from './components/PageLoader';
import InternalSubscription from './containers/InternalSubscription';
import Subscription from './containers/Subscription';
import TwoFactor from './containers/TwoFactor';
import EmailVerified from './containers/EmailVerified';
import SubscriptionUpdate from './containers/SubscriptionUpdate';

const Dashboard = lazy(() => import('./containers/Dashboard'));

const RegisterStepIntro = lazy(() => import('./containers/Register/Steps/RegisterStepIntro'));
const RegisterStepAddress = lazy(() => import('./containers/Register/Steps/RegisterStepAddress'));
const RegisterStepVehicles = lazy(() => import('./containers/Register/Steps/RegisterStepVehicles'));
const RegisterStepTasks = lazy(() => import('./containers/Register/Steps/RegisterStepTasks'));

const Schedules = lazy(() => import('./containers/Schedules'));
const FreemiumSchedule = lazy(() => import('./containers/FreemiumSchedule'));
const ScheduleDetail = lazy(() => import('./containers/ScheduleDetail'));

const Tasks = lazy(() => import('./containers/Tasks'));
const TaskEdit = lazy(() => import('./containers/Tasks/TaskEdit'));
const TaskShowTrashed = lazy(() => import('./containers/Tasks/TaskShowTrashed'));
const TaskCreate = lazy(() => import('./containers/Tasks/TaskCreate'));
const TasksImport = lazy(() => import('./containers/TasksImport'));

const SettingsAccount = lazy(() => import('./containers/SettingsAccount'));
const Support = lazy(() => import('./containers/Support'));
const Faq = lazy(() => import('./containers/Support/Faq'));

const Vehicles = lazy(() => import('./containers/Vehicles'));
const VehicleEdit = lazy(() => import('./containers/Vehicles/VehicleEdit'));
const VehicleCreate = lazy(() => import('./containers/Vehicles/VehicleCreate'));

const Users = lazy(() => import('./containers/Users'));
const UserEdit = lazy(() => import('./containers/Users/UserEdit'));
const UserCreate = lazy(() => import('./containers/Users/UserCreate'));

const Companies = lazy(() => import('./containers/Companies'));
const CompanyEdit = lazy(() => import('./containers/Companies/CompanyEdit'));
const CompanyCreate = lazy(() => import('./containers/Companies/CompanyCreate'));

const Checkout = lazy(() => import('./containers/Checkout'));

const NotificationTemplates = lazy(() => import('./containers/NotificationTemplates'));
const NotificationTemplateCreate = lazy(() => import('./containers/NotificationTemplates/NotificationTemplateCreate'));
const NotificationTemplateEdit = lazy(() => import('./containers/NotificationTemplates/NotificationTemplateEdit'));

const NotificationLogs = lazy(() => import('./containers/NotificationLogs'));
const NotificationLogDetail = lazy(() => import('./containers/NotificationLogs/NotificationLogDetail'));

const Synchronization = lazy(() => import('./containers/Synchronization'));

const ResetPassword = lazy(() => import('./containers/ResetPassword'));
const Impersonate = lazy(() => import('./containers/Impersonate'));
const Page = lazy(() => import('./containers/Page'));
const Stats = lazy(() => import('./containers/Stats'));
const DriverApp = lazy(() => import('./containers/DriverApp'));
const DriverPasswordReset = lazy(() => import('./containers/DriverPasswordReset'));

const ScheduleVisitNotes = lazy(() => import('./containers/ScheduleVisitNotes'));

const Feedback = lazy(() => import('./containers/Feedback'));

function LoadingComponent() {
  return <PageLoader />;
}

export default function Routes() {
  return (
    <ReactRoutes>
      <Route
        path="login"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireGuest><Login /></RequireGuest>
          </Suspense>
        )}
      />
      <Route
        path="logout"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireGuest><Logout /></RequireGuest>
          </Suspense>
        )}
      />
      <Route
        path="forgot-password"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireGuest><ForgotPassword /></RequireGuest>
          </Suspense>
        )}
      />
      <Route
        path="register/step-1-intro"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><RegisterStepIntro /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="register/step-2-address"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><RegisterStepAddress /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="register/step-3-vehicles"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><RegisterStepVehicles /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="register/step-4-tasks"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><RegisterStepTasks /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="register"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireGuest><Register /></RequireGuest>
          </Suspense>
        )}
      />
      <Route
        path="reset-password"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireGuest><ResetPassword /></RequireGuest>
          </Suspense>
        )}
      />
      <Route
        path="impersonate"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <Impersonate />
          </Suspense>
        )}
      />

      <Route
        path="/"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><Dashboard /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="schedules"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><Schedules /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="schedules/:scheduleUuid"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <ScheduleDetail />
          </Suspense>
        )}
      />
      <Route
        path="freemium-schedule"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <FreemiumSchedule />
          </Suspense>
        )}
      />

      <Route
        path="tasks"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><Tasks /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="tasks/create"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><TaskCreate /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="tasks/import"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><TasksImport /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="tasks/:taskId"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><TaskEdit /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="tasks/:taskId/trashed"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><TaskShowTrashed /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="vehicles"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><Vehicles /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="vehicles/create"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><VehicleCreate /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="vehicles/:vehicleId"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><VehicleEdit /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="users"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><Users /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="users/create"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><UserCreate /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="users/:userId"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><UserEdit /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="companies"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><Companies /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="companies/create"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><CompanyCreate /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="companies/:companyId"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><CompanyEdit /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="checkout/:uuid"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><Checkout /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="update-subscription"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><SubscriptionUpdate /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="notification-templates"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><NotificationTemplates /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="notification-templates/create"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><NotificationTemplateCreate /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="notification-templates/:notificationTemplateId"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><NotificationTemplateEdit /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="notification-logs"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><NotificationLogs /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="notification-logs/:notificationLogId"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><NotificationLogDetail /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="settings/account"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><SettingsAccount /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="synchronization"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><Synchronization /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="stats"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><Stats /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="support"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><Support /></RequireAuth>
          </Suspense>
        )}
      />
      <Route
        path="support/faq"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><Faq /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="internal-subscription/:id/:amount"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><InternalSubscription /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="internal-subscription/:id"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><InternalSubscription /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="internal-subscription"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><InternalSubscription /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="subscription"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><Subscription /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="two-factor"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><TwoFactor /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="driver-app"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><DriverApp /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="inbox"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><ScheduleVisitNotes /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="feedback"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><Feedback /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="p/email-verified"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><EmailVerified /></RequireAuth>
          </Suspense>
        )}
      />

      <Route
        path="p/driver-password-reset"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <DriverPasswordReset />
          </Suspense>
        )}
      />

      <Route
        path="p/:page"
        element={(
          <Suspense fallback={<LoadingComponent />}>
            <RequireAuth><Page /></RequireAuth>
          </Suspense>
        )}
      />

      <Route path="*" element={<Error />} />
    </ReactRoutes>
  );
}

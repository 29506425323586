import { atom } from 'recoil';

export const scheduleVisitNotesOverviewItemsAtom = atom({
  key: 'scheduleVisitNotesOverviewItemsAtom',
  default: {},
});

export const scheduleVisitNotesOverviewFiltersAtom = atom({
  key: 'scheduleVisitNotesOverviewFiltersAtom',
  default: {
    page: 1,
    schedule: '',
  },
});

export const scheduleVisitNotesSelectedItemAtom = atom({
  key: 'scheduleVisitNotesSelectedItemAtom',
  default: {},
});

export const scheduleVisitNotesUnreadCountAtom = atom({
  key: 'scheduleVisitNotesUnreadCountAtom',
  default: {
    count: 0,
  },
});

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useApi from '../../hooks/useApi';
import { userAtom } from '../atoms/auth';
import { updatesLastIdAtom, hasNewUpdatesAtom, lastUpdateFetchAtom } from '../atoms/updates';

const MAX_UPDATES_FETCH_IN_SECONDS = 15 * 60;

export default function useUpdateActions() {
  // const setUpdateItems = useSetRecoilState(updateItemsAtom);
  const [updatesLastId, setUpdatesLastId] = useRecoilState(updatesLastIdAtom);
  const [lastUpdateFetch, setLastUpdateFetch] = useRecoilState(lastUpdateFetchAtom);
  const setHasNewUpdates = useSetRecoilState(hasNewUpdatesAtom);
  const user = useRecoilValue(userAtom);

  const { handle: updatesApiHandle } = useApi({});

  async function fetchUpdates() {
    // Only fetch updates when a user is logged in
    if (!user) {
      return;
    }

    const currentTime = (new Date()).getTime();
    const diff = lastUpdateFetch ? currentTime - lastUpdateFetch : null;

    if (diff) {
      const diffInSeconds = diff / 1000;

      // Only load the updates at the max every fifteen minutes (900 seconds)
      if (diffInSeconds < MAX_UPDATES_FETCH_IN_SECONDS) {
        return;
      }
    }

    setLastUpdateFetch(currentTime);

    let results;

    try {
      results = await updatesApiHandle('GET', 'updates-content');
    } catch (err) {
      return;
    }

    if (results && results.last_id) {
      // If there's a last ID in the store (to prevent notifying on first visits)
      // And your last update ID is before the latest available update.
      if (updatesLastId !== null && updatesLastId < results.last_id) {
        setHasNewUpdates(true);
      }

      // Store the last update ID in the store, to compare on future updates
      setUpdatesLastId(results.last_id);
    }
  }

  return {
    fetchUpdates,
  };
}

import React from 'react';
import { useRecoilValue } from 'recoil';
import Main from '../../components/Main';
import MetaHead from '../../components/MetaHead';
import { translationsAtom } from '../../store/atoms/i18n';

export default function Error() {
  const t = useRecoilValue(translationsAtom);

  return (
    <>
      <MetaHead title={t.error.meta_title} />

      <Main>
        <div className="h-full w-full flex items-center justify-center">
          <div className="bg-white p-4 md:p-8 rounded-xl shadow-md w-full max-w-lg">
            <h1 className="text-lg text-center font-bold">
              {t.error.title}
            </h1>
          </div>
        </div>
      </Main>
    </>
  );
}

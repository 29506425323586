import { atom } from 'recoil';
import localStorageEffect from '../effects/localStorageEffect';

// eslint-disable-next-line import/prefer-default-export
export const userAtom = atom({
  key: 'userAtom',
  default: null,
  effects_UNSTABLE: [
    localStorageEffect('userAtom'),
  ],
});

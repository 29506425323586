import { useSetRecoilState } from 'recoil';
import useToastActions from './toasts';
import useApi from '../../hooks/useApi';
import { apiLoadingFamily } from '../atoms/api';
import { subscriptionsAtom } from '../atoms/subscription';
import useAuthActions from './auth';

export default function useSubscriptionActions() {
  const toastActions = useToastActions();
  const authActions = useAuthActions();
  const setSubscriptionsLoadingState = useSetRecoilState(apiLoadingFamily('subscriptions'));
  const setSubscriptions = useSetRecoilState(subscriptionsAtom);

  const { handle: subscriptionApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
  });

  const { handle: subscriptionsApiHandle } = useApi({
    onError: (err) => toastActions.addErrorFromObject(err),
    onLoadingStateChange: (loadingState) => setSubscriptionsLoadingState(loadingState),
  });

  async function fetchPortalSession() {
    const results = await subscriptionApiHandle('post', 'subscription/portal-session', {});

    return results;
  }

  async function checkoutSession(id, amount) {
    const results = await subscriptionApiHandle('post', 'subscription/checkout-session', {
      price_id: id,
      vehicle_count: amount,
    });

    return results;
  }

  async function session() {
    const results = await subscriptionApiHandle('post', 'subscription/subscription-session');

    return results;
  }

  async function fetchSubscriptions() {
    const results = await subscriptionsApiHandle('get', 'subscription-content');

    if (results) {
      setSubscriptions(results);
    }

    return results;
  }

  async function fetchCheckout(id) {
    const results = await subscriptionApiHandle('get', 'subscription/paymentIntent', {
      uuid: id,
    });

    return results;
  }

  async function orderAddon(id) {
    const results = await subscriptionApiHandle('post', 'subscription/order-addon', {
      price_id: id,
    });

    if (results) {
      await authActions.fetchUser();
    }

    return results;
  }

  return {
    checkoutSession,
    session,
    fetchSubscriptions,
    fetchPortalSession,
    fetchCheckout,
    orderAddon,
  };
}

import { atom } from 'recoil';
import { loadingStates } from '../../hooks/useApi';

export const freemiumModalOpenAtom = atom({
  key: 'freemiumModalOpenAtom',
  default: false,
});

export const freemiumLoadingState = atom({
  key: 'freemiumLoadingState',
  default: loadingStates.IDLE,
});

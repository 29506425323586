import { atom } from 'recoil';
import localStorageEffect from '../effects/localStorageEffect';

// eslint-disable-next-line import/prefer-default-export
export const navigationExpandedStateAtom = atom({
  key: 'navigationExpandedStateAtom',
  default: true,
  effects_UNSTABLE: [
    localStorageEffect('navigationExpandedStateAtom'),
  ],
});

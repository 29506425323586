/**
 * @param {Object} response
 * @returns {string[]}
 */
export default (response) => {
  if (!response.data || response.data.errors === undefined || !response.data.errors) {
    return [];
  }

  const errorsAggregate = [];

  const errorsObject = response.data.errors;

  Object.keys(errorsObject).forEach((errorKey) => {
    const errors = errorsObject[errorKey];

    errors.forEach((error) => {
      errorsAggregate.push(error);
    });
  });

  return errorsAggregate;
};

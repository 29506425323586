import mixpanel from 'mixpanel-browser';

export default function pluginMixpanel(userConfig) {
  let mixPanelInstance = null;

  // Return object for analytics to use
  return {
    /* All plugins require a name */
    name: 'mixpanel-plugin',

    /* Everything else below this is optional depending on your plugin requirements */
    config: {
      token: userConfig.token,
      debug: userConfig.debug || false,
      ignoreDnt: userConfig.ignoreDnt || userConfig.debug || false,
      name: userConfig.name || 'mixpanel',
      batchRequests: userConfig.batchRequests || true,
    },

    initialize: ({ config }) => {
      mixPanelInstance = mixpanel.init(config.token, {
        debug: config.debug,
        ignore_dnt: config.ignoreDnt,
        batch_requests: config.batchRequests,
      }, config.name);
    },

    page: ({ payload }) => {
      mixPanelInstance.track('Page', payload.properties);
    },

    track: ({ payload }) => {
      mixPanelInstance.track(payload.event, payload.properties);
    },

    identify: ({ payload }) => {
      const { userId, traits } = payload;

      if (typeof userId === 'string') {
        mixPanelInstance.identify(userId);
      }

      if (traits) {
        mixPanelInstance.people.set(traits);
      }
    },

    loaded: () => {
      // Return boolean so analytics knows when it can send data to third-party
      return !!mixPanelInstance;
    },

    methods: {
      alias(alias, original) {
        mixPanelInstance.alias(alias, original);
      },

      getInstance() {
        return mixPanelInstance;
      },
    },
  };
}

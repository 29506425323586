import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import './Steps.css';
import './StepsArrows.css';

export default function Steps({ activeStepIndex, steps, className }) {
  const stepsElements = useMemo(() => {
    return steps.map((step, index) => {
      const isLast = index === (steps.length - 1);

      return (
        <div
          key={step.title}
          className={`${activeStepIndex >= index ? 'bg-primary text-white' : 'bg-gray-200'} ${isLast ? 'text-right' : 'flex-grow'} flex items-center`}
        >
          <span className={`block ${isLast ? '' : 'pr-4 md:pr-5'} w-full`}>
            {step.title}
          </span>
        </div>
      );
    });
  }, [steps, activeStepIndex]);

  return (
    <div className={`${className} steps flex items-center`}>
      {stepsElements}
    </div>
  );
}

Steps.defaultProps = {
  activeStepIndex: 0,
  className: '',
};

Steps.propTypes = {
  activeStepIndex: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
  })).isRequired,
  className: PropTypes.string,
};

import {
  format,
  formatDistanceToNow,
  parse,
  parseISO,
} from 'date-fns';
import { nl } from 'date-fns/locale';

const locales = { nl };

export default function dateFormat(date, formatStr = 'PP') {
  let dateInstance = date;

  if (!date) {
    return null;
  }

  // Parse time values.
  if (typeof date === 'string' && date.length <= 8 && date.indexOf(':') > 0) {
    if (date.length <= 5) {
      dateInstance = parse(date, 'HH:mm', new Date());
    } else {
      dateInstance = parse(date, 'HH:mm:ss', new Date());
    }
  } else if (typeof date === 'string') {
    dateInstance = parseISO(date);
  }

  if (formatStr === 'distanceToNow') {
    return formatDistanceToNow(dateInstance, {
      locale: locales.nl,
      addSuffix: true,
    });
  }

  return format(dateInstance, formatStr, {
    // eslint-disable-next-line no-underscore-dangle
    locale: locales.nl,
  });
}

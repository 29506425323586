import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { translationsAtom } from '../../store/atoms/i18n';

export default function StartTrial({
  toggleSubscriptionTrialModal,
}) {
  const t = useRecoilValue(translationsAtom);

  return (
    <div>
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: t.notification_bar.try_text }}
      />
      <button
        type="button"
        className="p-2 -my-2 -mr-2 underline hover:opacity-60 focus:opacity-60 transition-all"
        onClick={toggleSubscriptionTrialModal}
      >
        {t.notification_bar.click_here_to_try}
      </button>
    </div>
  );
}

StartTrial.defaultProps = {
  //
};

StartTrial.propTypes = {
  toggleSubscriptionTrialModal: PropTypes.func.isRequired,
};

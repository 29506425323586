import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import Form from '../../components/Form';
import FormGroup from '../../components/FormFields/FormGroup';
import Button from '../../components/Button';
import { apiErrorsFamily, apiLoadingFamily } from '../../store/atoms/api';
import { loadingStates } from '../../hooks/useApi';
import TextInput from '../../components/FormFields/TextInput';
import useFreemiumRegisterSchema from '../../schemas/freemiumRegisterSchema';
import { translationsAtom } from '../../store/atoms/i18n';
import SliderInput from '../../components/FormFields/SliderInput';
import ValidationErrors from '../../components/ValidationErrors';

export default function FreemiumModalForm({
  onSubmit,
  onBack,
  visible,
}) {
  const t = useRecoilValue(translationsAtom);
  const [schema, cyclicDependencies] = useFreemiumRegisterSchema();
  const freemiumLoadingState = useRecoilValue(apiLoadingFamily('freemium'));
  const freemiumErrors = useRecoilValue(apiErrorsFamily('freemium'));

  return (
    <Form
      className={`${visible ? '' : 'hidden'}`}
      schemaObject={schema}
      schemaCyclicDependencies={cyclicDependencies}
      onSubmit={onSubmit}
      defaultValues={{
        expected_vehicles_count: 10,
        agreement: 1,
      }}
    >
      <ValidationErrors
        errors={freemiumErrors}
      />

      <FormGroup>
        <TextInput
          label={t.freemium_modal.company_name}
          id="company_name"
          schema={schema.company_name}
        />
      </FormGroup>

      <FormGroup>
        <TextInput
          label={t.freemium_modal.phone_number}
          id="phone_number"
          schema={schema.phone_number}
          inputType="tel"
        />
      </FormGroup>

      <FormGroup>
        <TextInput
          label={t.freemium_modal.password}
          id="password"
          schema={schema.password}
          inputType="password"
        />
      </FormGroup>

      <FormGroup>
        <SliderInput
          label={t.freemium_modal.expected_vehicles_count}
          id="expected_vehicles_count"
          schema={schema.expected_vehicles_count}
          inputType="expected_vehicles_count"
          min={1}
          max={100}
        />
      </FormGroup>

      <div className="flex">
        <Button
          styleType="positive"
          buttonType="submit"
          className="px-10 btn--lg mr-4"
          disabled={freemiumLoadingState === loadingStates.LOADING}
        >
          {t.freemium_modal.create_account_button}
        </Button>

        <button
          className="btn btn--empty ml-auto"
          type="button"
          onClick={onBack}
        >
          {t.freemium_modal.back}
        </button>
      </div>
    </Form>
  );
}

FreemiumModalForm.defaultProps = {
  visible: true,
};

FreemiumModalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

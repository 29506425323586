import { useRecoilState } from 'recoil';
import { registerEndModalOpenAtom } from '../atoms/registerEndModal';

export default function useRegisterEndModalActions() {
  const [
    registerEndModalOpen,
    setRegisterEndModalOpen,
  ] = useRecoilState(registerEndModalOpenAtom);

  function toggleModal(forceState = null) {
    setRegisterEndModalOpen(forceState !== null ? forceState : !registerEndModalOpen);
  }

  return {
    toggleModal,
  };
}

import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import Addon from './Addon';
import { subscriptionsAtom } from '../../store/atoms/subscription';
import { colorMap, iconMap } from './maps';

export default function Addons({ onOrderAddonClick }) {
  const subscriptions = useRecoilValue(subscriptionsAtom);

  const addonsContent = useMemo(() => {
    if (!subscriptions || !subscriptions.addons) {
      return null;
    }

    const { addons } = subscriptions;

    const formattedAddons = addons.map((addon) => {
      return {
        ...addon,
        ...colorMap[addon.color],
        icon: iconMap[addon.icon],
        identifier: addon.price_id,
        price_id: `${addon.price_id}-EUR-Monthly`,
      };
    });

    return formattedAddons.map((addon) => {
      return (
        <Addon
          key={addon.identifier}
          title={addon.title}
          description={addon.description}
          className={addon.className}
          price={addon.price}
          icon={addon.icon}
          identifier={addon.identifier}
          priceId={addon.price_id}
          onOrderAddonClick={onOrderAddonClick}
        />
      );
    });
  }, [onOrderAddonClick, subscriptions]);

  return (
    <div className="grid subscription-modal-line gap-4 mb-6 md:mb-8">
      {addonsContent}
    </div>
  );
}

Addons.defaultProps = {
  //
};

Addons.propTypes = {
  onOrderAddonClick: PropTypes.func.isRequired,
};

const defaultConfig = {
  anonymizeIp: false,
  allowGoogleSignals: true,
  allowAdPersonalizationSignals: true,
};

const loadedInstances = {};

const camelToSnakeCase = (key) => key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const identifyVisitor = (
  id,
  traits = {},
  config = {},
) => {
  const { trackingId } = config;
  if (!window.gtag || !trackingId) return;

  if (id) {
    window.gtag('set', { user_id: id });
  }

  if (Object.keys(traits).length) {
    window.gtag('set', traits);
  }
};

const scriptLoaded = (scriptSrc) => {
  const scripts = document.querySelectorAll('script[src]');
  const regex = new RegExp(`^${scriptSrc}`);

  return Boolean(Object.values(scripts).filter(
    (value) => regex.test(value.src),
  ).length);
};

const gtagLoaded = (scriptSrc) => {
  return scriptLoaded(scriptSrc);
};

const injectScript = (scriptSrc) => {
  const script = document.createElement('script');
  script.async = true;
  script.src = scriptSrc;
  document.body.appendChild(script);
  return script;
};

const setUpWindowGtag = () => {
  window.dataLayer = window.dataLayer || [];

  function gtagHelper() {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  }

  gtagHelper('js', new Date());

  window.gtag = gtagHelper;
  return gtagHelper;
};

const trackEvent = (eventData, config = {}) => {
  if (!window.gtag || !config.trackingId) {
    return null;
  }

  const data = {
    event_label: eventData.label,
    event_category: eventData.category || 'All',
    non_interaction: (eventData.nonInteraction !== undefined)
      ? Boolean(eventData.nonInteraction)
      : false,
  };

  if (eventData.value) {
    /* set value of the action */
    data.value = eventData.value >= 0 ? eventData.value : 0;
  }

  window.gtag('event', eventData.event, { ...data });

  return data;
};

const googleGtagAnalytics = (pluginConfig = {}) => {
  // Allow for multiple google analytics instances
  const instanceName = pluginConfig.instanceName ? pluginConfig.instanceName : '';

  const { trackingId } = pluginConfig;

  return {
    name: 'google-gtag-analytics',
    config: {
      ...defaultConfig,
      ...pluginConfig,
    },

    // Load gtag.js and define gtag
    // Set custom dimensions (ua properties) and parameters (ga4 properties)
    initialize: (pluginApi) => {
      const { config, instance } = pluginApi;
      if (!trackingId) throw new Error('No GA trackingId defined');

      const gtagScriptSource = 'https://www.googletagmanager.com/gtag/js';
      const scriptSrc = config.customScriptSrc || `${gtagScriptSource}?id=${trackingId}`;

      if (!gtagLoaded(config.customScriptSrc || gtagScriptSource)) {
        injectScript(scriptSrc);
      }

      if (!window.gtag) {
        setUpWindowGtag();
      }

      const newCookieConfig = {};

      if (config.cookieConfig !== undefined) {
        Object.entries(config.cookieConfig).forEach(([key, value]) => {
          newCookieConfig[camelToSnakeCase(key)] = value;
        });
      }

      // Initialize tracker instance on page
      if (!loadedInstances[instanceName]) {
        const gtagConfig = {
          cookie_domain: config.domain || 'auto',
          send_page_view: false,
          allow_google_signals: config.allowGoogleSignals,
          allow_ad_personalization_signals: config.allowAdPersonalizationSignals,
          anonymize_ip: config.anonymizeIp,
          ...newCookieConfig,
        };

        if (config.linker) {
          gtagConfig.linker = config.linker;
        }

        /* set custom dimensions from user traits */
        const user = instance.user() || {};
        const traits = user.traits || {};

        if (Object.keys(traits).length) {
          window.gtag('set', traits);
        }

        window.gtag('config', trackingId, gtagConfig);

        loadedInstances[instanceName] = true;
      }
    },

    // Set parameter scope at user level with 'set' method
    identify: (props) => {
      const { payload, config } = props;
      identifyVisitor(payload.userId, payload.traits, config);
    },

    // Set parameter scope at page level with 'config' method
    page: ({ payload, config }) => {
      if (!window.gtag || !config.trackingId) {
        return;
      }

      const { properties } = payload;
      let path = properties.path || document.location.pathname;

      if (typeof URL !== 'undefined') {
        const url = new URL(properties.url);
        path = url.pathname + url.search;
      }

      window.gtag('event', 'page_view', {
        page_title: properties.title,
        page_location: properties.url,
        page_path: path,
      });
    },

    loaded: () => {
      return Boolean(window.gtag);
    },

    // Set parameter scope at event level with 'event' method
    track: ({ payload, config, instance }) => {
      const { properties, event } = payload;
      const {
        label,
        value,
        category,
        nonInteraction,
      } = properties;
      const campaign = instance.getState('context.campaign');

      trackEvent({
        event,
        label,
        category: category || 'All',
        value,
        nonInteraction,
        campaign,
      }, config, payload);
    },
  };
};

export {};

export default googleGtagAnalytics;

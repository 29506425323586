import axios from 'axios';

export default async function issueCsrf() {
  const url = [
    process.env.REACT_APP_API_URL,
    'sanctum/csrf-cookie',
  ].join('/');

  axios.get(url);
}

/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';
import ConnectForm from './ConnectForm';

export default function RadioInput({
  label,
  description,
  id,
  schema,
  options,
  onChange,
}) {
  const methods = useFormContext();

  const isRequired = schema.exclusiveTests?.required || false;

  const requiredLabel = isRequired
    ? <abbr className="text-red-600 ml-1" title="Required">*</abbr>
    : null;

  const inputElements = Object.keys(options)
    .map((optionKey) => {
      const optionLabel = options[optionKey];

      const labelElement = (
        <div className="font-bold">{optionLabel}</div>
      );

      const inputId = `${id}-${optionKey}`;

      return (
        <label
          htmlFor={inputId}
          className={`flex mr-3 md:mr-5 mb-3 ${!description ? 'items-center' : ''}`}
          key={optionKey}
        >
          <input
            type="radio"
            className="mr-2 form-radio"
            name={id}
            id={inputId}
            value={optionKey}
            {...methods.register(id, {
              onChange,
            })}
          />

          <div className="">
            {labelElement}
          </div>
        </label>
      );
    });

  const globalLabelElement = (
    <span className="font-bold">{label}</span>
  );

  return (
    <ConnectForm>
      {({ error }) => (
        <>
          <div className="mb-2">
            {globalLabelElement}
            {requiredLabel}
          </div>
          <div className="flex flex-wrap -mb-3">
            {inputElements}
          </div>
          <ErrorMessage message={error} />
        </>
      )}
    </ConnectForm>
  );
}

RadioInput.defaultProps = {
  schema: {},
  description: null,
  options: {},
  onChange: () => {},
};

RadioInput.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object,
  options: PropTypes.objectOf(PropTypes.string),
  onChange: PropTypes.func,
};

import { useRecoilValue } from 'recoil';
import * as yup from 'yup';
import weakPasswords from '../config/weakPasswords';
import { translationsAtom } from '../store/atoms/i18n';

export default function useFreemiumRegisterSchema() {
  const t = useRecoilValue(translationsAtom);

  const schema = {
    company_name: yup
      .string()
      .required()
      .min(2)
      .max(200),
    phone_number: yup
      .string()
      .required()
      .min(2)
      .max(200),
    password: yup
      .string()
      .required()
      .min(6)
      .max(200)
      .test('passwords-weak', t.validation.custom.password_weak, (value) => {
        return weakPasswords.indexOf(value) < 0;
      }),
    expected_vehicles_count: yup
      .number()
      .required()
      .min(1)
      .max(100000),
  };

  const cyclicDependencies = [
    //
  ];

  return [
    schema,
    cyclicDependencies,
  ];
}

import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Main from '../../components/Main';
import MetaHead from '../../components/MetaHead';
import { translationsAtom } from '../../store/atoms/i18n';
import Spinner from '../../components/Spinner';

export default function SubscriptionUpdate() {
  const t = useRecoilValue(translationsAtom);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get('process')) {
      window.location = '/';
    }

    const time = parseInt(searchParams.get('process'), 10);

    if (Number.isNaN(time)) {
      window.location = '/p/subscription-confirmed';
    }

    setTimeout(() => {
      window.location = '/p/subscription-confirmed';
    }, (time * 1000));
  }, [searchParams]);

  return (
    <>
      <MetaHead title={t.subscription_update.head_title_loading} />

      <Main>
        <div className="w-full flex items-center justify-center mt-16">
          <div className="bg-white p-4 md:p-8 rounded-xl shadow-md w-full max-w-xl">
            <h1 className="text-3xl text-left font-bold">
              {t.subscription_update.loading}
            </h1>
            <div className="w-full flex justify-center mt-5">
              <Spinner className="h-16 w-16 my-5" />
            </div>
          </div>
        </div>
      </Main>
    </>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import Form from '../../components/Form';
import FormGroup from '../../components/FormFields/FormGroup';
import Button from '../../components/Button';
import TextInput from '../../components/FormFields/TextInput';
import { translationsAtom } from '../../store/atoms/i18n';
import useFreemiumRegisterEmailSchema from '../../schemas/freemiumRegisterEmailSchema';

export default function FreemiumModalEmailForm({ onSubmit, visible }) {
  const t = useRecoilValue(translationsAtom);
  const [schema, cyclicDependencies] = useFreemiumRegisterEmailSchema();

  return (
    <Form
      className={`${visible ? '' : 'hidden'}`}
      schemaObject={schema}
      schemaCyclicDependencies={cyclicDependencies}
      onSubmit={onSubmit}
    >
      <FormGroup>
        <TextInput
          label={t.freemium_modal.email}
          id="email"
          schema={schema.email}
          inputType="email"
        />
      </FormGroup>

      <FormGroup>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t.register.agreement
              .replace('%s', `<a class="underline hover:text-primary" href="https://www.routelogic.nl/gebruiksvoorwaarden-routelogic" target="_blank">${t.register.agreement_link_text}</a>`)
              .replace('%s', `<a class="underline hover:text-primary" href="https://www.routelogic.nl/privacy-verklaring" target="_blank">${t.register.privacy_link_text}</a>`),
          }}
        />
      </FormGroup>

      <Button
        styleType="positive"
        buttonType="submit"
        className="px-10 btn--lg"
      >
        {t.freemium_modal.continue}
      </Button>
    </Form>
  );
}

FreemiumModalEmailForm.defaultProps = {
  visible: true,
};

FreemiumModalEmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

import * as yup from 'yup';

export default function useTopUpSchema() {
  const schema = {
    amount: yup
      .number()
      .required()
      .min(25)
      .max(100000),
  };

  const cyclicDependencies = [
    //
  ];

  return [
    schema,
    cyclicDependencies,
  ];
}

import React from 'react';
import { useRecoilValue } from 'recoil';
import SvgIcon from '../../components/SvgIcon';
import { translationsAtom } from '../../store/atoms/i18n';
import logo from '../../assets/brand/logo.svg';
import capterraLogo from '../../assets/images/capterra-logo.svg';
import RegisterReviews from './RegisterReviews';
import larawardsLogo from '../../assets/images/larawards.svg';

export default function RegisterSidebar() {
  const t = useRecoilValue(translationsAtom);

  const ctas = [
    t.register.registration_cta_1,
    t.register.registration_cta_2,
    t.register.registration_cta_3,
    t.register.registration_cta_4,
  ].map((translation) => {
    return (
      <li className="mb-4 flex" key={translation}>
        <SvgIcon
          identifier="check"
          className="w-4 h-4 mt-2 text-green-500 flex-shrink-0 mr-3"
        />
        {translation}
      </li>
    );
  });

  return (
    <>
      <img
        src={logo}
        alt="RouteLogic logo"
        width="220"
        height="54"
        className="max-w-full block h-auto mb-6 md:mb-10"
      />

      <h1 className="text-2xl md:text-4xl font-bold mb-6 md:mb-10">
        {t.register.registration_title}
      </h1>

      <div
        className="mb-6 md:mb-10 md:text-lg leading-loose"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t.register.registration_description,
        }}
      />

      <h3 className="font-bold text-lg md:text-xl mb-4">
        {t.register.registration_cta_title}
      </h3>

      <ul className="md:text-lg mb-6 md:mb-10">
        {ctas}
      </ul>

      <div className="hidden lg:block">
        <RegisterReviews />

        <div className="mt-2 md:mt-6 flex justify-between gap-10 ml-auto">
          <img
            className="max-w-[14rem]"
            src={capterraLogo}
            alt=""
            width="323"
            height="154"
            loading="lazy"
          />

          <img
            className="mt-4"
            src={larawardsLogo}
            alt="Larawards"
            width="114"
            height="96"
            loading="lazy"
            decoding="async"
          />
        </div>
      </div>
    </>
  );
}

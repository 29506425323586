import React, { useState } from 'react';
import { useDebouncedEffect } from '@react-hookz/web';
import { useRecoilValue } from 'recoil';
import { getUnixTime, parseISO } from 'date-fns';
import { useLocation, useSearchParams } from 'react-router-dom';
import { userAtom } from '../../store/atoms/auth';
import { activeSubscriptionSelector, subscriptionPlanSelector } from '../../store/selectors/auth';
import useWindowSize from '../../hooks/useWindowSize';
import SvgIcon from '../SvgIcon';

const baseIntercomSettings = {
  api_base: 'https://api-iam.intercom.io',
  app_id: 'vnfjbjyu',
};

export default function Intercom() {
  const user = useRecoilValue(userAtom);
  const subscriptionPlan = useRecoilValue(subscriptionPlanSelector);
  const activeSubscription = useRecoilValue(activeSubscriptionSelector);
  const { width } = useWindowSize();
  const [searchParams] = useSearchParams();
  const [intercomInitiated, setIntercomInitiated] = useState(false);
  const [shouldShowLauncher, setShouldShowLauncher] = useState(true);

  const handleCloseIntercom = () => {
    window.Intercom('shutdown');
    setIntercomInitiated(false);
  };

  const location = useLocation();

  // Hide the launcher on the schedule detail page
  const isOnScheduleDetailPage = location.pathname.startsWith('/schedules/');
  const isOnRegister = location.pathname.startsWith('/register/');
  const isOnFreemiumWelcomePage = searchParams.get('freemium');

  useDebouncedEffect(() => {
    if (!width) {
      return;
    }

    // If Intercom was initiated before, shut it down
    if (intercomInitiated && typeof window.Intercom !== 'undefined') {
      setIntercomInitiated(false);
      window.Intercom('shutdown');
    }

    // Check if we should show the launcher icon
    const extendedIntercomLauncher = !!user && width > 480;

    const showLauncher = extendedIntercomLauncher
      && !isOnScheduleDetailPage
      && !isOnFreemiumWelcomePage
      && !isOnRegister;

    setShouldShowLauncher(showLauncher);

    let intercomSettings = { ...baseIntercomSettings };

    if (user) {
      intercomSettings = {
        ...intercomSettings,
        name: user.name,
        email: user.email,
        user_hash: user.email_hashed,
        company: {
          id: user.company.id,
          name: user.company.name,
          plan: activeSubscription && subscriptionPlan ? subscriptionPlan.name : 'Free',
        },
        created_at: getUnixTime(parseISO(user.created_at)),
      };
    }

    // If the launcher should be hidden, add to the configuration.
    if (!showLauncher) {
      intercomSettings = {
        ...intercomSettings,
        hide_default_launcher: true,
      };
    }

    // (Re-)boot Intercom with the settings
    if (typeof window.Intercom === 'function' && showLauncher) {
      window.Intercom('boot', intercomSettings);
      setIntercomInitiated(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, user, isOnScheduleDetailPage, isOnFreemiumWelcomePage, isOnRegister], 400);

  return (
    intercomInitiated && shouldShowLauncher && (
      <div className="relative z-[2147483600]">
        <button
          type="button"
          onClick={handleCloseIntercom}
          className="fixed bottom-18 right-18 bg-red-600 text-white rounded-full px-2 py-1.5 cursor-pointer"
        >
          <SvgIcon
            identifier="cross"
            className="w-3"
          />
        </button>
      </div>
    )
  );
}

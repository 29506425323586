import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import './Main.css';
import NotificationBar from '../NotificationBar';
import Navigation from '../Navigation';
import { userAtom } from '../../store/atoms/auth';

export default function Main({
  children,
  className,
  innerClassName,
  gapLess,
  fullHeight,
  hideNavigation,
}) {
  const user = useRecoilValue(userAtom);

  let mainClass = `${className} main flex-grow focus:border-0 focus:outline-none`;
  let notificationBarClass = 'mb-6 md:mb-10 px-6 py-4 md:px-10 md:py-6';

  if (!gapLess) {
    mainClass += ' p-6 md:p-10';
    notificationBarClass += ' -mt-6 -mx-6 md:-mt-10 md:-mx-10';
  }

  if (gapLess) {
    notificationBarClass = notificationBarClass
      .replace('mb-6', '')
      .replace('md:mb-10', '');
  }

  if (fullHeight) {
    mainClass += ' h-screen flex flex-col';
  }

  const navigation = useMemo(() => {
    return user && !hideNavigation ? <Navigation /> : null;
  }, [user, hideNavigation]);

  return (
    <>
      {navigation}

      <main
        id="main"
        className={mainClass}
        tabIndex="-1"
      >
        <NotificationBar
          className={notificationBarClass}
        />

        {
          fullHeight
            ? (
              <div className={`flex flex-grow min-h-0 ${innerClassName}`}>
                {children}
              </div>
            )
            : children
        }
      </main>
    </>
  );
}

Main.defaultProps = {
  className: '',
  innerClassName: '',
  gapLess: false,
  fullHeight: false,
  hideNavigation: false,
};

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  gapLess: PropTypes.bool,
  fullHeight: PropTypes.bool,
  hideNavigation: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { translationsAtom } from '../../store/atoms/i18n';

export default function TrialExpired({
  toggleSubscriptionModal,
}) {
  const t = useRecoilValue(translationsAtom);

  return (
    <div>
      {t.notification_bar.trial_expired}

      <button
        className="ml-2 underline underline-offset-2"
        type="button"
        onClick={toggleSubscriptionModal}
      >
        {t.notification_bar.upgrade_account}
      </button>
    </div>
  );
}

TrialExpired.defaultProps = {
  //
};

TrialExpired.propTypes = {
  toggleSubscriptionModal: PropTypes.func.isRequired,
};

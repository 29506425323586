import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import DebugObserver from './store/middleware/DebugObserver';
import analytics from './helpers/analytics';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_TOKEN,
  integrations: [
    new Integrations.BrowserTracing(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: process.env.NODE_ENV === 'development'
    ? 0.5
    : 0.05,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const debugObserverElement = process.env.NODE_ENV === 'development'
  ? <DebugObserver />
  : null;

axios.defaults.withCredentials = true;

render(
  <React.StrictMode>
    <RecoilRoot>
      <HelmetProvider>
        {debugObserverElement}
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals(({ id, name, value }) => {
  analytics.track(name, {
    category: 'Web Vitals',
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate
  }, {
    plugins: {
      // Disable all plugins
      all: false,
      // Enable Google Analytics for this event.
      'google-analytics': true,
    },
  });
});

import { useFormContext, useFormState } from 'react-hook-form';
import PropTypes from 'prop-types';
import { get } from 'lodash';

export default function ConnectForm({ children, name }) {
  const methods = useFormContext();
  const { errors } = useFormState({ name });
  let error = null;

  if (name) {
    const errorObject = get(errors, name);

    if (errorObject) {
      error = errorObject?.message || errorObject[Object.keys(errorObject)[0]]?.message || 'Error';
    }
  }

  return children({ ...methods, error });
}

ConnectForm.defaultProps = {
  name: null,
};

ConnectForm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  name: PropTypes.string,
};

import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { translationsAtom } from '../../store/atoms/i18n';
import {
  activeSubscriptionSelector,
  companySelector,
  subscriptionPlanForIdentifierActiveSelector,
  subscriptionPlanSelector,
} from '../../store/selectors/auth';
import SvgIcon from '../SvgIcon';
import { iconMap } from './maps';

export default function PlanBlock({
  plan,
  identifier,
  iconActive,
  iconInactive,
  showPricesUnit,
  features,
}) {
  const t = useRecoilValue(translationsAtom);
  const activeSubscriptionPlan = useRecoilValue(subscriptionPlanSelector);
  const activeSubscription = useRecoilValue(activeSubscriptionSelector);
  const company = useRecoilValue(companySelector);

  const isActive = useRecoilValue(subscriptionPlanForIdentifierActiveSelector(identifier));

  const [vehiclesInCart, setVehiclesInCart] = useState(company.paid_vehicle_count || 1);

  const isInTrial = activeSubscription?.status === 'in_trial';

  const styleType = isActive ? 'default' : 'primary';
  const icon = !isActive ? iconActive : iconInactive;

  const isCustom = identifier === 'custom';
  const isFree = identifier === 'free';
  const isPro = identifier === 'pro';
  const isProPlus = identifier === 'pro-plus';
  const isPaid = isPro || identifier === 'pro-plus';

  const onVehicleCountUpdate = useCallback((amount) => {
    setVehiclesInCart((previousState) => {
      const result = previousState + amount;

      if ((result) < 1) {
        return previousState;
      }

      return result;
    });
  }, [setVehiclesInCart]);

  const iconElement = icon
    ? <img className="block mx-auto mb-4" src={`/images/${icon}`} alt="" />
    : null;

  const price = showPricesUnit === 'yearly'
    ? plan.price_yearly
    : plan.price_monthly;

  const priceSubtitle = showPricesUnit === 'yearly'
    ? plan.price_yearly_subtitle
    : plan.price_monthly_subtitle;

  const paymentProviderId = showPricesUnit === 'yearly'
    ? plan.price_yearly_id
    : plan.price_monthly_id;

  const button = useMemo(() => {
    let buttonText = t.subscription_modal.start_trial;
    let addAmountField = false;
    let buttonClass = `mt-4 btn px-10 ${styleType === 'primary' ? 'btn--positive' : 'btn--empty'}`;

    if (isFree) {
      return (<div />);
    }

    if (isActive && !isInTrial) {
      buttonText = isPaid ? t.subscription_modal.update : t.subscription_modal.current_package;
    } else if (isInTrial) {
      buttonText = t.subscription_modal.upgrade;
    } else if (isCustom) {
      buttonText = t.subscription_modal.get_in_touch;
    } else if (activeSubscriptionPlan) {
      buttonText = t.subscription_modal.upgrade;
    } else if (company && company.has_had_subscription) {
      buttonText = t.subscription_modal.upgrade;
      addAmountField = true;
    }

    if (!isInTrial && isPro && activeSubscriptionPlan && activeSubscriptionPlan.identifier === 'pro-plus') {
      buttonText = t.subscription_modal.downgrade;
      buttonClass = 'mt-4 btn px-10 btn--negative';
    }

    // If this subscription is active.
    // Or this is the free block and the user has no active subscription.
    // In that case, the user is in essence on the free plan.
    if (isActive && !activeSubscription && isFree) {
      return (
        <button
          type="button"
          className="mt-4 btn px-10 btn--empty cursor-not-allowed"
          disabled
        >
          {buttonText}
        </button>
      );
    }

    if (isInTrial && isActive) {
      buttonClass = 'mt-6 md:mt-8 btn px-10 btn--positive';
    }

    if (isCustom) {
      const mailLineBreak = '%0D%0A';
      const mailBody = `
Ik zou graag meer informatie willen ontvangen over de mogelijkheden van enterprise voor onze organisatie.${mailLineBreak}${mailLineBreak}
[Omschrijf hier het aantal verwachte voertuigen en opdrachten]
      `.trim();
      const mailLink = `mailto:info@routelogic.nl?subject=Enterprise pakket&body=${mailBody}`;

      return (
        <a
          className={buttonClass}
          href={mailLink}
          target="_blank"
          rel="noreferrer"
        >
          {buttonText}
        </a>
      );
    }

    // It's possible that a subscription is in trial, but has no attachment to
    // the payment provider. The consequence is that we cannot redirect the
    // user to the payment provider to manage their subscription information.
    const hasPaymentProvider = !!activeSubscription?.chargebee_id;

    let buttonLink = typeof paymentProviderId !== 'undefined'
      ? `/internal-subscription/${paymentProviderId}${addAmountField ? `/${vehiclesInCart}` : ''}`
      : '/contact';

    // When the company already has subscriptions, we have to
    // move the user to the subscriptions overview page in the provider.
    if (company && company.has_had_subscription && hasPaymentProvider && isActive) {
      buttonLink = '/internal-subscription';
    }

    if (isActive && isInTrial) {
      return (
        <>
          <NavLink to={buttonLink} className={buttonClass}>
            {buttonText}
          </NavLink>
          <div className="mt-2">
            {t.subscription_modal.current_package}
          </div>
        </>
      );
    }

    return (
      <div className="flex flex-col flex-nowrap items-center">
        {addAmountField && (
          <div className="flex items-center">
            <button type="button" className="p-3 border rounded hover:bg-gray-100" onClick={() => { onVehicleCountUpdate(-1); }}>
              <SvgIcon identifier="min" />
            </button>

            <p className="mx-2 p-3 w-12 h-12 flex justify-center items-center border rounded">
              { vehiclesInCart }
            </p>

            <button type="button" className={`p-3 ${vehiclesInCart === 0 ? 'bg-green-500 text-white' : ''} border rounded hover:bg-gray-100 flex justify-center items-center`} onClick={() => { onVehicleCountUpdate(1); }}>
              <SvgIcon identifier="plus" />

              {vehiclesInCart === 0 && (
                <span className="ml-2">{t.vehicles.pre_upgrade}</span>
              )}
            </button>
          </div>
        )}

        <NavLink to={buttonLink} className={buttonClass}>
          {buttonText}
        </NavLink>
      </div>
    );
  }, [
    activeSubscriptionPlan,
    activeSubscription,
    paymentProviderId,
    isActive,
    isInTrial,
    isFree,
    isPro,
    isPaid,
    isCustom,
    styleType,
    company,
    vehiclesInCart,
    onVehicleCountUpdate,
    t,
  ]);

  const planTrialOption = useMemo(() => {
    // Free and custom have no trial option
    if (isFree || isCustom) {
      return null;
    }

    // If the company already has a subscription in the past,
    // the user cannot use another trial period.
    if (company.has_had_subscription) {
      return null;
    }

    return (
      <div className="text-sm mt-2">
        {t.subscription_modal.first_month_free}
      </div>
    );
  }, [t, isFree, isCustom, company]);

  const planFeatures = useMemo(() => {
    return features.map((feature, index) => {
      const key = `${feature.title}-${index}`;
      const featureIcon = iconMap[feature.icon];

      if (index === 0 && isPro) {
        return null;
      }

      return (
        <li key={key} className="flex my-1">
          <SvgIcon className="self-center mr-2 shrink-0" identifier={featureIcon} />

          <span className="text-left">
            {feature.title}
          </span>
        </li>
      );
    });
  }, [features, isPro]);

  const banner = useMemo(() => {
    if (isActive && !isInTrial) {
      return (
        <div className="bg-sky-400 border-primary text-white -mx-4 -mt-4 rounded-t-xl mb-4 py-2">
          {t.subscription_modal.current_subscription}
        </div>
      );
    }

    if (isProPlus) {
      return (
        <div className="bg-primary border-primary text-white -mx-4 -mt-4 rounded-t-xl mb-4 py-2">
          {t.subscription_modal.best_value}
        </div>
      );
    }

    return null;
  }, [isActive, isProPlus, t, isInTrial]);

  return (
    <div
      className={`
        p-4 text-center rounded-xl flex justify-between flex-col bg-white border
        ${banner ? '' : 'mt-10'}
      `}
    >
      <div>
        {banner}

        <div className="text-lg mb-4">
          {plan.title}
        </div>

        <div>
          {iconElement}
        </div>

        <div className="mt-4 mb-12">
          <div className="mx-16">
            <h3 className="text-lg font-bold mb-2 text-left">
              {t.subscription_modal.features}
            </h3>

            <ul>
              {planFeatures}

              <li>
                <a href="https://routelogic.nl/prijs" className="flex text-primary underline" target="_blank" rel="noreferrer">
                  <SvgIcon className="self-center mr-2" identifier="plus" />

                  {t.subscription_modal.more_features}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <div className="h-20 flex flex-col justify-center mt-4">
          <div className="text-xl lg:text-2xl mb-1 font-bold">
            {price}
          </div>

          <div>
            {priceSubtitle}
          </div>

          {planTrialOption}
        </div>

        {button}
      </div>
    </div>
  );
}

PlanBlock.defaultProps = {
  iconActive: null,
  iconInactive: null,
};

PlanBlock.propTypes = {
  plan: PropTypes.shape({
    title: PropTypes.string,
    price_monthly: PropTypes.string,
    price_monthly_subtitle: PropTypes.string,
    price_monthly_id: PropTypes.string,
    price_yearly: PropTypes.string,
    price_yearly_subtitle: PropTypes.string,
    price_yearly_id: PropTypes.string,
  }).isRequired,
  iconActive: PropTypes.string,
  iconInactive: PropTypes.string,
  identifier: PropTypes.string.isRequired,
  showPricesUnit: PropTypes.oneOf(['yearly', 'monthly']).isRequired,
  features: PropTypes.arrayOf(PropTypes.object).isRequired,
};

import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Menu } from '@headlessui/react';
import { NavLink, useLocation } from 'react-router-dom';
import { navigationExpandedStateAtom } from '../../store/atoms/navigation';
import './Navigation.css';
import NavigationItem from '../NavigationItem';
import { translationsAtom } from '../../store/atoms/i18n';
import useNavigationActions from '../../store/actions/navigation';
import NavigationUser from './NavigationUser';
import logoImage from '../../assets/brand/logo.svg';
import useWindowSize from '../../hooks/useWindowSize';
import SubscriptionInfo from '../SubscriptionInfo';
import { hasNewUpdatesAtom } from '../../store/atoms/updates';
import { userHasFeatureSelector } from '../../store/selectors/auth';
import useSubscriptionModalActions from '../../store/actions/subscriptionModal';
import useScheduleVisitNotesActions from '../../store/actions/scheduleVisitNotes';
import { scheduleVisitNotesUnreadCountAtom } from '../../store/atoms/scheduleVisitNotes';

const NAVIGATION_BREAKPOINT = 768;

export default function Navigation() {
  const { width: windowWidth } = useWindowSize();
  const navigationActions = useNavigationActions();
  const navigationExpanded = useRecoilValue(navigationExpandedStateAtom);
  const [
    navigationExpandedChangedBasedOnWindowWidth,
    setNavigationExpandedChangedBasedOnWindowWidth,
  ] = useState(false);
  const t = useRecoilValue(translationsAtom);
  const location = useLocation();
  const [hasNewUpdates, setHasNewUpdates] = useRecoilState(hasNewUpdatesAtom);
  const hasDriverAppFeature = useRecoilValue(userHasFeatureSelector('DRIVER_APP'));
  const hasStatsFeature = useRecoilValue(userHasFeatureSelector('STATS'));
  const hasNotificationsFeature = useRecoilValue(userHasFeatureSelector('NOTIFICATIONS'));
  const hasInboxFeature = useRecoilValue(userHasFeatureSelector('INBOX'));
  const unreadVisitNotesCount = useRecoilValue(scheduleVisitNotesUnreadCountAtom).count;

  let formattedUnreadNotesCount = unreadVisitNotesCount;

  if (unreadVisitNotesCount > 99) {
    formattedUnreadNotesCount = '99+';
  }

  const subscriptionModalActions = useSubscriptionModalActions();
  const scheduleVisitNotesActions = useScheduleVisitNotesActions();

  const onSubscriptionModalOpen = useCallback(() => {
    subscriptionModalActions.toggleModal(true);
  }, [subscriptionModalActions]);

  const handleProFeatureClick = useCallback((event) => {
    event.preventDefault();
    onSubscriptionModalOpen();
  }, [onSubscriptionModalOpen]);

  const navigationItems = [
    {
      id: 'Dashboard',
      title: t.navigation.dashboard,
      icon: 'grid',
      url: '/',
    },
    {
      id: 'Stats',
      title: t.navigation.stats,
      icon: 'building',
      url: '/stats',
      badge: !hasStatsFeature,
      badgeText: !hasStatsFeature ? 'PRO PLUS' : null,
      onClick: !hasStatsFeature ? handleProFeatureClick : null,
    },
    {
      id: 'Schedules',
      title: t.navigation.schedules,
      icon: 'route',
      url: '/schedules',
    },
    {
      id: 'Opdrachten',
      title: t.navigation.tasks,
      icon: 'box',
      url: '/tasks',
    },
    {
      id: 'Voertuigen',
      title: t.navigation.vehicles,
      icon: 'vehicle',
      url: '/vehicles',
    },
    {
      id: 'Notificaties',
      title: t.navigation.notifications,
      icon: 'mail',
      url: '/notification-templates',
      badge: !hasNotificationsFeature,
      badgeText: !hasNotificationsFeature ? 'PRO' : null,
      onClick: !hasNotificationsFeature ? handleProFeatureClick : null,
    },
    {
      id: 'Chauffeursapp',
      title: t.navigation.driver_app,
      icon: 'app',
      url: '/driver-app',
      badge: !hasDriverAppFeature,
      badgeText: !hasDriverAppFeature ? 'PRO' : null,
      onClick: !hasDriverAppFeature ? handleProFeatureClick : null,
    },
    {
      id: 'messages',
      title: t.navigation.inbox,
      icon: 'noteNoOffset',
      url: '/inbox',
      smallBadge: !hasInboxFeature ? false : unreadVisitNotesCount > 0,
      badge: !hasInboxFeature ? true : unreadVisitNotesCount > 0,
      badgeText: !hasInboxFeature ? 'PRO PLUS' : formattedUnreadNotesCount.toString(),
      badgeColor: !hasInboxFeature ? null : 'red',
      onClick: !hasInboxFeature ? handleProFeatureClick : null,
    },
    {
      id: 'divider',
      divider: true,
    },
    {
      id: 'Support',
      title: t.navigation.support,
      icon: 'buoy',
      url: '/support',
    },
    {
      id: 'Updates',
      title: t.navigation.updates,
      icon: 'stars',
      url: 'https://www.routelogic.io/updates',
      smallBadge: hasNewUpdates,
      onClick: () => {
        setHasNewUpdates(false);
      },
    },
  ];

  useEffect(() => {
    scheduleVisitNotesActions.getUnreadNotesCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (windowWidth !== undefined && !navigationExpandedChangedBasedOnWindowWidth) {
      if (windowWidth < NAVIGATION_BREAKPOINT) {
        navigationActions.toggleNavigationExpandedState(false);
      }

      setNavigationExpandedChangedBasedOnWindowWidth(true);
    }
  }, [windowWidth, navigationActions, navigationExpandedChangedBasedOnWindowWidth]);

  useEffect(() => {
    if (navigationExpanded && windowWidth < NAVIGATION_BREAKPOINT) {
      navigationActions.toggleNavigationExpandedState(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const toggleExpanded = () => {
    navigationActions.toggleNavigationExpandedState();
  };

  const navigationItemsRendered = navigationItems.map((item) => {
    if (item.divider) {
      return (<hr key={item.id} className="my-3" />);
    }

    return (
      <NavigationItem
        key={item.id}
        navigationItem={item}
        showText={navigationExpanded}
        showProBadge={item.showProBadge}
      />
    );
  });

  return (
    <nav
      className={`
        navigation bg-white md:min-h-full flex flex-col flex-shrink-0 print:hidden
        md:fixed md:py-4 md:px-1 md:z-20 group ${navigationExpanded ? 'is-expanded' : ''}
      `}
    >
      <div className="flex items-center md:mb-4">
        <button
          className="py-4 px-7 md:px-4 self-start navigation__toggle flex justify-center items-center"
          type="button"
          onClick={toggleExpanded}
          aria-expanded={navigationExpanded}
          aria-controls="navigation"
        >
          <span className="inline-block w-100 bg-gray-700 text-gray-700 relative" />
          <span className="sr-only">{navigationExpanded ? t.navigation.is_expanded : t.navigation.is_not_expanded}</span>
        </button>

        <NavLink to="/" className="ml-1">
          <img
            src={logoImage}
            alt="RouteLogic logo"
            width="300"
            height="73"
            className="block w-40 md:w-44"
          />
        </NavLink>
      </div>

      <div
        id="navigation"
        aria-hidden={!navigationExpanded}
        className={`md:flex md:flex-col flex-grow ${navigationExpanded ? 'p-4 md:py-0 md:px-2' : 'group-hover:p-4 group-hover:md:py-0 group-hover:md:px-2 hidden md:items-center'}`}
      >
        <ul>
          {navigationItemsRendered}
        </ul>

        <div className="relative mt-auto group-hover:w-full">
          <div className={`${navigationExpanded ? '' : 'hidden group-hover:block '} mb-4 md:mb-6`}>
            <SubscriptionInfo />
          </div>

          <Menu>
            {({ open }) => (
              <NavigationUser
                isOpen={open}
                navigationExpanded={navigationExpanded}
              />
            )}
          </Menu>
        </div>
      </div>
    </nav>
  );
}

import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import './NavigationItem.css';
import SvgIcon from '../SvgIcon';
import Badge from '../Badge';

export default function NavigationItem({ navigationItem, showText }) {
  const baseClass = 'flex rounded-md items-center py-4 px-3 relative';
  const activeClass = 'is-active bg-primary text-white';
  const inactiveClass = 'text-gray-900';
  const showTextClass = 'md:px-5 md:py-4';
  const hiddenTextClass = 'md:justify-center md:items-center group-hover:md:justify-start group-hover:md:px-5 group-hover:md:py-4';

  const linkContents = (
    <>
      <span className={`navigation-item__icon flex flex-shrink-0 items-center justify-center mr-4 ${showText ? '' : 'group-hover:md:mr-4 md:mr-0'}`}>
        <SvgIcon
          identifier={navigationItem.icon}
          className={`block flex-shrink-0 w-10 h-10 icon-${navigationItem.icon}`}
        />
      </span>

      <span className={`flex-grow-0 leading-none block ${showText ? '' : 'md:sr-only group-hover:md:not-sr-only'}`}>
        {navigationItem.title}
      </span>

      {navigationItem.badgeText && navigationItem.badge ? (
        <Badge
          className={`
            ml-auto -mr-1
            ${navigationItem.badgeColor === 'green' ? '!bg-green-600 !bg-none' : ''}
            ${navigationItem.badgeColor === 'red' ? '!bg-red-600 !bg-none' : ''}
            ${navigationItem.badgeColor === 'blue' ? '!bg-blue-600 !bg-none' : ''}
            ${showText ? '' : 'group-hover:block hidden'}
          `}
        >
          {navigationItem.badgeText}
        </Badge>
      ) : null}

      {
        navigationItem.smallBadge
          ? (
            <span
              className={`
                w-3 h-3 absolute top-3 ${showText ? 'left-3' : 'group-hover:left-3 left-1'}
                rounded-full shadow-[0_0_0_2px]
                ${!navigationItem.badgeColor ? 'bg-green-600 shadow-green-600/20' : ''}
                ${navigationItem.badgeColor === 'green' ? 'bg-green-600 shadow-green-600/20' : ''}
                ${navigationItem.badgeColor === 'red' ? 'bg-red-600 shadow-red-600/20' : ''}
                ${navigationItem.badgeColor === 'blue' ? 'bg-blue-600 shadow-blue-600/20' : ''}
              `}
            />
          )
          : null
      }
    </>
  );

  return (
    <li className="navigation-item" key={navigationItem.id}>
      {
        navigationItem.url.indexOf('https:') >= 0
          ? (
            <a
              href={navigationItem.url}
              className={`${baseClass} ${showText ? showTextClass : hiddenTextClass}`}
              target="_blank"
              rel="noreferrer"
              onClick={navigationItem.onClick ? navigationItem.onClick : null}
            >
              {linkContents}
            </a>
          )
          : (
            <NavLink
              to={navigationItem.url}
              className={({ isActive }) => `${baseClass} ${showText ? showTextClass : hiddenTextClass} ${isActive ? activeClass : inactiveClass}`}
              onClick={navigationItem.onClick ? navigationItem.onClick : null}
            >
              {linkContents}
            </NavLink>
          )
      }
    </li>
  );
}

NavigationItem.propTypes = {
  showText: PropTypes.bool.isRequired,
  navigationItem: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    icon: PropTypes.string,
    url: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    badge: PropTypes.bool,
    smallBadge: PropTypes.bool,
    badgeText: PropTypes.string,
    badgeColor: PropTypes.string,
  }).isRequired,
};

import React from 'react';
import { NavLink } from 'react-router-dom';
import SvgIcon from '../SvgIcon';

export default function Features() {
  const features = [
    {
      title: 'Chauffeurs-app',
      icon: 'app',
      iconSize: 12,
      url: '/driver-app',
    },
    {
      title: 'Notificaties &amp;<br />Track-and-trace',
      icon: 'mail',
      iconSize: 10,
      url: '/notification-templates',
    },
    {
      title: 'API-koppeling<br />Zapier',
      icon: 'sync',
      iconSize: 10,
      url: '/synchronization',
    },
  ];

  return (
    <div className="grid grid-cols-3 mb-6 md:mb-10 text-center">
      {features.map((feature) => {
        return (
          <NavLink
            key={feature.title}
            to={feature.url}
            className="hover:text-gray-500 focus:text-gray-500 transition group"
          >
            <div
              className={`
                flex items-center justify-center bg-gray-200 rounded-lg w-20 h-20 lg:w-24 lg:h-24
                mx-auto mb-3 text-gray-700 group-hover:bg-gray-700 group-hover:text-white transition
              `}
            >
              <SvgIcon identifier={feature.icon} className={`w-${feature.iconSize} h-${feature.iconSize}`} />
            </div>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: feature.title }}
            />
          </NavLink>
        );
      })}
    </div>
  );
}

Features.defaultProps = {
  //
};

Features.propTypes = {
  //
};

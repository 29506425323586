import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import Main from '../../components/Main';
import { translationsAtom } from '../../store/atoms/i18n';
import MetaHead from '../../components/MetaHead';
import Container from '../../components/Container';
import useTwoFactorActions from '../../store/actions/twoFactor';
import { apiErrorsFamily, apiLoadingFamily } from '../../store/atoms/api';
import { loadingStates } from '../../hooks/useApi';
import ValidationErrors from '../../components/ValidationErrors';
import useAuthActions from '../../store/actions/auth';

export default function TwoFactor() {
  const navigate = useNavigate();
  const t = useRecoilValue(translationsAtom);
  const twoFactorActions = useTwoFactorActions();
  const [qrCode, setQrCode] = useState(null);
  const [url, setUrl] = useState(null);
  const authActions = useAuthActions();

  const twoFactorStoreLoadingState = useRecoilValue(apiLoadingFamily('two-factor-store'));
  const twoFactorErrors = useRecoilValue(apiErrorsFamily('two-factor'));

  useEffect(() => {
    // Retrieve the QR code to setup 2FA.
    twoFactorActions.fetchQr()
      .then((data) => {
        setQrCode(data?.qr);
        setUrl(data?.url);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback((event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    // Verify and store the token, thus enabling 2FA.
    twoFactorActions.store(formData).then(() => {
      // Refetch the user to update the UI with the 2FA state.
      authActions.fetchUser();

      navigate('/p/two-factor-completed');
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const descriptionOne = t.two_factor.description_1
    .replace('%s', `
      <a
        class="border-b border-gray-800 hover:text-primary hover:border-primary focus:text-primary focus:border-primary"
        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
        target="_blank"
        rel="noreferrer"
      >Android</a>
    `)
    .replace('%s', `
      <a
        class="border-b border-gray-800 hover:text-primary hover:border-primary focus:text-primary focus:border-primary"
        href="https://apps.apple.com/nl/app/google-authenticator/id388497605"
        target="_blank"
        rel="noreferrer"
      >iOS</a>
    `);

  return (
    <>
      <MetaHead title={t.two_factor.meta_title} />

      <Main>
        <Container className="my-6 md:my-20">
          <div className="bg-white p-6 md:p-10 rounded-xl shadow-md">
            <div className="md:grid md:grid-cols-5 md:gap-10">
              <div className="col-span-2 items-center flex flex-col">
                {
                  qrCode
                    ? (
                      <img
                        className="max-w-full block h-auto"
                        src={qrCode}
                        alt=""
                        width="265"
                        height="265"
                      />
                    )
                    : null
                }

                {
                  url
                    ? (
                      <div className="min-w-0 w-full text-center">
                        <div>
                          {t.two_factor.two_factor_url}
                        </div>
                        <div
                          className="w-full min-w-0 max-w-full whitespace-nowrap overflow-x-auto text-gray-500 py-2 border-b"
                          readOnly
                        >
                          {url}
                        </div>
                      </div>
                    )
                    : null
                }
              </div>

              <div className="col-span-3">
                <h1 className="text-3xl font-bold mb-6">
                  {t.two_factor.title}
                </h1>

                <div
                  className="text-lg leading-relaxed text-gray-600 mb-6"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: descriptionOne }}
                />

                <div className="text-lg leading-relaxed text-gray-600 mb-6">
                  {t.two_factor.description_2}
                </div>

                <form className="text-lg" onSubmit={onSubmit}>
                  <ValidationErrors errors={twoFactorErrors} />

                  <label className="mb-6 block" htmlFor="token">
                    <div className="mb-3 font-bold">
                      {t.two_factor.code_label}
                    </div>
                    <input
                      className="form-control max-w-sm w-full"
                      type="text"
                      id="token"
                      name="token"
                      autoComplete="one-time-code"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      required
                      minLength={6}
                    />
                  </label>

                  <button
                    type="submit"
                    className="btn btn--primary"
                    disabled={twoFactorStoreLoadingState === loadingStates.LOADING}
                  >
                    {
                      twoFactorStoreLoadingState === loadingStates.LOADING
                        ? t.two_factor.button_waiting
                        : t.two_factor.button_submit
                    }
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </Main>
    </>
  );
}

import React, { useCallback, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { translationsAtom } from '../../store/atoms/i18n';
import SvgIcon from '../../components/SvgIcon';
import useFreemiumActions from '../../store/actions/freemium';
import { freemiumModalOpenAtom } from '../../store/atoms/freemium';
import Steps from './Steps';
import FreemiumModalEmailForm from './FreemiumModalEmailForm';
import FreemiumModalForm from './FreemiumModalForm';
import { schedulesDetailItemAtom } from '../../store/atoms/scheduleDetail';
import analytics from '../../helpers/analytics';
import identifyWithUserResponse from '../../helpers/identifyWithUserResponse';

export default function FreemiumModal() {
  const t = useRecoilValue(translationsAtom);
  const freemiumActions = useFreemiumActions();
  const freemiumModalOpen = useRecoilValue(freemiumModalOpenAtom);
  const [activeStepIndex, setActiveStepIndex] = useState(1);
  const [emailFormContents, setEmailFormContents] = useState({});
  const schedule = useRecoilValue(schedulesDetailItemAtom);
  const navigate = useNavigate();

  const onEmailFormSubmit = useCallback((data) => {
    setEmailFormContents(data);
    setActiveStepIndex(activeStepIndex + 1);
  }, [activeStepIndex]);

  const onFormSubmit = useCallback((data) => {
    const formData = {
      ...data,
      ...emailFormContents,
      schedule_uuid: schedule.uuid,
    };

    freemiumActions.register(formData).then((results) => {
      if (results) {
        identifyWithUserResponse(results);

        analytics.track('Freemium login', {
          category: 'Freemium login',
          label: results.id,
          userId: results.id,
        });

        setActiveStepIndex(activeStepIndex + 1);

        // If running in an iframe, change the parent URL. This prevents the issue
        // where users are trapped inside the iframe.
        if (typeof window.parent !== 'undefined') {
          window.parent.location.href = 'https://app.routelogic.io/schedules?freemium=1';
          return;
        }

        navigate('/schedules?freemium=1');
      }
    });
    // setActiveStepIndex(activeStepIndex + 1);
  }, [emailFormContents, schedule, freemiumActions, navigate, activeStepIndex]);

  const onStepBack = useCallback(() => {
    setActiveStepIndex(activeStepIndex - 1);
  }, [activeStepIndex]);

  const contents = useMemo(() => {
    return (
      <>
        <FreemiumModalEmailForm
          onSubmit={onEmailFormSubmit}
          onBack={onStepBack}
          visible={activeStepIndex === 1}
        />
        <FreemiumModalForm
          onSubmit={onFormSubmit}
          onBack={onStepBack}
          visible={activeStepIndex === 2}
        />
      </>
    );
  }, [activeStepIndex, onEmailFormSubmit, onFormSubmit, onStepBack]);

  let descriptionText = null;

  if (activeStepIndex === 1) {
    descriptionText = t.freemium_modal.email_step_content;
  } else if (activeStepIndex === 2) {
    descriptionText = t.freemium_modal.account_step_content;
  } else if (activeStepIndex === 3) {
    descriptionText = t.freemium_modal.completed_step_content;
  }

  let modalTitle = t.freemium_modal.title;

  if (activeStepIndex === 1) {
    modalTitle = t.freemium_modal.email_step_title;
  } else if (activeStepIndex === 2) {
    modalTitle = t.freemium_modal.account_step_title;
  }

  return (
    <Transition
      show={freemiumModalOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        as="div"
        onClose={() => freemiumActions.toggleModal(false)}
        className="fixed inset-0 z-30 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen p-4 md:p-10 overflow-hidden">
          <Dialog.Overlay
            className="fixed inset-0 bg-black opacity-30"
          />

          <div className="relative bg-white rounded-xl max-w-3xl w-full mx-auto z-40">
            <div className="overflow-hidden w-full max-w-3xl p-6 md:p-10 lg:p-14">
              <Dialog.Title className="text-xl md:text-2xl font-bold">
                {modalTitle}
              </Dialog.Title>

              <Dialog.Description
                className="mt-3 text-xl mb-6 md:mb-10 lg:mb-14"
                dangerouslySetInnerHTML={{ __html: descriptionText }}
              />

              <Steps
                className={`${activeStepIndex === 3 ? 'hidden' : ''} mb-6 md:mb-10 lg:mb-14 -mx-4 md:-mx-10 lg:-mx-14 px-4 md:px-10 lg:px-14`}
                activeStepIndex={activeStepIndex}
                steps={[{
                  title: t.freemium_modal.calculate_route,
                }, {
                  title: t.freemium_modal.add_email,
                }, {
                  title: t.freemium_modal.create_account,
                }]}
              />

              {contents}
            </div>

            <button
              className="bg-primary hover:bg-primary-darker focus:bg-primary-darker transition-colors text-white p-3 md:p-4 absolute top-2 right-2 md:-top-4 md:-right-4 rounded-xl"
              type="button"
              onClick={() => freemiumActions.toggleModal(false)}
            >
              <span className="sr-only">{t.words.close}</span>
              <SvgIcon className="w-5 h-5" identifier="cross" />
            </button>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

FreemiumModal.defaultProps = {
  //
};

FreemiumModal.propTypes = {
  //
};

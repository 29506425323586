import { useCallback, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import useLocaleKey from '../../hooks/useLocaleKey';
import { fetchTranslations } from '../../services/i18nApi';
import { translationsAtom, translationsLoadingStateAtom } from '../atoms/i18n';

export default function useI18nActions() {
  const setTranslationsLoadingState = useSetRecoilState(translationsLoadingStateAtom);
  const setTranslations = useSetRecoilState(translationsAtom);
  const [loadedLocales, setLoadedLocales] = useState({});
  const locale = useLocaleKey();

  const languageLoadingState = useCallback((state) => {
    setTranslationsLoadingState(state);
  }, [setTranslationsLoadingState]);

  const fetch = useCallback(async () => {
    // If the translations have already been downloaded.
    if (typeof loadedLocales[locale] !== 'undefined') {
      setTranslations(loadedLocales[locale]);
      setTranslationsLoadingState('idle');

      return loadedLocales[locale];
    }

    // Fetch the translations for the locale
    const remoteTranslations = await fetchTranslations(locale);

    // Store in loaded locales, to retrieve it when this function reruns
    const newLoadedLocales = { ...loadedLocales };
    newLoadedLocales[locale] = remoteTranslations;
    setLoadedLocales(newLoadedLocales);

    setTranslations(remoteTranslations);
    setTranslationsLoadingState('idle');

    return remoteTranslations;
  }, [
    setTranslations,
    setTranslationsLoadingState,
    loadedLocales,
    locale,
  ]);

  return {
    languageLoadingState,
    fetch,
  };
}

import React, { useCallback, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import nl from 'date-fns/locale/nl';
import en from 'date-fns/locale/en-US';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';
import useLocaleKey from '../../hooks/useLocaleKey';

registerLocale('nl', nl);
registerLocale('en', en);

export default function DatePickerComponent({
  className,
  onChange,
  children,
  allowFutureDates,
  wrapperClassName,
  calendarClassName,
  placeholderText,
  inline,
  value,
  renderDay,
  minDate,
}) {
  const valueFormatted = typeof value === 'string' ? parseISO(value) : value;
  const [startDate, setStartDate] = useState(valueFormatted);
  const [endDate, setEndDate] = useState(new Date());
  const localeKey = useLocaleKey();

  if (allowFutureDates) {
    setEndDate(null);
  }

  const onDateChange = useCallback((date) => {
    setStartDate(date);
    onChange(date);
  }, [setStartDate, onChange]);

  return (
    <DatePicker
      selected={startDate}
      className={`form-control form-control--shadow ${className}`}
      wrapperClassName={wrapperClassName}
      calendarClassName={calendarClassName}
      endDate={endDate}
      onChange={onDateChange}
      placeholderText={placeholderText}
      dateFormat="dd-MM-yyyy"
      renderDayContents={renderDay}
      inline={inline}
      minDate={minDate}
      isClearable
      locale={localeKey}
    >
      {children}
    </DatePicker>
  );
}

DatePickerComponent.defaultProps = {
  allowFutureDates: false,
  inline: false,
  placeholderText: '',
  wrapperClassName: '',
  calendarClassName: '',
  className: '',
  children: null,
  value: null,
  renderDay: null,
  minDate: null,
};

DatePickerComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  placeholderText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  allowFutureDates: PropTypes.bool,
  inline: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  calendarClassName: PropTypes.string,
  className: PropTypes.string,
  renderDay: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

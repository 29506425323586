import { Dialog } from '@headlessui/react';
import React from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import { translationsAtom } from '../../store/atoms/i18n';
import Button from '../Button';
import Features from '../Features';

export default function TrialEnded({
  onOpenSubscriptionModal,
}) {
  const t = useRecoilValue(translationsAtom);

  return (
    <div>
      <Dialog.Title
        className="text-xl md:text-2xl mb-4 md:mb-6 font-bold"
        dangerouslySetInnerHTML={{
          __html: t.upgrade_trial_modal.trial_ended_title,
        }}
      />

      <Dialog.Description
        className="text-lg text-gray-700 mb-6 md:mb-10"
        dangerouslySetInnerHTML={{
          __html: t.upgrade_trial_modal.trial_ended_description,
        }}
      />

      <Features />

      <Button
        styleType="positive"
        className="btn--lg w-full justify-center"
        onClick={onOpenSubscriptionModal}
      >
        {t.upgrade_trial_modal.upgrade_account}
      </Button>
    </div>
  );
}

TrialEnded.defaultProps = {
  //
};

TrialEnded.propTypes = {
  onOpenSubscriptionModal: PropTypes.func.isRequired,
};

import { atom } from 'recoil';
import localStorageEffect from '../effects/localStorageEffect';

export const lastUpdateFetchAtom = atom({
  key: 'lastUpdateFetchAtom',
  default: null,
  effects_UNSTABLE: [
    localStorageEffect('lastUpdateFetchAtom'),
  ],
});

export const updateItemsAtom = atom({
  key: 'updateItems',
  default: null,
});

export const updatesLastIdAtom = atom({
  key: 'updatesLastId',
  default: null,
  effects_UNSTABLE: [
    localStorageEffect('updatesLastIdAtom'),
  ],
});

export const hasNewUpdatesAtom = atom({
  key: 'hasNewUpdates',
  default: false,
  effects_UNSTABLE: [
    localStorageEffect('hasNewUpdatesAtom'),
  ],
});

import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../Alert';

export default function ValidationErrors({ errors }) {
  if (!errors.length) {
    return null;
  }

  let list = '<ul>';

  errors.forEach((error) => {
    list += `<li>${error}</li>`;
  });

  list += '</ul>';

  return (
    <Alert
      className="mb-6"
      message={list}
      type="danger"
    />
  );
}

ValidationErrors.defaultProps = {
  //
};

ValidationErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

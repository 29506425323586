import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { translationsAtom } from '../../store/atoms/i18n';
import { emailVerificationSentAtom } from '../../store/atoms/emailVerify';
import useEmailVerifyActions from '../../store/actions/emailVerify';

export default function EmailVerification() {
  const t = useRecoilValue(translationsAtom);
  const emailVerifyActions = useEmailVerifyActions();
  const emailVerificationSent = useRecoilValue(emailVerificationSentAtom);

  const resendVerification = useCallback(() => {
    emailVerifyActions.sendVerification();
  }, [emailVerifyActions]);

  return (
    <div>
      {
        emailVerificationSent
          ? t.notification_bar.verification_sent
          : (
            <>
              <button
                type="button"
                className="p-2 -my-2 -ml-2 underline hover:opacity-60 focus:opacity-60 transition-all"
                onClick={resendVerification}
              >
                {t.notification_bar.click_here_to_verify}
              </button>
              {t.notification_bar.verify_text}
            </>
          )
      }
    </div>
  );
}

EmailVerification.defaultProps = {
  //
};

EmailVerification.propTypes = {
  //
};

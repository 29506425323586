import analytics from './analytics';

export default function identifyWithUserResponse(userResponse) {
  if (typeof userResponse === 'undefined' || !userResponse) {
    return;
  }

  const avatar = userResponse.avatar_path
    ? `https://api.routelogic.io/storage/${userResponse.avatar_path.replace(/^public\//, '')}`
    : null;

  analytics.identify(userResponse.id.toString(), {
    $avatar: avatar,
    $email: userResponse.email,
    $first_name: userResponse.name,
    company: userResponse.company?.name,
    companyId: userResponse.company?.id,
    createdAt: userResponse.created_at,
  });
}

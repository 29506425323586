import React from 'react';
import PropTypes from 'prop-types';
import './Badge.css';

export default function Badge({ className, children, onClick }) {
  const classes = `${className} badge inline-flex items-center leading-5 px-2 -my-1 font-bold text-xs rounded-full`;

  if (onClick) {
    return (
      <button type="button" className={classes} onClick={onClick}>
        {children}
      </button>
    );
  }

  return (
    <div className={classes}>
      {children}
    </div>
  );
}

Badge.defaultProps = {
  className: '',
  onClick: null,
};

Badge.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
